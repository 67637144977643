export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const GET_RESTAURANTS = "GET_RESTAURANTS";
export const GET_RESTAURANT = "GET_RESTAURANT";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";
export const SET_LEVELS = "SET_LEVELS";
export const FORUM_REPLIES_HAVE_CHANGED = "FORUM_REPLIES_HAVE_CHANGED";

