import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Route, Switch } from "react-router-dom";
import StudentTopBar from "./partials/StudentTopBar";
import ExamsList from "./pages/ExamsList";
import BeginExam from "./Exam/BeginExam";
import NotFound from "../../layout/NotFound";
import StudentLanding from "./pages/StudentLanding";
import StudentResults from "./pages/StudentResults";
import ChangePassword from "./pages/ChangePassword";
import ForumList from "./Forum/ForumList";
import StudentForum from "./Forum/StudentForum";
import AssignmentList from "./Assignment/AssignmentList";
import Assignment from "./Assignment/Assignment";
import Profile from "./Profile/Profile";
import LessonList from "./Lesson/LessonList";

class StudentDashboard extends Component {
  constructor() {
    super();
    this.state = {
      showAvatarComponent: false,
    };
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    const { showAvatarComponent } = this.state;

    return (
      <div>
        {showAvatarComponent && (
          <Profile
            closeProfile={() => this.setState({ showAvatarComponent: false })}
          ></Profile>
        )}
        <div>
          <StudentTopBar
            user={this.props.auth.user}
            changeAvatarWindow={() =>
              this.setState({ showAvatarComponent: true })
            }
          ></StudentTopBar>
        </div>
        <div className="student-dashboard-content">
          <Switch>
            <Route exact path="/student" component={StudentLanding} />
            <Route path="/student/examslist" component={ExamsList} />
            <Route path="/student/results" component={StudentResults} />
            <Route path="/student/forums" component={ForumList} />
            <Route path="/student/assignments" component={AssignmentList} />
            <Route path="/student/lessons" component={LessonList} />
            <Route path="/student/task/:id" component={Assignment} />
            <Route path="/student/discussion/:id" component={StudentForum} />
            <Route path="/student/beginexamen/:id" component={BeginExam} />
            <Route path="/student/changepw" component={ChangePassword} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
        {/* <div className="student-dashboard-footer">
          <div className="student-dashboard-footer__contact-info">
            <div>@2020 Purocodigo, Todos los derechos reservados</div>
            <div className="student-dashboard-footer__contact-info__data">
              <span>
                <span className="fa fa-phone"></span> +57 3144139093
              </span>
              <span>
                <span className="fa fa-envelope"></span>&nbsp;
                support@purocodigo.net
              </span>
            </div>
          </div>
        </div> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(withRouter(StudentDashboard));
