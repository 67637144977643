import React, { Component } from "react";
import Message from "./Message";
import { getScrollTopMax } from "../../../../../helpers/helpers";
import ChatForm from "./ChatForm";
import { LESSON_NEW_MESSAGE } from "../../../../../helpers/api";
import axios from "axios";
import { toast } from "react-toastify";
import VoiceMessage from "./VoiceMessage";

export default class Chat extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
    };
    this.chatHistoryEl = null;
    this.autoScroll = true;
  }

  componentDidMount() {
    this.scrollToBottom();
  }

  renderMessages() {
    if (!this.props.messages) return <div>No Messages</div>;

    const { messages, currentUser } = this.props;

    return messages.map((message) => {
      return message.is_voice == 0 ? (
        <Message
          key={message.id}
          message={message}
          currentUserId={currentUser.id}
        ></Message>
      ) : (
        <VoiceMessage
          key={message.id}
          message={message}
          currentUserId={currentUser.id}
          lessonId={this.props.lessonId}
        ></VoiceMessage>
      );
    });
  }

  componentDidUpdate() {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.autoScroll) {
      this.chatHistoryEl.parentNode.scrollTop = this.chatHistoryEl.scrollHeight;
    }
  };

  sendMessageEvent = async (message) => {
    try {
      const res = await axios.post(LESSON_NEW_MESSAGE, {
        lesson: this.props.lessonId,
        message: message,
      });
    } catch (e) {
      toast.error(e.response.data.error, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  };

  scrollOnChatWindow = (e) => {
    const scrollTopMax = getScrollTopMax(e.target);

    if (Math.ceil(e.target.scrollTop) >= scrollTopMax) {
      this.autoScroll = true;
      return;
    }

    this.autoScroll = false;
  };

  render() {
    const { lessonOpen, lessonId, currentUser } = this.props;

    return (
      <div className="lesson-container__chat">
        <div className="chat">
          <div className="chat-history" onScroll={this.scrollOnChatWindow}>
            <div className="activate-auto-scroll">
              <span></span>
            </div>
            <ul ref={(el) => (this.chatHistoryEl = el)}>
              {this.renderMessages()}
            </ul>
          </div>
          {lessonOpen ? (
            <ChatForm
              lessonId={lessonId}
              messageEvent={this.sendMessageEvent}
              currentUser={currentUser}
            ></ChatForm>
          ) : (
            <div className="lesson-container__chat__finish">
              La clase ha terminado
            </div>
          )}
        </div>
      </div>
    );
  }
}
