import React, { Component } from 'react'
import { getAvatars } from '../../../../../helpers/avatars';
import classnames from 'classnames';

export default class Avatar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            avatars: [],
            selectedAvatar: 0,
            buttonDisabled: true,
            sendingRequest: false
        };
    }

    componentDidMount() {
        let { avatars } = this.state;        
        avatars = getAvatars(this.props.gender);
        this.setState({
            avatars
        });
    }

    selectAvatar (avatar) {
        this.setState({
            selectedAvatar: avatar,
            buttonDisabled: false
        });

    }

    renderAvatars () {
        let { avatars, selectedAvatar } = this.state;
        let markup = [];
        avatars.forEach(avatar => {
            markup.push(
                <div onClick={() => { this.selectAvatar(avatar) }}
                    className={classnames("single-avatar ", {
                        "single-avatar--active": avatar.id == selectedAvatar.id
                    })}
                    key={avatar.id}><img src={avatar.src}></img></div>
            );
        });
        return markup;
    }

    render() {

        let { buttonDisabled, sendingRequest, selectedAvatar } = this.state;

        return (
            <div className="profile__container__content">
                <div>
                    <h2><i className="fa fa-user-o" aria-hidden="true"></i>&nbsp;Seleccione un avatar</h2>
                </div>

                <div className="avatars-container">
                    { this.renderAvatars() }
                </div>
                
                <div className="next-step-button">
                    <button 
                        onClick={() => {
                            this.props.avatarCallback(selectedAvatar);
                            this.setState({
                                sendingRequest: true
                            });
                        }}
                        className={classnames("button is-primary ", {
                            "is-loading": sendingRequest
                        })}                        
                        disabled={buttonDisabled}>
                        Guardar cambios</button>
                </div>
            </div>
        );
    }
}
