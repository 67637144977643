import React, { Component } from "react";
import axios from "axios";
import { GET_FORUM, FORUM_REPLY } from "../../../../helpers/api";
import moment from 'moment';
import 'moment/locale/es';
import {connect} from "react-redux";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import ParticipateForm from "./ParticipateForm";
import commentsSvg from '../../../../assets/comments.svg';
import SingleComment from "./SingleComment";


class ForumDetail extends Component {

    constructor() {
        super();        
        this.state = {
            forum: {
                replies: []
            },     
            errors: {},            
            recordsPerPage: 10,
            recordsShowing: 0,
            replies: [],
            showMoreButton: true,
            fetchingDataFromAPI: true,
            isForumOpened: false            
        }
        moment().locale('es');      
        this.user = null;
       
    }

    componentWillReceiveProps(nextProps) {        
        if (nextProps.forums) {                 
            this.forumCommentsHaveChanged();
        }
    }

    async componentDidMount() { 

        const { slug, levelid, auth } = this.props;

        this.user = auth.user;

        await this.fetchForum(slug, levelid);

        const close = this.checkIfForumClosed();

        this.setState({
            isForumOpened: close,
            fetchingDataFromAPI: false
        });
        
    }

    async fetchForum(slug, levelid) {
        try {
            const res = await axios.get(GET_FORUM + "/" + levelid + "/" + slug);
            this.setState({
                forum: res.data,
                recordsPerPage: 10,
                recordsShowing: 0,
                replies: [],
                showMoreButton: true,
                errors: {}
            }, ()=> {
                this.loadReplies();
            });
            
        } catch (e) {
            this.props.history.push('/');
        }
    }

    checkIfForumClosed() {
        const { forum } = this.state;
        const endDate = moment(forum.end_date);
        const now = moment(forum.now);   
        const diff = endDate.diff(now);
        return diff > 0;       
    }

    renderHtml() {
        return (<div
            dangerouslySetInnerHTML={{
            __html: this.state.forum.content
        }}></div>);               
    }    

    participate = async(contentValue) => {
        
        const { forum } = this.state;
        const data = {
            'content': contentValue,
            'forum_id': forum.id
        }

        try {
            const res = await axios.post(FORUM_REPLY, data);

            if (parseInt(res.status) === 200) {

                const { slug, levelid } = this.props;

                await this.fetchForum(slug, levelid);              

                this.participateFormChild.replyCreated();

                toast.info("Gracias por participar", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 2000
                });              
            }        
            
        } catch(e) {
            this.participateFormChild.replyReturnedError();
            this.setState({                
                errors: e.response.data
            })
        }
    }

    loadReplies() {        
        let { recordsPerPage, recordsShowing, forum, showMoreButton } = this.state;
        const replies = forum.replies;
       
        if (replies.length > (recordsShowing + recordsPerPage)) {
            recordsShowing = recordsShowing + recordsPerPage;
        } else {
            recordsShowing = replies.length;            
            showMoreButton = false;
        }

        const data = [];        
        for (let i = 0; i < recordsShowing; i++) {
            data.push(replies[i]);
        }      

        this.setState({ replies: data, recordsShowing, showMoreButton });         
    }

    
    forumCommentsHaveChanged = async () => {
        const { slug, levelid } = this.props;
        await this.fetchForum(slug, levelid); 
    }

    renderReplies () {
                
        const { replies, forum, isForumOpened } = this.state;
        const now = moment(forum.time);
        
        return replies.map(reply => {   
                         
            return <SingleComment                     
                    key={reply.id} 
                    isForumOpened={isForumOpened} 
                    forumId={forum.id}
                    reply={reply} 
                    now={now} 
                    user={this.user}
                ></SingleComment>
        });
    }

    loadMore = () => {
        this.loadReplies()
    }

 
    render() {

        const {isForumOpened, forum, errors, showMoreButton, fetchingDataFromAPI } = this.state;
        
        const asnwers = forum.replies.length ? this.renderReplies() 
        : (<div className="no-answers">Este foro no tiene respuestas todavia &nbsp;<span className="fa fa-frown-o"></span></div>);

        return (
            <div>

                
                
                { fetchingDataFromAPI && <FullScreenLoader></FullScreenLoader> }
                    <div className="forum-header-component">                        
                        <h2>
                            { forum.title }
                        </h2>
                    </div>  
                    <div className="forum-content-component" style={{ marginTop: '40px' }}>                       
                        <div>
                            {this.renderHtml()}
                        </div>                        
                    </div>
                    <div className="forum-answers-list">
                        { isForumOpened && 
                            <ParticipateForm
                            ref={ instance => this.participateFormChild =instance } 
                            sendReply={this.participate}
                            errors={errors} ></ParticipateForm>
                        }
                        <div className="header answers-header">
                            <object type="image/svg+xml" data={commentsSvg}>
                                Error al cargar svg
                            </object>
                            <span>Respuestas</span>
                        </div>
                        <div>
                            { asnwers }
                        </div>
                        <div className="load-more-button">
                            { showMoreButton && 
                            <button className="button is-primary is-small is-outlined" onClick={this.loadMore}>
                                
                                Mostrar mas respuestas
                                </button> }
                        </div>
                    </div>
                </div>
            
        );
    }
}


const mapStateToProps = state => ({
    auth: state.auth,
    forums: state.forums
});

export default connect(
    mapStateToProps,
    null
)(withRouter(ForumDetail));

