import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import levelReducer from "./levelReducer";
import forumsReducer from "./forumsReducer";

export default combineReducers({
    auth: authReducer,
    errors: errorReducer,    
    levels: levelReducer,
    forums: forumsReducer
});