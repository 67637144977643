import React, { Component } from "react";
import classnames from "classnames";
import CounterTextArea from "../../../UI/Form/CounterTextArea";

export default class ParticipateForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      characterCount: 0,
      content: "",
      sendingReply: false,
    };
  }

  onChange = (val) => {
    const length = val.length;
    if (length > 500) {
      return;
    }
    this.setState({
      content: val,
      characterCount: length,
    });
  };

  replyCreated() {
    this.setState({
      characterCount: 0,
      content: "",
      sendingReply: false,
    });
  }

  replyReturnedError() {
    this.setState({
      sendingReply: false,
    });
  }

  render() {
    const { characterCount, content, sendingReply } = this.state;
    const { errors } = this.props;

    return (
      <div>
        <div>
          <CounterTextArea
            characterCount={characterCount}
            onChangeCb={(val) => this.onChange(val)}
            error={errors.content}
            content={content}
            header={true}
            headerText="Participa en el foro"
            icon={"fa fa-commenting-o"}
          ></CounterTextArea>
          <div className="mt-5">
            <button
              className={classnames("button is-outlined", {
                "is-loading ": sendingReply,
              })}
              onClick={() => {
                this.setState({ sendingReply: true });
                this.props.sendReply(content);
              }}
            >
              <i className="fa fa-comments-o"></i>&nbsp;Comentar
            </button>
          </div>
        </div>
      </div>
    );
  }
}
