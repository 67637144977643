import React from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";

const InputTextField = ({
                            name,
                            placeholder,
                            value,
                            label,
                            hasRedBorderError,
                            error,
                            info,
                            type,
                            onChange,
                            isMedium,
                            icon,
                            disabled,
                            isErrorColorLight
                        }) => {
    return (

        <div className={
            classnames("control",{
                "has-icons-left": icon
            })
        }>
            <input
                type={type}
                className={classnames("input", {
                    "is-danger": error && hasRedBorderError,
                    "is-medium": isMedium
                })}
                name={name}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}     
                autoComplete="off"
            />
            { icon && (
                <span className="icon is-small is-left">
                    <i className={icon}></i>
                </span>
            ) }
            
            {error && (
                <p  className={classnames('help', {
                    'forced-white-text': isErrorColorLight,
                    'is-danger': !isErrorColorLight 
                })}>{error}</p>
            )}
        </div>
    );
}

InputTextField.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    info: PropTypes.string,
    error: PropTypes.string,
    type: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    icon: PropTypes.string,
    disabled: PropTypes.string,
    isMedium: PropTypes.bool,
    hasRedBorderError: PropTypes.bool,
    isErrorColorLight: PropTypes.bool
}

InputTextField.defaultProps = {
    type: 'text',
    isMedium: false,
    hasRedBorderError: true,
    isErrorColorLight: false
}

export default InputTextField;
