import React from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";

const Loader = ({
                    isLoaderWhite                           
                }) => {
    return (
        <div className="spinner-container">
            <div className={classnames('lds-ellipsis', {
                'is-loader-white': isLoaderWhite
            })}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    );
}

Loader.propTypes = {
    isLoaderWhite: PropTypes.bool    
}

Loader.defaultProps = {    
    isLoaderWhite: false
}

export default Loader;