import React, { Component } from "react";
import classnames from "classnames";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { logoutUser } from "../../../../actions/authActions";
import PropTypes from "prop-types";
import logo from "../../../../assets/logowhite.png";

class AdminTopBar extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu() {
    const currentValue = this.state.menuOpen;
    this.setState({
      menuOpen: !currentValue,
    });
  }

  signOut(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  handleNavigationEvent(route) {
    this.toggleMenu();
    this.props.history.push(`/admin/${route}`);
  }

  componentWillReceiveProps(nextProps) {}

  render() {
    return (
      <nav
        className="navbar is-primary"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <a
            className="navbar-item"
            onClick={() => {
              this.handleNavigationEvent("");
            }}
          >
            <img src={logo}></img>
          </a>

          <a
            role="button"
            onClick={() => this.toggleMenu()}
            className={classnames("navbar-burger burger", {
              "is-active": this.state.menuOpen,
            })}
            aria-label="menu"
            aria-expanded="false"
            data-target="menuTopBar"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="menuTopBar"
          className={classnames("navbar-menu", {
            "is-active": this.state.menuOpen,
          })}
        >
          <div className="navbar-start">
            <a
              className="navbar-item"
              onClick={() => {
                this.handleNavigationEvent("");
              }}
            >
              Inicio
            </a>

            <div className="navbar-item has-dropdown is-hoverable ">
              <a className="navbar-link">Talleres</a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("newassignment");
                  }}
                >
                  Nuevo taller
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("assignmentlist");
                  }}
                >
                  Lista de talleres
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("tasks");
                  }}
                >
                  Soluciones de talleres
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable ">
              <a className="navbar-link">Clases</a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("newlesson");
                  }}
                >
                  Crear clase
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("lessons");
                  }}
                >
                  Ver clases
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("attendancereport");
                  }}
                >
                  Asistencia
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("reportlesson");
                  }}
                >
                  Participación
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable mobile-item-menu">
              <a className="navbar-link">Pruebas</a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("newexam");
                  }}
                >
                  Crear prueba
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("exams");
                  }}
                >
                  Ver pruebas
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("examresult");
                  }}
                >
                  Resultados
                </a>
                <hr className="navbar-divider" />
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("answers");
                  }}
                >
                  Ver soluciones
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable mobile-item-menu">
              <a className="navbar-link">Cursos</a>
              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("createlevel");
                  }}
                >
                  Crear curso
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("level");
                  }}
                >
                  Ver cursos
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable mobile-item-menu">
              <a className="navbar-link">Estudiante</a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("register");
                  }}
                >
                  Registrar estudiante
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("studentlist");
                  }}
                >
                  Ver estudiantes
                </a>
              </div>
            </div>

            <div className="navbar-item has-dropdown is-hoverable mobile-item-menu">
              <a className="navbar-link">Foro</a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("forum");
                  }}
                >
                  Nuevo foro
                </a>
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.handleNavigationEvent("listforum");
                  }}
                >
                  Ver foros
                </a>
              </div>
            </div>
          </div>

          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">{this.props.user.name}</a>

              <div className="navbar-dropdown">
                <hr className="navbar-divider" />
                <a className="navbar-item" onClick={this.signOut.bind(this)}>
                  Cerrar sesión
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

AdminTopBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(null, { logoutUser })(withRouter(AdminTopBar));
