import React, { Component } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import { logoutUser } from "../../../../actions/authActions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import logo from "../../../../assets/logo2.png";

class StudentDashboard extends Component {
  constructor() {
    super();
    this.state = {
      menuOpen: false,
    };
  }

  toggleMenu() {
    const currentValue = this.state.menuOpen;
    this.setState({
      menuOpen: !currentValue,
    });
  }

  signOut(e) {
    e.preventDefault();
    this.props.logoutUser();
  }

  changeAvatar() {
    this.toggleMenu();
    this.props.changeAvatarWindow();
  }

  navigateTo(route) {
    this.toggleMenu();
    this.props.history.push(`/student${route}`);
  }

  render() {
    return (
      <nav
        className="navbar inverted"
        role="navigation"
        aria-label="main navigation"
      >
        <div className="navbar-brand">
          <Link className="navbar-item" to="/student">
            <img src={logo}></img>
          </Link>

          <a
            role="button"
            onClick={() => this.toggleMenu()}
            className={classnames("navbar-burger burger", {
              "is-active": this.state.menuOpen,
            })}
            aria-label="menu"
            aria-expanded="false"
            data-target="menuTopBar"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div
          id="menuTopBar"
          className={classnames("navbar-menu", {
            "is-active": this.state.menuOpen,
          })}
        >
          <div className="navbar-start">
            <a className="navbar-item" onClick={() => this.navigateTo("")}>
              Inicio
            </a>

            <a
              className="navbar-item"
              onClick={() => this.navigateTo("/examslist")}
            >
              Pruebas
            </a>

            <a
              className="navbar-item"
              onClick={() => this.navigateTo("/results")}
            >
              Resultados
            </a>

            <a
              className="navbar-item"
              onClick={() => this.navigateTo("/forums")}
            >
              Foro
            </a>

            <a
              className="navbar-item"
              onClick={() => this.navigateTo("/assignments")}
            >
              Talleres
            </a>

            <a
              className="navbar-item"
              onClick={() => this.navigateTo("/lessons")}
            >
              Clases
            </a>
          </div>

          <div className="navbar-end">
            <div className="navbar-item has-dropdown is-hoverable">
              <a className="navbar-link">{this.props.user.name}</a>

              <div className="navbar-dropdown">
                <a
                  className="navbar-item"
                  onClick={() => {
                    this.changeAvatar();
                  }}
                >
                  Cambiar Avatar
                </a>
                <a
                  className="navbar-item"
                  onClick={() => this.navigateTo("/changepw")}
                >
                  Cambiar Contraseña
                </a>
                <hr className="navbar-divider" />
                <a className="navbar-item" onClick={this.signOut.bind(this)}>
                  Cerrar sesión
                </a>
              </div>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

StudentDashboard.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default connect(null, { logoutUser })(withRouter(StudentDashboard));
