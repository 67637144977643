import React, { Component } from "react";
import {connect} from "react-redux";
import { deleteLevel } from "../../../../actions/levelActions";
import DataTable from "react-data-table-component";
import ConfirmCheck from "../../../UI/Modal/ConfirmCheck";
import { toast } from 'react-toastify';



class Levels extends Component {

    constructor(props) {
        super(props);
        this.state = {                
            levels: this.props.levels,
            showConfirm: false,
            levelToDelete: {},           
        };       
    } 

    deleteLevel(row) {
        this.setState({
            levelToDelete: row,
            showConfirm: true
        })        
    }

    confirmWindow(res) {
        
        this.setState({
            showConfirm: false,
            levelToDelete: {}
        });

        if (!res.res) {
            return;
        }

        this.props.deleteLevel(res.metadata.id)
            .then((r) => {
                if (r == 200) {
                    toast.info("Curso eliminado correctamente", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 2500
                    });
                }
            })
        
    }

    render() {

        const { errors, levels } = this.state;

        const table = levels.length ? <DataTable
            columns={[
                { name: 'Nombre del curso', selector: 'name', sortable: true },
                { name: 'Acciones',
                 selector: 'id',
                 cell: row => <div className="icon-actions">
                     <span title="Eliminar curso"><i className="fa fa-trash" onClick={() => this.deleteLevel(row)}></i></span>
                 </div>,}                
            ]}
            data={levels}
            pagination={true}
            paginationPerPage={20}
            noHeader={true}

        /> : <div>
            No se encontraron cursos
        </div>;


        return (
            <div>
                <ConfirmCheck
                    isActive={this.state.showConfirm}
                    callback={this.confirmWindow.bind(this)}
                    title="Eliminar curso"
                    message="¿Estás seguro que deseas eliminar este curso? Si eliminas este curso, todos los estudiantes y pruebas relacionadas con este curso también serán eliminados"
                    confirmTextLbl="Por favor confirme el nombre del curso a eliminar"
                    confirmButton="Si"
                    cancelButton="No"
                    metadata={this.state.levelToDelete}
                    name={this.state.levelToDelete.name}
                    confirmPlaceholder="Nombre del curso"
                ></ConfirmCheck>
                <div className={'columns'}>
                    <div className={'column is-10 is-offset-1 mt-20'}>
                        <h3 className={'is-main-header'}>
                        <i className="fa fa-list" style={{ marginRight:'5px' }}></i>
                        Lista de cursos</h3>
                        
                        { table }                       

                    </div>
                </div>
            </div>
        );
    }
}

export default connect(
    null,
    { deleteLevel }
)(Levels);