import React, { Component } from "react";
import classnames from "classnames";
import DataTable from "react-data-table-component";

export default class Attendances extends Component {
  action(res) {
    this.props.close(res);
  }

  render() {
    const { data } = this.props;

    const table = data.length ? (
      <DataTable
        columns={[
          {
            sortable: true,
            name: "Identificación",
            selector: "id",
            cell: (row) => (
              <span title={row.user.identification}>
                {row.user.identification}
              </span>
            ),
          },
          {
            sortable: true,
            name: "Estudiante",
            selector: "id",
            cell: (row) => <span title={row.user.name}>{row.user.name}</span>,
          },
          {
            sortable: true,
            name: "Fecha de ingreso a la clase",
            selector: "id",
            cell: (row) => <span title={row.created_at}>{row.created_at}</span>,
          },
        ]}
        data={data}
        pagination={true}
        paginationPerPage={5}
        noHeader={true}
      />
    ) : (
      <div>No se encontraron asistencias para esta clase</div>
    );

    return (
      <div
        className={classnames("modal ", {
          "is-active": this.props.isActive,
        })}
      >
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{this.props.title}</p>
            <button
              className="delete"
              aria-label="close"
              onClick={() => {
                this.action(true);
              }}
            ></button>
          </header>
          <section className="modal-card-body">
            <React.Fragment>{table}</React.Fragment>
          </section>
          <footer className="modal-card-foot"></footer>
        </div>
      </div>
    );
  }
}
