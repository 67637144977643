import React, { Component } from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import { formatBytes } from "../../../../helpers/helpers";
import axios from "axios";
import {
  ASSIGNMENT_SEND_SOLUTION,
  GET_ASSIGNMENT,
  GET_SOLUTION_FILE,
  DELETE_SOLUTION,
} from "../../../../helpers/api";
import { withRouter } from "react-router-dom";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import classnames from "classnames";
import svg from "../../../../assets/send.svg";
import TitleHeader from "../layout/TitleHeader";
import UploadHelp from "../Help/UploadHelp";
import moment from "moment";
import "moment/locale/es";
import Confirm from "../../../UI/Modal/Confirm";

class Assignment extends Component {
  constructor() {
    super();

    this.state = {
      file: null,
      solutionSent: true,
      task: null,
      fetchingDataFromAPI: true,
      sendingSolution: false,
      showHelp: false,
      confirmWindowActive: false,
    };

    moment().locale("es");
  }

  async componentDidMount() {
    const { id } = this.props.match.params;
    try {
      const res = await axios.get(`${GET_ASSIGNMENT}/${id}`);
      this.setState({
        task: res.data,
        fetchingDataFromAPI: false,
        solutionSent: res.data.solved,
      });
    } catch (ex) {
      this.props.history.push("/student/assignments");
    }
  }

  onDrop = (files) => {
    if (files.length > 1) {
      toast.error("Solamente puede enviar (1) archivo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return;
    }

    const file = files[0];

    if (file.size > 1024 * 1024 * 6) {
      toast.error("El archivo excede el peso maximo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return;
    }

    if (file.type == "application/pdf") {
      if (file.type == "application/pdf") file.icon = "fa fa-file-pdf-o";
      this.setState({ file });
    } else {
      toast.error("El formato del documento debe ser PDF", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return;
    }
  };

  sendSolution = async () => {
    const { file } = this.state;

    if (!file) {
      toast.error("Por favor adjunte el archivo de la solución", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
      return;
    }

    this.setState({
      sendingSolution: true,
    });

    const formData = new FormData();
    formData.append("file", file);
    formData.append("assignmentId", this.state.task.id);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    try {
      const res = await axios.post(ASSIGNMENT_SEND_SOLUTION, formData, config);

      if (res.status == 200) {
        let { task } = this.state;
        task.solution_date = res.data.created_at;
        task.solution_id = res.data.id;
        this.setState({
          solutionSent: true,
          sendingSolution: false,
          task: task,
        });
        toast.info("Solución enviada", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2500,
        });
      }
    } catch (e) {
      this.setState({
        sendingSolution: false,
      });
      toast.error("Ha ocurrido un error, intente de nuevo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 4000,
      });
    }
  };

  renderHtml = (content) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: content,
        }}
      ></div>
    );
  };

  downloadSolution = async () => {
    this.setState({
      fetchingDataFromAPI: true,
    });

    try {
      const id = this.state.task.solution_id;

      const response = await axios.get(`${GET_SOLUTION_FILE}/${id}/download`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
        },
      });

      const filenameHeader = response.headers["content-disposition"];
      const fileName = filenameHeader.replace("filename=", "");

      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      this.setState({
        fetchingDataFromAPI: false,
      });
    } catch (ex) {
      this.setState({
        fetchingDataFromAPI: false,
      });
      toast.error("No se ha podido descargar la solución", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  };

  async deleteSolution() {
    this.setState({
      fetchingDataFromAPI: true,
    });

    try {
      const id = this.state.task.solution_id;
      const res = await axios.delete(`${DELETE_SOLUTION}/${id}`);

      let { task } = this.state;
      task.solution_date = "";
      task.solution_id = "";

      this.setState({
        fetchingDataFromAPI: false,
        solutionSent: false,
        task: task,
      });
    } catch (ex) {
      this.setState({
        fetchingDataFromAPI: false,
      });
      toast.error("No se ha podido eliminar la solución", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    }
  }

  deleteSolutionWindowCallback = (res) => {
    this.setState({ confirmWindowActive: false });

    if (!res) {
      return;
    }

    this.deleteSolution();
  };

  render() {
    const {
      file,
      fetchingDataFromAPI,
      task,
      solutionSent,
      sendingSolution,
      showHelp,
    } = this.state;

    return (
      <div>
        <Confirm
          callback={this.deleteSolutionWindowCallback}
          isActive={this.state.confirmWindowActive}
          confirmButton="Si"
          cancelButton="No"
          title={"Eliminar solución"}
          message="Estas seguro(a) que quieres eliminar la solución?"
        ></Confirm>

        {!solutionSent && (
          <div
            onClick={() => this.setState({ showHelp: true })}
            className="upload-file-help-button"
          >
            <i className="fa fa-question-circle" aria-hidden="true"></i>
          </div>
        )}

        {showHelp && (
          <UploadHelp
            closeHelp={() => {
              this.setState({ showHelp: false });
            }}
          ></UploadHelp>
        )}

        {fetchingDataFromAPI && <FullScreenLoader></FullScreenLoader>}

        {task && <TitleHeader text={task.title} image={svg}></TitleHeader>}

        {task && (
          <div className="columns">
            <div className="column is-8 is-offset-2 mt-20">
              <div>
                {this.renderHtml(task.content)}

                {task.assignment_url && (
                  <div className="download-assignment">
                    <a
                      target="_blank"
                      href={`https://purocodigo.net/public/apps/cdn/icfes/${task.assignment_url}`}
                    >
                      <i className="fa fa-cloud-download"></i> Descargar taller
                      ({task.title})
                    </a>
                  </div>
                )}
              </div>

              <hr />

              {!solutionSent && (
                <div>
                  <div className="mb-10">
                    <h5 className="title is-5">Enviar solución</h5>
                    <p>
                      <b>Nota:</b> el peso del archivo de la solución no debe
                      ser mayor a <b>6MB</b> y debe ser un documento PDF.{" "}
                      <b>(SOLAMENTE ARCHIVOS PDF)</b>
                    </p>
                  </div>
                  <div>
                    <Dropzone onDrop={this.onDrop}>
                      {({ getRootProps, getInputProps }) => (
                        <section className="dropzone-container">
                          <div {...getRootProps({ className: "dropzone" })}>
                            <input {...getInputProps()} />
                            <div className="info-container">
                              {file ? (
                                <div className="file-uploaded">
                                  <div className={file.icon}></div>
                                  <div>
                                    <span>{file.name}</span> -{" "}
                                    <span>{formatBytes(file.size)}</span>
                                  </div>
                                </div>
                              ) : (
                                "Arrastre el archivo o presione clic para seleccionarlo"
                              )}
                            </div>
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  <div className="mt-10">
                    <button
                      className={classnames("button is-outlined", {
                        "is-loading": sendingSolution,
                      })}
                      onClick={this.sendSolution}
                    >
                      Enviar solución
                    </button>
                  </div>
                </div>
              )}

              {task && solutionSent && (
                <div style={{ maxWidth: "600px", margin: "0 auto" }}>
                  <div className="card">
                    <header className="card-header">
                      <p
                        style={{ color: "#888", fontSize: "1.2rem" }}
                        className="card-header-title"
                      >
                        <span className="fa fa-check"></span>&nbsp;Usted ya ha
                        enviado la solución!
                      </p>
                    </header>
                    <div className="card-content">
                      <div className="content" style={{ color: "#666" }}>
                        Solución enviada el:{" "}
                        {moment(task.solution_date).format(
                          "DD [de] MMMM YYYY [a las] H:mm"
                        )}
                      </div>
                    </div>
                    <footer className="card-footer">
                      <a
                        className="card-footer-item"
                        onClick={this.downloadSolution}
                      >
                        <span className="fa fa-cloud-download"></span>
                        &nbsp;Descargar solución
                      </a>
                      <a
                        className="card-footer-item"
                        onClick={() =>
                          this.setState({ confirmWindowActive: true })
                        }
                      >
                        <span className="fa fa-trash-o"></span>&nbsp;Eliminar
                        solución
                      </a>
                    </footer>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(Assignment);
