import React, { Component } from 'react'
import video from '../../../../assets/howtouploadfiles.mp4';

class UploadHelp extends Component {

    componentDidMount() {
        const videoHtml = this.refs.video;    
        
        videoHtml.oncanplay = () => {
            try {
                videoHtml.play();
            } catch (e) {
                this.props.closeHelp(true);
            }
        }

        videoHtml.onended = () => {
            this.props.closeHelp(true);
        }

        videoHtml.onerror = () => {
            this.props.closeHelp(true);
        }
    }

    render() {
        return (
            <div className="help-fullscreen-component">
                <video muted  ref="video">
                    <source src={video} type="video/mp4"></source>
                </video>
            </div>
        )
    }
}

export default UploadHelp;
