import { GET_ERRORS, SET_CURRENT_USER } from "./types";
import setAuthToken from "../Utils/setAuthToken";
import axios from "axios";
import jwt_decode from "jwt-decode";
import { LOGIN } from "../helpers/api";

// login user
export const loginUser = (userData, history) => dispatch => {
    return new Promise((resolve, reject) => {
        axios.post(LOGIN, userData)
        .then(response => {
            const { token } = response.data;
            localStorage.setItem("jwtToken", token);
            setAuthToken(token);
            const decoded = jwt_decode(token);
            dispatch(setCurrentUser(decoded));   
            resolve(response);        
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
            reject(err)
        });
    })
};


//set logged in user
export const setCurrentUser = decoded => {
    return {
        type: SET_CURRENT_USER,
        payload: decoded
    };
};


// log user out
export const logoutUser = userData => dispatch => {
    //remove token from localstorage
    localStorage.removeItem("jwtToken");
    //remove auth header for future requests
    setAuthToken(false);
    //set current user to empty object wich will set authenticate to false
    dispatch(setCurrentUser({}));
};
