import React, { Component } from "react";
import InputSelectField from "../../../UI/Form/InputSelectField";
import {
  GET_LESSON_PANEL,
  DELETE_LESSON,
  GET_ATTENDANCES,
} from "../../../../helpers/api";
import axios from "axios";
import DataTable from "react-data-table-component";
import { withRouter, Link } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmCheck from "../../../UI/Modal/ConfirmCheck";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import Attendances from "./Attendances";

class Lessons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levels: this.props.levels,
      lessons: [],
      level_id: "-1",
      sendingRequest: false,
      fetchingDataFromApi: false,
      showConfirm: false,
      lessonToDelete: {},
      windowModalActive: false,
      attendances: {},
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const val = e.target.value;

    this.setState({ level_id: val.toString() });

    if (val == -1) return;

    this.setState({ fetchingDataFromApi: true });

    this.fetchlessonsByLevel(val).then((res) => {
      this.setState({
        fetchingDataFromApi: false,
        lessons: res.data,
      });
    });
  }

  async fetchlessonsByLevel(id) {
    try {
      return await axios.get(GET_LESSON_PANEL + "/" + id);
    } catch (e) {
      console.log(e);
    }
  }

  deleteLesson(row) {
    this.setState({
      lessonToDelete: row,
      showConfirm: true,
    });
  }

  async confirmWindow(res) {
    this.setState({
      showConfirm: false,
      lessonToDelete: {},
    });

    if (!res.res) {
      return;
    }

    this.setState({ fetchingDataFromApi: true });

    try {
      const data = await axios.delete(DELETE_LESSON + "/" + res.metadata.id);

      let { lessons } = this.state;

      lessons = lessons.filter((forum) => forum.id != data.data.id);

      this.setState({
        lessons,
        fetchingDataFromApi: false,
      });

      toast.info("Clase eliminada correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (e) {
      this.setState({ fetchingDataFromApi: false });
      toast.error("Ha ocurrido un error, intente de nuevo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
      });
    }
  }

  async showAttendanceList(lessonId) {
    this.setState({
      fetchingDataFromApi: true,
    });
    try {
      const res = await axios.get(`${GET_ATTENDANCES}/${lessonId}`);
      this.setState({
        windowModalActive: true,
        attendances: res.data,
        fetchingDataFromApi: false,
      });
    } catch (e) {
      console.error(e);
    }
  }

  render() {
    const { lessons } = this.state;

    const table = lessons.length ? (
      <DataTable
        columns={[
          {
            name: "Titulo",
            selector: "id",
            sortable: true,
            cell: (row) => <span title={row.title}>{row.title}</span>,
          },
          { name: "Creado por", selector: "user.name", sortable: true },
          {
            name: "Clase abre",
            selector: "id",
            sortable: true,
            cell: (row) => <span title={row.start_date}>{row.start_date}</span>,
          },
          {
            name: "Clase cierra",
            selector: "id",
            sortable: true,
            cell: (row) => <span title={row.end_date}>{row.end_date}</span>,
          },

          {
            name: "Asistencia",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Ver lista de asistencia">
                  <i
                    className="fa fa-list"
                    onClick={() => this.showAttendanceList(row.id)}
                  ></i>
                </span>
              </div>
            ),
          },
          {
            name: "Ver clase",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Ir a la clase">
                  <Link target="_blank" to={`/lesson/${row.uuid}`}>
                    <i className="fa fa-book"></i>
                  </Link>
                </span>
              </div>
            ),
          },
          {
            name: "Acciones",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Editar clase">
                  <i
                    className="fa fa-pencil"
                    onClick={() =>
                      this.props.history.push(
                        `/admin/editlesson/${row.level_id}/${row.uuid}`
                      )
                    }
                  ></i>
                </span>
                <span title="Eliminar clase">
                  <i
                    className="fa fa-trash"
                    onClick={() => this.deleteLesson(row)}
                  ></i>
                </span>
              </div>
            ),
          },
        ]}
        data={lessons}
        pagination={true}
        paginationPerPage={20}
        noHeader={true}
      />
    ) : (
      <div>No se encontraron clases para este curso</div>
    );

    return (
      <div>
        <ConfirmCheck
          isActive={this.state.showConfirm}
          callback={this.confirmWindow.bind(this)}
          title="Eliminar clase - "
          message="¿Estás seguro que deseas eliminar este clase? Si eliminas esta clase, toda la informacion relacionada con esta clase tambien se eliminara"
          confirmTextLbl="Por favor confirme el nombre de la clase a eliminar"
          confirmButton="Si"
          cancelButton="No"
          metadata={this.state.lessonToDelete}
          name={
            this.state.lessonToDelete.title
              ? this.state.lessonToDelete.title
              : ""
          }
          confirmPlaceholder="Nombre de la clase"
        ></ConfirmCheck>

        {this.state.fetchingDataFromApi ? (
          <FullScreenLoader></FullScreenLoader>
        ) : null}

        <Attendances
          title={`Lista de asistencia - ${this.state.attendances.title}`}
          isActive={this.state.windowModalActive}
          close={() => this.setState({ windowModalActive: false })}
          data={
            this.state.attendances.attendances
              ? this.state.attendances.attendances
              : {}
          }
        ></Attendances>

        <div className={"columns"}>
          <div className={"column is-10 is-offset-1 mt-20"}>
            <h3 className={"is-main-header"}>
              <i className="fa fa-book" style={{ marginRight: "5px" }}></i>
              Lista de clases
            </h3>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">Seleccione un curso</label>
                  <InputSelectField
                    name={"level_id"}
                    value={this.state.level_id}
                    onChange={this.onChange}
                    placeholder={"Curso"}
                    data={this.state.levels}
                    isFullWidth={true}
                    medium={false}
                  />
                </div>
              </div>
            </div>

            {this.state.level_id != "-1" ? (
              table
            ) : (
              <div>Por favor seleccione un curso</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Lessons);
