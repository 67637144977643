import { SET_LEVELS } from "../actions/types";
import { fetchLevels } from "../actions/levelActions";

const initialState = {
    levels: null,
};

export default function(state = initialState, action, dispatch) {
    switch (action.type) {
        case SET_LEVELS:                        
            return {
                ...state,
                levels: action.payload,
            };
        default:
            return state;
    }
}