import React, { Component } from "react";
import axios from "axios";
import { GET_ASSIGNMENTS_LIST } from "../../../../helpers/api";
import DashboardHeader from "../layout/DashboardHeader";
import moment from "moment";
import "moment/locale/es";
import Loader from "../../../Common/Loader";
import { Link } from "react-router-dom";
import svg from "../../../../assets/calendar.svg";
import EmptyResults from "../layout/EmptyResults";
import TitleHeader from "../layout/TitleHeader";
import ReactPaginate from "react-paginate";
import LazyLoader from "../../Shared/Lessons/Components/LazyLoader";

export default class AssignmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasks: [],
      fetchingData: true,
      perPage: 5,
      offset: 0,
      filteredTasks: [],
    };
    moment().locale("es");
  }

  async componentDidMount() {
    try {
      const res = await axios.get(GET_ASSIGNMENTS_LIST);

      let tasks = res.data.assignments;
      const now = moment(res.data.now);
      for (let task of tasks) {
        const endDate = moment(task.end_date);
        task["difference"] = endDate.diff(now);
        task["humanize"] = moment.duration(endDate.diff(now)).humanize();
      }

      const perPage = 5;
      const pageCount = Math.ceil(tasks.length / perPage);

      this.setState(
        {
          tasks: tasks,
          fetchingData: false,
          pageCount: pageCount,
          perPage: perPage,
        },
        () => this.loadData()
      );
    } catch (e) {
      this.setState({
        fetchingData: false,
      });
    }
  }

  loadData() {
    const { perPage, offset, tasks } = this.state;
    const filteredTasks = [];
    let maxCount = perPage + offset;
    if (maxCount > tasks.length) {
      maxCount = tasks.length;
    }
    for (let i = offset; i < maxCount; i++) {
      filteredTasks.push(tasks[i]);
    }

    this.setState({
      filteredTasks: filteredTasks,
    });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perPage);

    this.setState({ offset: offset }, () => {
      this.loadData();
    });
  };

  render() {
    const { tasks, fetchingData, filteredTasks, pageCount } = this.state;

    return (
      <div>
        <TitleHeader text="Talleres" image={svg}></TitleHeader>

        <div className="columns">
          <div className="column is-8 is-offset-2">
            {fetchingData ? (
              <LazyLoader></LazyLoader>
            ) : (
              <div>
                {tasks.length > 0 ? (
                  <React.Fragment>
                    <ul className="forum-list">
                      {filteredTasks.map((task) => {
                        return (
                          <li key={task.id}>
                            <div className="forum-list__content">
                              <div>
                                <i className="fa fa-book "></i>
                              </div>
                              <div className="forum-list__meta">
                                <div>{task.title}</div>
                                <div>
                                  {task.difference <= 0
                                    ? "Fecha del taller ha terminado"
                                    : `Faltan ${task.humanize} para que se cierre este taller`}
                                </div>
                                <div>
                                  Creado por: <span>{task.user.name}</span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Link
                                className="forum-list__button-go"
                                to={"/student/task/" + task.id}
                              >
                                Ir al taller
                              </Link>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {pageCount > 1 && (
                      <nav
                        className="pagination mt-20 mb-20"
                        role="navigation"
                        aria-label="pagination"
                      >
                        <ReactPaginate
                          previousLabel={"Anterior"}
                          nextLabel={"Siguiente"}
                          breakLabel={"..."}
                          breakClassName={"pagination-link"}
                          pageCount={this.state.pageCount}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination-list"}
                          pageLinkClassName={"pagination-link"}
                          activeLinkClassName={"is-current"}
                          previousClassName={"button"}
                          nextClassName={"button"}
                        />
                      </nav>
                    )}
                  </React.Fragment>
                ) : (
                  <EmptyResults text="No te preocupes! No hay talleres!"></EmptyResults>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
