import { FORUM_REPLIES_HAVE_CHANGED } from "../actions/types";

const initialState = {};

export default function(state = initialState, action) {
    switch (action.type) {
        case FORUM_REPLIES_HAVE_CHANGED:
            return action.payload;        
        default:
            return state;
    }
}