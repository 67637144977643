import React from "react";

export default function LazyLoader() {
  return (
    <div className="columns is-centered">
      <div className="column is-10">
        <div className="ph-item">
          <div className="ph-col-12">
            <div className="ph-picture"></div>
            <div className="ph-row">
              <div className="ph-col-12"></div>
            </div>
          </div>
          <div className="ph-col-12">
            <div className="ph-picture"></div>
            <div className="ph-row">
              <div className="ph-col-12"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
