import React, { Component } from "react";
import {connect} from "react-redux";
import {withRouter, Prompt} from "react-router-dom";
import ExamOption from "./ExamOption";
import classnames from "classnames";
import axios from 'axios';
import Loader from "../../../Common/Loader";
import Confirm from "../../../UI/Modal/Confirm";
import { toast } from 'react-toastify';
import { GET_EXAM_QUESTIONS_FOR_EXAM, EXAM_TEST_RESULT, CREATE_OR_CHECK_STUDENT_EXAM_ROW, GET_EXAM_QUESTIONS_BY_EXAM_LEVEL } from "../../../../helpers/api";
import { getMinutesAndSeconds } from "../../../../helpers/helpers";
import FullScreenLoader from "../../../Common/FullScreenLoader";


class Exam extends Component {

    constructor() {
        super();
        this.state = {
            counter: 0,
            showAnswersPage: false,
            activeQuestion: {},
            activeIndex: 0,
            questions: [],
            exam: {},
            loadingImage: true,
            studentAnswers: {},
            showConfirm: false,
            timeLeftLbl: "00:00",
            isBlocking: false,
            calculatingResult: false
        };

        this.currentDate = new Date();
        this.timerInterval = null;       
    }

    async fetchQuestions (id) {
        try {
            let studentAnswers = localStorage.getItem('studentAnswers') || null;
            let activeIndex = localStorage.getItem('activeIndex') || 0;
            if (studentAnswers !== null) {
                this.setState({
                    studentAnswers: JSON.parse(studentAnswers),
                    activeIndex: parseInt(activeIndex)
                })
            }
            const res = await axios.get(GET_EXAM_QUESTIONS_FOR_EXAM + "/" + id);
            this.setState({
                exam: res.data.exam,
                questions: res.data.questions,
                counter: res.data.questions.length,
                activeQuestion: res.data.questions[this.state.activeIndex]
            });
        } catch(e) {

        }
    }

    createTimer(timeLeft) {        
        let { minutes, seconds } = getMinutesAndSeconds(timeLeft);

        this.timerInterval = setInterval(() => {           
            if (seconds == 0) {
                if (minutes > 0) {
                    minutes--;
                    seconds = 59;
                } else {
                    clearInterval(this.timerInterval);
                    toast.error("El tiempo para esta prueba ha terminado", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 4000
                    });
                    this.setState({ isBlocking : false }, () => {
                        this.props.history.push("/student/examslist")
                    });                   
                    
                }
            } else {
                seconds--;
            }           

            const minutesLbl = minutes > 9 ? minutes : "0" + minutes.toString();
            const secondsLbl = seconds > 9 ? seconds : "0" + seconds.toString();
            
            this.setState({
                timeLeftLbl: minutesLbl + ":" + secondsLbl
            })
        }, 1000);
    }
    
    componentWillUnmount () {
        try {
            clearInterval(this.timerInterval);
        } catch(e) {

        }
        
    }

    async componentDidMount() {        

        const { id } = this.props.match.params;   
        
        if (parseInt(id) <= 0) {            
            this.props.history.push("/student/examslist");
            return;            
        }
        
        try {
            const res = await axios.get(CREATE_OR_CHECK_STUDENT_EXAM_ROW + "/" + id);
            
            if (res.status.toString() == "200") {
                
                if (!res.data.timeUp && !res.data.finished) {

                    this.createTimer(res.data.time_left)
                    
                    this.fetchQuestions(id);

                    this.setState({ isBlocking : true });                   
                }

                if (res.data.finished) {                    
                    toast.info("Ya ha presentado este prueba anteriormente", {
                        position: toast.POSITION.BOTTOM_CENTER,
                        autoClose: 2000
                    });
                    this.props.history.push("/student/examslist")
                }
            }
        } catch(e) {

            if(e.response.data.time_up && !e.response.data.finished) 
            {
                toast.error("El tiempo para esta prueba ha terminado", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 4000
                });
                this.props.history.push("/student/examslist");
            }

            if (e.response.status.toString() == "404" || e.response.status.toString() == "401") {
                this.props.history.push("/student/examslist");
            } 
        }                
    }

    getQuestion(dir = "next") {
        const { questions, activeIndex, counter } = this.state;
        if (dir === "next") {
            if (activeIndex + 1 > (counter - 1)) {
                return;
            }
            this.setState({
                activeQuestion: questions[activeIndex + 1],
                activeIndex: activeIndex + 1,
                loadingImage: true,
            })
        } else if("prev"){
            if (activeIndex === 0) {
                return;
            }
            this.setState({
                activeQuestion: questions[activeIndex - 1],
                activeIndex: activeIndex - 1,
                loadingImage: true,
            })
        }
    }

    imageLoaded() {
        this.setState({
            loadingImage: false
        })
    }

    toggleAnswersPage() {
        const { showAnswersPage } = this.state;
        this.setState({
            showAnswersPage: !showAnswersPage
        });
    }

    handleSelectedChoice(data) {
        let { studentAnswers } = this.state;
        studentAnswers[data.question] = data.answer;
        this.setState({studentAnswers: studentAnswers});        
        localStorage.setItem('studentAnswers', JSON.stringify(studentAnswers));
        localStorage.setItem('activeIndex', this.state.activeIndex);
    }

    generateOptions() {
        let code = [];
        let final = [];
        for (let i = 0; i < this.state.counter; i++) {
            if (i % 9 == 0) {
                final.push(
                    <div key={i} className={'options-grid'}>{code}</div>
                );
                code = [];
            }
            const questionNumber = i + 1;
            if (this.state.studentAnswers[questionNumber]) {
                code.push(<ExamOption optionSelected={this.state.studentAnswers[questionNumber]} onSelectedChoice={this.handleSelectedChoice.bind(this)} key={questionNumber} id={questionNumber}></ExamOption>);
            }else {
                code.push(<ExamOption optionSelected={0}  onSelectedChoice={this.handleSelectedChoice.bind(this)} key={questionNumber} id={questionNumber}></ExamOption>);
            }

        }
        final.push(
            <div key={1000} className={'options-grid'}>{code}</div>
        );
        return final;
    }

    finishExam() {
        if (Object.keys(this.state.studentAnswers).length === this.state.counter) {
            this.setState({
                showConfirm: true
            });
        } else {
            toast.error("Por favor responda todas las preguntas", {
                position: toast.POSITION.TOP_CENTER
            });
        }
    }

    async confirmWindow(res) {
        this.setState({
            showConfirm: false
        });

        if (!res) {
            return;
        }        

        try {

            clearInterval(this.timerInterval)

            this.setState({ isBlocking : false, calculatingResult: true });

            const { id } = this.props.match.params;

            //fetch questions with answers            
            const dataFetched = await axios.get(GET_EXAM_QUESTIONS_BY_EXAM_LEVEL + "/" + id) 
            const questions = dataFetched.data;

            const { studentAnswers } = this.state;
            let correctAnswers = 0;
            let metaData = [];
            for (let i = 0; i < questions.length; i++) {
                metaData.push({
                    question: {
                        id: questions[i].id,
                        answer: questions[i].answer,
                        studentAnswer: studentAnswers[i+1]
                    },
    
                });
                if (questions[i].answer == studentAnswers[i+1]) {
                    correctAnswers++;
                }
            } 
                      
            axios.post(EXAM_TEST_RESULT, {
                "exam_level_id": id,                
                "exam_metadata": JSON.stringify(metaData),
                "number_questions": this.state.counter,
                "result": correctAnswers
            }).then(res => {
                if (res.status == 200) {
                    localStorage.removeItem("studentAnswers");
                    localStorage.removeItem("activeIndex");
                    this.setState({ calculatingResult: false }, () => {
                        toast.info("Se ha enviado su prueba.", {
                            position: toast.POSITION.BOTTOM_CENTER
                        });
                        this.props.history.push("/student/examslist");
                    });
                                      
                }
            }).catch(err => {
                toast.error("Ha ocurrido un error, por favor intente de nuevo", {
                    position: toast.POSITION.TOP_CENTER
                });
            })  

        }catch(ex) {
            toast.error("Error al calcular resultado, intente de nuevo", {
                position: toast.POSITION.TOP_CENTER
            });
        } 
        
    }

    getCurrentDate () {
        try {
            return this.currentDate.toLocaleDateString('en-GB');
        } catch(e) {
            return "";
        }        
    }

    render() {

        let { isBlocking, calculatingResult } = this.state;

        return (
            <div className={"exam-container"}>
                { calculatingResult ? <FullScreenLoader></FullScreenLoader> : null }
                <Prompt
                    when={isBlocking}
                    message={ "Esta seguro(a) que quiere salir? Si sales de la prueba perderas todo tu progreso" }
                ></Prompt>
                <Confirm
                    isActive={this.state.showConfirm}
                    callback={this.confirmWindow.bind(this)}
                    title="Finalizar prueba"
                    message="Estas seguro que deseas enviar la prueba?"
                    confirmButton="Si"
                    cancelButton="No"
                ></Confirm>
                <div className={"exam-container__header"}>
                    <div className="exam-container__header__content">
                        <div className="exam-container__header__left">
                            <div>
                                NOMBRE: { this.props.auth.user.name }
                            </div>
                            <div>PRUEBA: { this.state.exam.name } </div> 
                            <div>FECHA: { this.getCurrentDate()  } </div>                            
                        </div>
                        <div className="exam-container__header__right">
                            <div>TIEMPO RESTANTE: { this.state.timeLeftLbl }</div>
                        </div>
                    </div>
                </div>
                <div className="exam-container__body">
                    <div className="exam-container__body__info">
                        Pregunta { this.state.activeIndex + 1 }
                    </div>
                    <div className="exam-container__body__question">
                        {
                            this.state.loadingImage ? <Loader/> : null
                        }
                        <img
                            className={classnames("", { "is-hidden": this.state.loadingImage })}
                            onLoad={()=> this.imageLoaded()} src={this.state.activeQuestion.question_url} alt=""/>
                    </div>
                    <div className="exam-container__body__controls">
                        <button className={
                            classnames("button", {
                                "is-hidden": this.state.activeIndex === 0
                            })
                        }
                            onClick={() => this.getQuestion("prev")}
                        >Pregunta anterior</button>
                        <button className={
                            classnames("button", {
                                "is-hidden": this.state.activeIndex === (this.state.counter - 1)
                            })
                        }
                            onClick={() => this.getQuestion()}
                        >Siguiente pregunta</button>

                        {
                            this.state.activeIndex === (this.state.counter - 1) ? <button className="button"
                                    onClick={() => this.finishExam()}
                            >Terminar prueba</button> : null
                        }

                    </div>
                </div>
                <div className={classnames("exam-container__answer-box-container", {
                    "exam-container__answer-box-container--hide": !this.state.showAnswersPage
                })}>
                    <div className={"exam-container__box-header"}>
                        <div className={"exam-container__box-header__content"}>
                            <div className={"exam-container__box-header__title"}>
                                <span>Hoja de respuestas</span>
                            </div>
                            <div className={"exam-container__box-header__close_button"}>
                                <span onClick={() => this.toggleAnswersPage()}>
                                    <i className={classnames("fa", {
                                        "fa-window-close": this.state.showAnswersPage,
                                        "fa-expand": !this.state.showAnswersPage
                                    })}></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={"exam-container__options-container"}>
                        { this.generateOptions() }
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    null
)(withRouter(Exam));
