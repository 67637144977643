import React, { Component } from "react";
import InputSelectField from "../../../UI/Form/InputSelectField";
import { GET_FORUMS, FORUM_REMOVE } from "../../../../helpers/api";
import axios from "axios";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmCheck from "../../../UI/Modal/ConfirmCheck";
import FullScreenLoader from "../../../Common/FullScreenLoader";

class ForumAdminList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levels: this.props.levels,
      forums: [],
      level_id: "-1",
      sendingRequest: false,
      fetchingDataFromApi: false,
      showConfirm: false,
      forumToDelete: {},
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const val = e.target.value;

    this.setState({ level_id: val.toString() });

    if (val == -1) return;

    this.setState({ fetchingDataFromApi: true });

    this.fetchForumsByLevel(val).then((res) => {
      this.setState({
        fetchingDataFromApi: false,
        forums: res.data.all_forums,
      });
    });
  }

  async fetchForumsByLevel(id) {
    try {
      return await axios.get(GET_FORUMS + "/" + id);
    } catch (e) {
      console.log(e);
    }
  }

  showForum(forum) {
    this.props.history.push(
      `/admin/detailforum/${forum.level_id}/${forum.slug}`
    );
  }

  deleteForum(row) {
    this.setState({
      forumToDelete: row,
      showConfirm: true,
    });
  }

  async confirmWindow(res) {
    this.setState({
      showConfirm: false,
      forumToDelete: {},
    });

    if (!res.res) {
      return;
    }

    this.setState({ fetchingDataFromApi: true });

    try {
      const data = await axios.delete(FORUM_REMOVE + "/" + res.metadata.id);

      let { forums } = this.state;

      forums = forums.filter((forum) => forum.id != data.data.id);

      this.setState({
        forums,
        fetchingDataFromApi: false,
      });

      toast.info("Foro eliminado correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (e) {
      this.setState({ fetchingDataFromApi: false });
      toast.error("Ha ocurrido un error, intente de nuevo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
      });
    }
  }

  render() {
    const { forums } = this.state;

    const table = forums.length ? (
      <DataTable
        columns={[
          {
            name: "Titulo",
            selector: "id",
            sortable: true,
            cell: (row) => <span title={row.title}>{row.title}</span>,
          },
          { name: "Creado por", selector: "user.name", sortable: true },
          { name: "Foro abre", selector: "start_date", sortable: true },
          { name: "Foro Cierra", selector: "end_date", sortable: true },
          {
            name: "Ver foro",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Ver foro">
                  <i
                    className="fa fa-comments-o"
                    onClick={() => this.showForum(row)}
                  ></i>
                </span>
              </div>
            ),
          },
          {
            name: "Acciones",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Editar foro">
                  <i
                    className="fa fa-pencil"
                    onClick={() =>
                      this.props.history.push(
                        `/admin/editforum/${row.level_id}/${row.slug}`
                      )
                    }
                  ></i>
                </span>
                <span title="Eliminar foro">
                  <i
                    className="fa fa-trash"
                    onClick={() => this.deleteForum(row)}
                  ></i>
                </span>
              </div>
            ),
          },
        ]}
        data={forums}
        pagination={true}
        paginationPerPage={20}
        noHeader={true}
      />
    ) : (
      <div>No se encontraron foros en este curso</div>
    );

    return (
      <div>
        <ConfirmCheck
          isActive={this.state.showConfirm}
          callback={this.confirmWindow.bind(this)}
          title="Eliminar foro"
          message="¿Estás seguro que deseas eliminar este foro? Si eliminas esta foro, todos las respuestas de este foro seran eliminadas"
          confirmTextLbl="Por favor confirme el nombre del foro a eliminar"
          confirmButton="Si"
          cancelButton="No"
          metadata={this.state.forumToDelete}
          name={
            this.state.forumToDelete.title ? this.state.forumToDelete.title : ""
          }
          confirmPlaceholder="Nombre del foro"
        ></ConfirmCheck>

        {this.state.fetchingDataFromApi ? (
          <FullScreenLoader></FullScreenLoader>
        ) : null}
        <div className={"columns"}>
          <div className={"column is-10 is-offset-1 mt-20"}>
            <h3 className={"is-main-header"}>
              <i className="fa fa-comments" style={{ marginRight: "5px" }}></i>
              Lista de Foros
            </h3>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">Seleccione un curso</label>
                  <InputSelectField
                    name={"level_id"}
                    value={this.state.level_id}
                    onChange={this.onChange}
                    placeholder={"Curso"}
                    data={this.state.levels}
                    isFullWidth={true}
                    medium={false}
                  />
                </div>
              </div>
            </div>

            {this.state.level_id != "-1" ? (
              table
            ) : (
              <div>Por favor seleccione un curso</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ForumAdminList);
