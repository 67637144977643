const MAX_ICFES_RESULT = 100;
const MAX_EXAM_RESULT = 5.0;

export const gradeExam = (result) => {
    if (result.result == 0) {
        return 0;
    }

    if (result.type_exam == "Icfes") {
        return Math.round((MAX_ICFES_RESULT / result.number_questions) * result.result);
    }

    if (result.type_exam == "Exam") {
        return parseFloat(((MAX_EXAM_RESULT / result.number_questions) * result.result).toFixed(1));        
    }    

    
}