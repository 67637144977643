import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const User = ({ user, currentUserId }) => {
  return (
    <li key={user.id}>
      <span className="user-name">{user.name}</span>
      <span className="user-online">
        <span className="green-dot"></span>{" "}
        <span>
          {parseInt(user.id) === parseInt(currentUserId) ? "Yo" : "Online"}{" "}
        </span>
      </span>
    </li>
  );
};

User.propTypes = {
  user: PropTypes.object,
  currentUserId: PropTypes.number,
};

export default User;
