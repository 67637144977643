import React, { Component } from "react";
import classnames from "classnames";
import { toast } from "react-toastify";
import { FORUM_REPLY_REMOVE } from "../../../../helpers/api";
import axios from "axios";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import "moment/locale/es";
import ReplyCommentForm from "./ReplyCommentForm";
import { deleteComment } from "../../../../actions/forumsActions";
import { connect } from "react-redux";
import CounterTextArea from "../../../UI/Form/CounterTextArea";

class SingleComment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modifyingReply: false,
      commentEditingContent: "",
      characterCount: 0,
      confirmWindowActive: false,
      sendingRequest: false,
      replyToComment: false,
      errors: {},
    };

    moment().locale("es");
  }

  async editComment(reply) {
    const { commentEditingContent } = this.state;
    if (
      commentEditingContent.trim().toLowerCase() ==
      reply.content.trim().toLowerCase()
    ) {
      this.setState({ commentEditingContent: "", modifyingReply: false });
      return;
    }

    this.setState({
      sendingRequest: true,
    });

    const data = {
      content: commentEditingContent,
    };

    try {
      const res = await axios.patch(`${FORUM_REPLY_REMOVE}/${reply.id}`, data);
      reply.content = commentEditingContent;
      this.setState({
        commentToModify: "",
        sendingRequest: false,
        modifyingReply: false,
      });
    } catch (e) {
      if (e.response.data.content) {
        toast.error(e.response.data.content, {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
      } else {
        toast.error("Error! Intente nuevamente", {
          position: toast.POSITION.BOTTOM_CENTER,
          autoClose: 2000,
        });
      }

      this.setState({ sendingRequest: false });
    }
  }

  onChange = (val) => {
    const length = val.length;
    if (length > 500) {
      return;
    }
    this.setState({
      commentEditingContent: val,
      characterCount: length,
    });
  };

  async deleteComment() {
    confirmAlert({
      message: "Estas seguro(a) que quieres eliminar el comentario",
      buttons: [
        {
          label: "Si",
          onClick: () => this.removeCommentFromDB(),
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  }

  removeCommentFromDB = async () => {
    const { reply } = this.props;

    try {
      const res = await this.props.deleteComment(reply.id);
    } catch (e) {
      toast.error("Error! Intente nuevamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
    }
  };

  renderCommentReplies() {
    const { reply, now, user, isForumOpened, forumId } = this.props;

    if (!reply || !reply.comment_replies) {
      return;
    }

    if (reply.comment_replies.length > 0) {
      return reply.comment_replies.map((commentReply) => {
        return (
          <SingleComment
            forumId={forumId}
            key={commentReply.id}
            isForumOpened={isForumOpened}
            reply={commentReply}
            now={now}
            user={user}
          ></SingleComment>
        );
      });
    }
  }

  render() {
    const { reply, now, user, isForumOpened } = this.props;

    const { characterCount, errors, commentEditingContent } = this.state;

    const time = moment(reply.created_at);
    const diff = moment.duration(time.diff(now)).humanize();

    return (
      <article className="media" key={reply.id}>
        <figure className="media-left">
          <p className="image comment-avatar">
            {!reply.user.profile ? (
              <img src="https://www.purocodigo.net/public/apps/cdn/images/assets/student.png" />
            ) : (
              <img src={reply.user.profile.avatar_url} />
            )}
          </p>
        </figure>
        <div className="media-content">
          <div className="content">
            <div>
              <strong>{reply.user.name}</strong> <small>Hace {diff} </small>
              <br />
              {this.state.modifyingReply ? (
                <CounterTextArea
                  characterCount={characterCount}
                  onChangeCb={(val) => this.onChange(val)}
                  error={errors.content}
                  content={commentEditingContent}
                ></CounterTextArea>
              ) : (
                reply.content
              )}
            </div>
          </div>
          {reply.user.id == user.id &&
            isForumOpened &&
            !this.state.modifyingReply &&
            !this.state.replyToComment && (
              <nav className="level is-mobile comment-menu">
                <div className="level-left">
                  <a
                    title="Editar"
                    className="level-item"
                    onClick={() =>
                      this.setState({
                        commentEditingContent: reply.content,
                        modifyingReply: true,
                      })
                    }
                  >
                    <span className="icon is-small">
                      <i className="fa fa-pencil"></i>
                    </span>
                  </a>
                  {!reply.parent_id && reply.comment_replies.length == 0 && (
                    <a
                      title="Eliminar"
                      onClick={() => this.deleteComment()}
                      className="level-item"
                    >
                      <span className="icon is-small">
                        <i className="fa fa-trash"></i>
                      </span>
                    </a>
                  )}
                </div>
              </nav>
            )}
          {reply.user.id == user.id && this.state.modifyingReply && (
            <div>
              <button
                className={classnames("button is-outlined", {
                  "is-loading": this.state.sendingRequest,
                })}
                onClick={() => this.editComment(reply)}
              >
                <i className="fa fa-save"></i>&nbsp;Guardar
              </button>
              <button
                onClick={() =>
                  this.setState({
                    commentEditingContent: "",
                    modifyingReply: false,
                  })
                }
                className="button"
                style={{ marginLeft: "10px" }}
              >
                <i className="fa fa-times"></i>&nbsp;Cancelar
              </button>
            </div>
          )}
          {isForumOpened && !this.state.modifyingReply ? (
            this.state.replyToComment ? (
              <ReplyCommentForm
                parentId={reply.parent_id ? reply.parent_id : reply.id}
                forumId={this.props.forumId}
                cancelReply={() => this.setState({ replyToComment: false })}
              ></ReplyCommentForm>
            ) : (
              <small>
                <a onClick={() => this.setState({ replyToComment: true })}>
                  Responder
                </a>
              </small>
            )
          ) : (
            <div></div>
          )}

          <div style={{ marginTop: "10px" }}>{this.renderCommentReplies()}</div>
        </div>
      </article>
    );
  }
}

export default connect(null, { deleteComment })(SingleComment);
