import React, { Component } from "react";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import axios from "axios";
import InputSelectField from "../../../UI/Form/InputSelectField";
import DataTable from 'react-data-table-component';
import InputTextField from "../../../UI/Form/InputTextField";
import { STUDENTS_BY_LEVEL, STUDENT_RESULTS, DELETE_STUDENT, RESTORE_PW } from "../../../../helpers/api";
import ContentWindow from "../../../UI/Modal/ContentWindow";
import Confirm from "../../../UI/Modal/Confirm";
import { toast } from "react-toastify";


class StudentList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            name: "",           
            levels: this.props.levels,
            students: [],
            filteredStudents: [],
            level_id: "-1",
            sendingRequest: false,
            fetchingDataFromApi: false,
            windowModalActive: false,
            modalData: {
                exams: [],
                user: {}
            },
            confirmWindowActive: false,
            studentToDelete: {},
            confirmRestorePassword: false,
            studentToRestore: {},
        };
        this.onChange = this.onChange.bind(this);
        this.findStudent = this.findStudent.bind(this);
    }

    onChange(e) {


        const val = e.target.value;

        this.setState({ level_id: val.toString()  });

        if (val == -1) return;

        this.setState({ fetchingDataFromApi: true });

        this.fetchStudentsByLevel(val).then(students => {
            this.setState({
                students: students.data,
                filteredStudents: students.data,
                fetchingDataFromApi: false
            });
        });
    }

    findStudent(e) {

        this.setState({
            name: e.target.value
        });

        const name = e.target.value.toLowerCase();

        let { students } = this.state;

        students = students.filter(student => {
            return student.name.toLowerCase().trim().includes(name);
        });

        this.setState({
            filteredStudents: students
        })
    }   

    async fetchStudentsByLevel(id) {

        try {
            return await axios.get(STUDENTS_BY_LEVEL + "/" + id);
        }catch (e) {
            console.log(e)
        }
    }

    confirmDelete (row) {
        this.setState({ studentToDelete: row, confirmWindowActive: true})
    }

    async deleteStudent(res) {
        const { studentToDelete } = this.state;
        this.setState({ studentToDelete: {}, confirmWindowActive: false})

        if (!res) {
            return;
        }

        try {            
            this.setState({ fetchingDataFromApi: true })
            const res = await axios.delete(DELETE_STUDENT + "/" + studentToDelete.id);
            toast.info("Estudiante eliminado", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:1500
            });

            let { students } = this.state;
            students = students.filter(student => student.id != studentToDelete.id);
            this.setState({ students: students, filteredStudents: students, fetchingDataFromApi: false });
        } catch (e) {
            this.setState({ fetchingDataFromApi: false })
        }
    }

    async showResults(row) {

        this.setState({
            fetchingDataFromApi: true
        })

        try {
            const results = await axios.get(STUDENT_RESULTS + "/" + row.id);

            this.setState({
                windowModalActive: true,
                modalData: {
                    user: row,
                    exams: results.data                    
                },
                fetchingDataFromApi: false
            });
        }catch(e) {
            this.setState({
                fetchingDataFromApi: false
            })
        }        
    }

    restorePassword(row) {
       this.setState({ studentToRestore: row, confirmRestorePassword: true})
    }

    async restorePasswordCallback(res) {
        const { studentToRestore } = this.state;
        this.setState({ studentToRestore: {}, confirmRestorePassword: false})

        if (!res) {
            return;
        }

        try {            
            this.setState({ fetchingDataFromApi: true })
            const res = await axios.patch(RESTORE_PW, { student_id: studentToRestore.id });
            toast.info("Contraseña restaurada", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:1500
            });            
            this.setState({ fetchingDataFromApi: false })
        } catch (e) {
            toast.error("Ha ocurrido un error, intente de nuevo", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:1500
            });
            this.setState({ fetchingDataFromApi: false })
        }
    }
   

    render() {        

        const { filteredStudents } = this.state;

        const table = filteredStudents.length ? <DataTable
            columns={[
                { name: 'Número de documento', selector: 'identification', sortable: true },
                { name: 'Nombre', selector: 'name', sortable: true },                
                { name: 'Resultados',
                 selector: 'id',
                 cell: row => <div className="icon-actions">
                     <span title="Ver resultados"><i className="fa fa-list" onClick={() => this.showResults(row)}></i></span>
                 </div>,},
                { name: 'Acciones',
                selector: 'id',
                cell: row => <div className="icon-actions">
                    <span title="Eliminar estudiante"><i className="fa fa-trash" onClick={() => this.confirmDelete(row)}></i></span>
                </div>,},
                { name: 'Restaurar contraseña',
                selector: 'id',
                cell: row => <div className="icon-actions">
                    <span title="Reiniciar contraseña"><i className="fa fa-pencil" onClick={() => this.restorePassword(row)}></i></span>
                </div>,}
            ]}
            data={filteredStudents}
            pagination={true}
            paginationPerPage={20}
            noHeader={true}

        /> : <div>
            No se encontraron estudiantes en este curso
        </div>;


        return (
            <div>
                { this.state.fetchingDataFromApi ? <FullScreenLoader></FullScreenLoader> : null }


                <Confirm
                    callback={this.restorePasswordCallback.bind(this)}
                    isActive={this.state.confirmRestorePassword}
                    confirmButton="Si"
                    cancelButton="No"
                    title={"Reiniciar contraseña de " + this.state.studentToRestore.name + "?"}
                    message="Quieres restaurar la contraseña del estudiante?"
                ></Confirm>


                <Confirm
                    callback={this.deleteStudent.bind(this)}
                    isActive={this.state.confirmWindowActive}
                    confirmButton="Si"
                    cancelButton="No"
                    title={"Eliminar estudiante " + this.state.studentToDelete.name + "?"}
                    message="Si eliminas este estudiante, todos los resultados de las pruebas tomadas por el estudiante tambien seran eliminadas"
                ></Confirm>


                {
                    this.state.windowModalActive &&
                    <ContentWindow
                    title={ this.state.modalData.user.name }
                    isActive={this.state.windowModalActive}
                    close={()=> this.setState({ windowModalActive: false })}
                    data={ this.state.modalData.exams }
                    ></ContentWindow>
                }

                <div className={'columns'}>
                    <div className={'column is-10 is-offset-1 mt-20'}>
                        <h3 className={'is-main-header'}>
                        <i className="fa fa-users" style={{ marginRight:'5px' }}></i>
                        Lista de estudiantes</h3>
                       <div className="columns">
                            <div className="column is-5">
                                <div className="field">
                                    <label className="label">Seleccione un curso</label>
                                    <InputSelectField
                                        name={'level_id'}
                                        value={this.state.level_id}
                                        onChange={this.onChange}
                                        placeholder={'Curso'}                                
                                        data={this.state.levels}
                                        isFullWidth={true}
                                        medium={false}
                                    />
                                </div>
                            </div>
                            <div className="column is-7">
                                { this.state.level_id != '-1' ? (<div className="field">
                                    <label className="label">Buscar estudiante</label>
                                    <InputTextField
                                        name={'name'}
                                        value={this.state.name}
                                        onChange={this.findStudent}
                                        placeholder={'Nombre del estudiante'}                                
                                    />
                                </div>) : '' }
                            </div>
                        </div>
                       

                        { this.state.level_id != '-1' ? table : <div>
                            Por favor seleccione un curso
                        </div> }



                    </div>
                </div>
            </div>
        );
    }
}

export default StudentList;
