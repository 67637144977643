import React, { Component } from "react";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import InputSelectField from "../../../UI/Form/InputSelectField";
import axios from "axios";
import { GET_EXAMS, GET_EXAM_QUESTIONS } from "../../../../helpers/api";


class ExamSolve extends Component {

    constructor() {
        super();
        this.state = {
            fetchingDataFromApi: false,
            exams: [],
            exam_id: '-1',       
            questions: []    
        };       

        this.onSelectExam = this.onSelectExam.bind(this); 
    }   

    async onSelectExam(e) {
        this.setState({ [e.target.name]: e.target.value });

        const examId = e.target.value;        

        if (examId == "-1") return;

        this.setState({ fetchingDataFromApi: true })

        try {
            const data = await axios.get(GET_EXAM_QUESTIONS + "/" + examId);
       
            this.setState({
                questions: data.data,
                fetchingDataFromApi: false
            })
        }catch(e) {
            this.setState({ fetchingDataFromApi: true })
        }

        
    }

    async componentDidMount() {
        const data = await axios.get(GET_EXAMS);
        this.setState({
            exams: data.data
        });        
        
    }

    translateNumber(number) {
        switch (number.toString()) {
            case "1":
                return 'A';
            case "2":
                return 'B';
            case "3":
                return 'C';
            case "4":
                return 'D';
        }
    }    


    render() {
        return (
            <div>              
                { this.state.fetchingDataFromApi ? <FullScreenLoader></FullScreenLoader> : null }
                <div className={'columns'}>
                    <div className={'column is-10 is-offset-1 mt-20'}>
                        
                        <h3 className={'is-main-header'}>
                            <i className="fa fa-check" style={{ marginRight:'5px' }}></i>
                            Soluciones de pruebas</h3>

                        <div className="columns">
                            <div className="column is-12">
                                <div className="field">
                                    <label className="label">Seleccione prueba</label>
                                    <InputSelectField
                                        name={'exam_id'}
                                        value={this.state.exam_id}
                                        onChange={this.onSelectExam}
                                        placeholder={'Prueba'}                                        
                                        data={this.state.exams}
                                        isFullWidth={true}
                                        medium={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="exam-solve">
                            <div>
                                {
                                    this.state.questions.map((question, index) => {
                                        return (
                                            <div className="exam-solve__question" key={question.id}>
                                                <div className="exam-solve__header">
                                                    <h5 className="title is-5">Pregunta #{(index + 1)} - Respuesta correcta: <span className="exam-solve__header__right-answer">{ this.translateNumber(question.answer) }</span></h5>
                                                </div>
                                                <div className="exam-solve__image">
                                                    <img src={question.question_url}></img>
                                                </div>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                        </div>

                        
                    </div>
                </div>
            </div>
        );
    }
}

export default ExamSolve;
