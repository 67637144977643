import React from 'react';
import PropTypes from "prop-types";

const FullScreenLoader = ({
   text,
   color
}) => {

  const textClasses = "text " + color;
  const ellipsisClasses = "lds-ellipsis " + color;

  return (
    <div className="full-screen-loader">
      <div className="spinner-container">
        <div className={ellipsisClasses}>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
        <div className={textClasses} >
          {text}
        </div>
      </div>
    </div>
  );
};

FullScreenLoader.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

export default FullScreenLoader;
