import React, { Component } from "react";

import InputSelectField from "../../../UI/Form/InputSelectField";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import axios from "axios";

import {
  REPORT_ATTENDANCE,
  REPORT_ATTENDANCE_DOCUMENT,
  REPORT_ATTENDANCE_CLEAN_RESOURCES,
} from "../../../../helpers/api";
import { withRouter } from "react-router-dom";
registerLocale("es", es);

class AttendanceReport extends Component {
  constructor(props) {
    super(props);

    this.state = {
      levels: this.props.levels,
      level_id: "",
      startDate: new Date(),
      endDate: new Date(),
      errors: {},
      isFetchingData: false,
      generatingDocument: false,
      grades: [],
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  generateReport = async () => {
    try {
      this.setState({
        isFetchingData: true,
        errors: {},
      });
      const res = await axios.post(REPORT_ATTENDANCE, {
        level_id: this.state.level_id,
        start_date: Date.parse(this.state.startDate),
        end_date: Date.parse(this.state.endDate),
      });

      this.setState({
        grades: res.data,
        isFetchingData: false,
      });
    } catch (e) {
      this.setState({
        errors: e.response.data,
        isFetchingData: false,
      });
    }
  };

  generateReportDocument = async () => {
    try {
      this.setState({
        generatingDocument: true,
        errors: {},
      });
      const response = await axios.post(
        REPORT_ATTENDANCE_DOCUMENT,
        {
          level_id: this.state.level_id,
          start_date: Date.parse(this.state.startDate),
          end_date: Date.parse(this.state.endDate),
        },
        {
          responseType: "arraybuffer",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const filenameHeader = response.headers["content-disposition"];
      const fileName = filenameHeader.replace("filename=", "");

      const type = response.headers["content-type"];
      const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();

      this.setState({
        generatingDocument: false,
      });

      axios
        .delete(`${REPORT_ATTENDANCE_CLEAN_RESOURCES}/${fileName}`)
        .then((_) => {})
        .catch((e) => console.log(e));
    } catch (e) {
      this.setState({
        errors: e.response.data,
        generatingDocument: false,
      });
    }
  };

  componentDidMount() {}

  render() {
    const { errors, isFetchingData, grades, generatingDocument } = this.state;

    return (
      <div className="columns">
        <div className="column is-10 is-offset-1">
          <div>
            <h3 className={"is-main-header"}>
              <i className="fa fa-comments-o"></i>&nbsp;
              {"Reporte de Participación"}
            </h3>

            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">Seleccione curso</label>
                  <InputSelectField
                    name={"level_id"}
                    value={this.state.level_id}
                    onChange={this.onChange}
                    placeholder={"Escoja un curso"}
                    error={errors.level_id}
                    data={this.state.levels}
                    isFullWidth={true}
                    medium={false}
                  />
                </div>
              </div>
            </div>

            <div className="columns">
              <div className="column is-6">
                <div className="field">
                  <label className="label">Fecha de inicio</label>
                  <DatePicker
                    className={classnames("input", {
                      "is-danger": errors.start_date,
                    })}
                    selected={this.state.startDate}
                    onChange={(date) =>
                      this.setState({ startDate: date, endDate: date })
                    }
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm"
                    locale="es"
                  />
                  {errors.start_date ? (
                    <p className="help is-danger">{errors.start_date}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="column is-6">
                <div className="field">
                  <label className="label">Fecha de cierre</label>
                  <DatePicker
                    className={classnames("input", {
                      "is-danger": errors.end_date,
                    })}
                    selected={this.state.endDate}
                    onChange={(date) => this.setState({ endDate: date })}
                    showTimeSelect
                    dateFormat="yyyy-MM-dd HH:mm"
                    locale="es"
                    minDate={this.state.startDate}
                  />
                  {errors.end_date ? (
                    <p className="help is-danger">{errors.end_date}</p>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <button
              className={classnames("button is-primary  pull-left", {
                "is-loading": isFetchingData,
              })}
              onClick={this.generateReport}
            >
              Generar reporte
            </button>

            {grades.length == 0 && <div style={{ height: "300px" }}></div>}

            {grades.length > 0 && (
              <div className="mt-10">
                <br></br>
                <h5 className="title is-5 mt-20">
                  Calificaciones de participaciones
                </h5>
                <div id="results-table">
                  <table className="table is-fullwidth is-striped">
                    <thead>
                      <tr>
                        <th>Identificación</th>
                        <th>Nombre</th>
                        <th>Participaciones</th>
                        <th>Nota</th>
                      </tr>
                    </thead>
                    <tbody>
                      {grades.map((d) => {
                        return (
                          <tr key={d.identification}>
                            <td>{d.identification}</td>
                            <td>{d.name}</td>
                            <td>{d.comments}</td>
                            <td>{d.grade}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}

            {grades.length > 0 && (
              <div className="mt-20 mb-20">
                <button
                  style={{ color: "green" }}
                  className={classnames("button", {
                    "is-loading": generatingDocument,
                  })}
                  onClick={this.generateReportDocument}
                >
                  <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                  &nbsp; Descargar excel
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AttendanceReport);
