import React, { Component } from "react";
import { connect } from "react-redux";
import { createForumCommentReply } from "../../../../actions/forumsActions";
import PropTypes from "prop-types";
import classnames from "classnames";
import CounterTextArea from "../../../UI/Form/CounterTextArea";

class ReplyCommentForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      replyContent: "",
      characterCount: 0,
      errors: {},
      sendingRequest: false,
    };
  }

  onChange = (val) => {
    const length = val.length;
    if (length > 500) {
      return;
    }
    this.setState({
      replyContent: val,
      characterCount: length,
    });
  };

  sendCommentReply = async () => {
    const { parentId, forumId } = this.props;

    const data = {
      parent_id: parentId,
      forum_id: forumId,
      content: this.state.replyContent,
    };

    this.setState({ sendingRequest: true });

    try {
      const res = await this.props.createForumCommentReply(data);

      this.setState({ sendingRequest: false });
    } catch (ex) {
      this.setState({ sendingRequest: false });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
      });
    }
  }

  render() {
    const { characterCount, errors, replyContent } = this.state;

    return (
      <div style={{ marginTop: "10px" }}>
        <CounterTextArea
          characterCount={characterCount}
          onChangeCb={(val) => this.onChange(val)}
          error={errors.content}
          content={replyContent}
        ></CounterTextArea>
        <div className="mt-5">
          <button
            className={classnames("button mr-5", {
              "is-loading": this.state.sendingRequest,
            })}
            onClick={() => this.sendCommentReply()}
          >
            <i className="fa fa-commenting-o" aria-hidden="true"></i>
            &nbsp;Responder
          </button>
          <button className="button" onClick={() => this.props.cancelReply()}>
            <i className="fa fa-times" aria-hidden="true"></i>&nbsp;Cancelar
          </button>
        </div>
      </div>
    );
  }
}

ReplyCommentForm.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { createForumCommentReply })(
  ReplyCommentForm
);
