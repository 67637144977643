import React, { Component } from 'react'
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import classnames from 'classnames';
import axios from 'axios';
import { UPDATE_ASSIGNMENT } from '../../../../helpers/api';
import { toast } from 'react-toastify';

class EditAssignmentModal extends Component {

    constructor(props) {
        super(props);
        this.state = {
            startDate: Date.parse(this.props.task.start_date),
            endDate: Date.parse(this.props.task.end_date),
            isSaving: false
        };        
    }

    saveChanges = async (res) => {        
        if (res) {
            const data = {
                start_date: Date.parse(this.state.startDate) || this.state.startDate,
                end_date: Date.parse(this.state.endDate) || this.state.endDate
            };
            const { task } = this.props;     
            
            if (!data.start_date || !data.end_date) {
                return;
            }

            this.setState({ isSaving: true });

            const response = await axios.patch(`${UPDATE_ASSIGNMENT}/${task.id}`, data)
            toast.info("Fechas actualizadas.", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:1500
            });

            this.props.callback({ res, response: response.data });            
        } else {
            this.props.callback({ res });            
        }
        
    }

    render() {

        return (
            <div className={classnames("modal ", {
                "is-active": this.props.isActive,
            })}>
                <div className="modal-background"></div>
                <div className="modal-card">
                <header className="modal-card-head">
                    <p className="modal-card-title">{this.props.task.name}</p>
                    <button className="delete" aria-label="close" onClick={()=>{this.saveChanges(false)}}></button>
                </header>
                <section className="modal-card-body">
                    
                    <p>Por favor ingrese las nuevas fechas para este taller, y después haga clic en el botón guardar cambios, o Cancelar para descartar los cambios</p>
                    <div style={{ height: 10 }}></div>
                    <div className="columns">
                        <div className="column is-12">
                            <div className="field">
                                <label className="label">Fecha de inicio del taller</label>
                                <DatePicker 
                                    className="input"                                     
                                    selected={this.state.startDate}
                                    onChange={(date) => this.setState({startDate: date, endDate: date})}
                                    showTimeSelect
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    locale="es"
                                    minDate={new Date()}
                                />                               
                            </div>                            
                        </div>                        
                    </div>
                    <div className="columns">
                        <div className="column is-12">
                            <div className="field">
                                <label className="label">Fecha de cierre del taller</label>
                                <DatePicker  
                                    popperPlacement="top-start"                                    
                                    className="input"     
                                    selected={this.state.endDate}
                                    onChange={(date) => this.setState({endDate: date})}
                                    showTimeSelect                                    
                                    dateFormat="yyyy-MM-dd HH:mm"
                                    locale="es"
                                    minDate={this.state.startDate}
                                />                                
                            </div>
                        </div>
                    </div>
                    <div style={{ height: 10 }}></div>
                </section>
                <footer className="modal-card-foot">
                    <div style={{ height: 60 }}></div>
                    <button                         
                         className={classnames("button is-primary", {
                            'is-loading': this.state.isSaving
                        })}
                        onClick={()=>{this.saveChanges(true)}}>Guardar cambios</button>
                    <button className="button" onClick={()=>{this.saveChanges(false)}}>Cancelar</button>
                </footer>
                </div>
            </div>
        );
    }
}

export default EditAssignmentModal;
