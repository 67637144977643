import React from "react";
import PropTypes from "prop-types";

const ChatLoader = ({ text, color }) => {
  const textClasses = "text " + color;
  const ellipsisClasses = "lds-ellipsis " + color;

  return (
    <div className="full-screen-loader full-screen-loader--darker">
      <div className="spinner-container">
        <div className="lds-hourglass mb-10"></div>
        <div className={textClasses}>{text}</div>
      </div>
    </div>
  );
};

ChatLoader.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
};

export default ChatLoader;
