import React from 'react';

const NotFound = ({}) => {
    return (
        <div>
            <h1 className="title is-1">Not found</h1>
        </div>
    );
}

export default NotFound;