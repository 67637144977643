import React from "react";
import classnames from "classnames";
import PropTypes from "prop-types";

const Message = ({ message, currentUserId }) => {
  return (
    <li
      className={classnames("", {
        clearfix: message.user.id == currentUserId,
      })}
    >
      <div
        className={classnames("message-data", {
          "align-right": message.user.id == currentUserId,
        })}
      >
        <span className="message-data-name">
          <i
            className={classnames("fa fa-circle", {
              me: message.user.id == currentUserId,
              online: message.user.id != currentUserId,
            })}
          ></i>
          {message.user.name}
        </span>
        <span className="message-data-time">{message.created_at}</span>
      </div>
      <div
        className={classnames("message", {
          "other-message float-right": message.user.id == currentUserId,
          "my-message": message.user.id != currentUserId,
        })}
      >
        {message.content}
      </div>
    </li>
  );
};

Message.propTypes = {
  message: PropTypes.object,
  currentUserId: PropTypes.number,
};

export default Message;
