import { GET_ERRORS, FORUM_REPLIES_HAVE_CHANGED, CLEAR_ERRORS } from "./types";
import axios from "axios";
import { FORUM_REPLY, FORUM_REPLY_REMOVE } from "../helpers/api";

// this action is to create reply to comment
export const createForumCommentReply = (data) => dispatch => {

    dispatch({
        type: CLEAR_ERRORS        
    });

    return axios
        .post(FORUM_REPLY, data)
        .then(response => {
            dispatch({
                type: FORUM_REPLIES_HAVE_CHANGED,
                payload: response.data
            });
            return new Promise((resolve, reject) => {                
                resolve(response);
            });
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });

            return new Promise((resolve, reject) => {                
                reject(err);
            });
        });
};

// this action is to  comments/replies
export const deleteComment = (id) => dispatch => {

    return axios.delete(`${FORUM_REPLY_REMOVE}/${id}`)
        .then(response => {
            dispatch({
                type: FORUM_REPLIES_HAVE_CHANGED,
                payload: response.data
            });
            return new Promise((resolve, reject) => {                
                resolve(response);
            });
        });   
};