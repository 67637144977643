import React, { Component } from "react";
import classnames from "classnames";
import ExamResultDetailTable from '../../Users/Admin/students/ExamResultDetailTable';

class ContentWindow extends Component {

  action(res) {
    this.props.close(res);
  }  

  render() {
    return (
      <div className={classnames("modal ", {
        "is-active": this.props.isActive,
      })}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{ this.props.title }</p>
            <button className="delete" aria-label="close" onClick={()=>{this.action(true)}}></button>
          </header>
          <section className="modal-card-body">
            <ExamResultDetailTable data={this.props.data}></ExamResultDetailTable>               
          </section>
          <footer className="modal-card-foot">            
          </footer>
        </div>
      </div>
    );
  }
}



export default ContentWindow;