import React, { Component } from 'react';
import FullScreenLoader from "../../../Common/FullScreenLoader";
import axios from "axios";
import InputSelectField from "../../../UI/Form/InputSelectField";
import { GET_ASSIGNMENT_BY_LEVEL, GET_ASSIGNMENT_SOLUTIONS, GET_SOLUTION_FILE } from '../../../../helpers/api';
import DataTable from 'react-data-table-component';
import { toast } from 'react-toastify';

class AssignmentsTable extends Component {

    constructor(props) {
        super(props);
        this.state = {               
            levels: this.props.levels,           
            level_id: "-1",
            assignment_id: "-1",
            sendingRequest: false,
            fetchingDataFromApi: false,  
            assignments: [],
            solutions: []
        };
        this.onSelectLevel = this.onSelectLevel.bind(this);       
        this.onSelectSolution = this.onSelectSolution.bind(this);       
    }

    async onSelectLevel(e) {
        this.setState({ 
            [e.target.name]: e.target.value, 
            assignments: [],
            solutions: [],
            assignment_id: "-1" 
        });
        const val = e.target.value;
        if (val == "-1") return;
        try {
            const data = await axios.get(GET_ASSIGNMENT_BY_LEVEL + "/" + val);
            this.setState({ assignments: data.data });
        }catch(e) {

        }
    }

    async onSelectSolution(e) {
        this.setState({ [e.target.name]: e.target.value, solutions: [] });
        const val = e.target.value;
        try {
            const data = await axios.get(GET_ASSIGNMENT_SOLUTIONS + "/" + val);
            this.setState({ solutions: data.data });
        }catch(e) {

        }
    } 

    async downloadSolution(row) {
        this.setState({
            fetchingDataFromApi: true
        });        

        try {

            const response = await axios.get(`${GET_SOLUTION_FILE}/${row.id}/download`, {
                responseType: 'arraybuffer',
                headers: {
                    'Content-Type': 'application/json'
                }});

            const filenameHeader = response.headers['content-disposition']
            const fileName = filenameHeader.replace("filename=", '')
            
            const type = response.headers['content-type']
            const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(blob)
            link.download = fileName
            link.click()  
            
            this.setState({
                fetchingDataFromApi: false
            });

        } catch (ex) {
            
            toast.error("Error al descargar la solución", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000
            }); 
            this.setState({
                fetchingDataFromApi: false
            });
        }
    }

        

    render() {

        const { solutions } = this.state;

        const table = solutions.length ? <DataTable
            columns={[
                { name: 'Número de documento', selector: 'user.identification', sortable: true },
                { name: 'Nombre estudiante', selector: 'user.name', sortable: true },
                { name: 'Fecha de solución', selector: 'created_at', sortable: true },
                { name: 'Descargar solución',
                 selector: 'id',
                 cell: row => <div className="icon-actions">
                     <a style={{ fontSize: '24px' }} title="Descargar solución" onClick={()=> this.downloadSolution(row)}>
                         <i className="fa fa-download"></i>
                     </a>
                 </div>,},
            ]}
            data={solutions}
            pagination={true}
            paginationPerPage={20}
            noHeader={true}

        /> : <div>
            No hay soluciones para este taller
        </div>;

        return (
            <div className={'columns'}>
                <div className={'column is-10 is-offset-1 mt-20'}>
                    <h3 className={'is-main-header'}>
                    <i className="fa fa-book" style={{ marginRight:'5px' }}></i>
                        Soluciones de talleres</h3>
                    { this.state.fetchingDataFromApi ? <FullScreenLoader></FullScreenLoader> : null }
                    <div className="columns">
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Seleccione un curso</label>
                                <InputSelectField
                                    name={'level_id'}
                                    value={this.state.level_id}
                                    onChange={this.onSelectLevel}
                                    placeholder={'Curso'}                                
                                    data={this.state.levels}
                                    isFullWidth={true}
                                    medium={false}
                                />
                            </div>
                        </div>
                        <div className="column is-6">
                            <div className="field">
                                <label className="label">Seleccione un taller</label>
                                <InputSelectField
                                    disabled={this.state.level_id == -1 ? true : false}
                                    name={'assignment_id'}
                                    value={this.state.assignment_id}
                                    onChange={this.onSelectSolution}
                                    placeholder={'Taller'}                                
                                    data={this.state.assignments}
                                    isFullWidth={true}
                                    medium={false}
                                />
                            </div>
                        </div>
                    </div>
                    <div >                    
                        { this.state.assignment_id != "-1" && table }                    
                    </div>
                </div>                
            </div>
        )
    }
}


export default AssignmentsTable;