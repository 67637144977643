import React, { Component } from "react";
import {Link, withRouter} from "react-router-dom";
import classnames from "classnames";

class AdminMenu extends Component {

    constructor() {
        super();
        this.state = {
            activeRoute: null
        };
    }

    componentDidMount() {
        const currentRoute = this.props.history.location.pathname.split("/");
        const activeRoute = currentRoute[currentRoute.length - 1]
        this.setState({
            activeRoute
        })
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.location) {
            const currentRoute = this.props.history.location.pathname.split("/");
            const activeRoute = currentRoute[currentRoute.length - 1]
            this.setState({
                activeRoute
            })
        }
    }

    handleNavigationEvent(route) {
        /*this.setState({
            activeRoute: route
        })*/
        this.props.history.push(`/admin/${route}`);
    }

    render() {
        return (
            <aside className="menu">
                <p className="menu-label">
                    Pruebas
                </p>
                <ul className="menu-list">
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "newexam"
                        })}
                        onClick={()=> {this.handleNavigationEvent('newexam')}}>Crear prueba</a>
                    </li>
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "exams"
                        })}
                        onClick={()=> {this.handleNavigationEvent('exams')}}>Ver pruebas</a>
                    </li>  
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "examresult"
                        })}
                        onClick={()=> {this.handleNavigationEvent('examresult')}}>Resultados</a>
                    </li>   
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "answers"
                        })}
                        onClick={()=> {this.handleNavigationEvent('answers')}}>Ver soluciones</a>
                    </li>
                </ul>
                <p className="menu-label">
                    Cursos
                </p>
                <ul className="menu-list">                    
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "createlevel"
                        })}
                        onClick={()=> {this.handleNavigationEvent('createlevel')}}>Crear Curso</a>
                    </li>
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "level"
                        })}
                        onClick={()=> {this.handleNavigationEvent('level')}}>Ver cursos</a>
                    </li>
                </ul>
                <p className="menu-label">
                    Estudiantes
                </p>
                <ul className="menu-list">
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "register"
                        })}
                        onClick={()=> {this.handleNavigationEvent('register')}}>Registrar estudiante</a>
                    </li>
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "studentlist"
                        })}
                        onClick={()=> {this.handleNavigationEvent('studentlist')}}>Ver estudiantes</a>
                    </li>              
                </ul>
                <p className="menu-label">
                    Foro
                </p>
                <ul className="menu-list">
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "forum"
                        })}
                        onClick={()=> {this.handleNavigationEvent('forum')}}>Nuevo foro</a>
                    </li>
                    <li><a
                        className={classnames("", {
                            "is-active": this.state.activeRoute == "listforum"
                        })}
                        onClick={()=> {this.handleNavigationEvent('listforum')}}>Ver foros</a>
                    </li>                                  
                </ul>
                
            </aside>
        );
    }
}



export default withRouter(AdminMenu);
