import { GET_ERRORS, SET_LEVELS } from "./types";
import axios from "axios";
import { CREATE_LEVELS, GET_LEVELS, DELETE_LEVEL } from "../helpers/api";

// create level
export const createLevel = (levelData) => (dispatch) => {
  return axios
    .post(CREATE_LEVELS, levelData)
    .then((response) => {
      return new Promise((resolve, reject) => {
        dispatch(fetchLevels());
        resolve(response);
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// fetch levels
export const fetchLevels = () => (dispatch) => {
  return axios
    .get(GET_LEVELS)
    .then((response) => {
      dispatch({
        type: SET_LEVELS,
        payload: response.data,
      });
      dispatch({
        type: GET_ERRORS,
        payload: {},
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};

// delete level
export const deleteLevel = (levelId) => (dispatch) => {
  return axios
    .delete(DELETE_LEVEL + "/" + levelId)
    .then((response) => {
      return new Promise((res, rej) => {
        dispatch({
          type: SET_LEVELS,
          payload: response.data,
        });
        if (response.status == 200) {
          res(response.status);
        }
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      });
    });
};
