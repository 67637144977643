import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { GET_MY_RESULTS } from "../../../../helpers/api";
import CountUp from "react-countup";
import Loader from "../../../Common/Loader";
import svg from "../../../../assets/result.svg";
import EmptyResults from "../layout/EmptyResults";
import TitleHeader from "../layout/TitleHeader";
import { gradeExam } from "../../../../helpers/examresult";

class StudentResults extends Component {
  constructor() {
    super();
    this.state = {
      results: [],
      numExams: 0,
      numIcfes: 0,
    };
  }

  async componentDidMount() {
    this.setState({ fetchingData: true });
    try {
      const res = await axios.get(GET_MY_RESULTS);
      this.calculateExamsNumber(res.data);
    } catch (e) {
      this.setState({ fetchingData: false });
    }
  }

  calculateResult(result) {
    return gradeExam(result);
  }

  calculateExamsNumber(data) {
    let exams = 0,
      icfes = 0;
    data.forEach((result) => {
      if (result.type_exam == "Exam") {
        exams++;
      }

      if (result.type_exam == "Icfes") {
        icfes++;
      }
    });

    this.setState({
      fetchingData: false,
      results: data,
      numExams: exams,
      numIcfes: icfes,
    });
  }

  render() {
    const { numExams, numIcfes } = this.state;

    return (
      <div>
        <TitleHeader text="Resultados" image={svg}></TitleHeader>

        {this.state.fetchingData ? (
          <div className="columns is-centered">
            <div className="column is-5">
              <div className="ph-item">
                <div className="ph-col-12">
                  <div className="ph-picture"></div>
                </div>
                <div className="ph-col-12">
                  <div className="ph-picture"></div>
                </div>
              </div>
            </div>
            <div className="column is-5">
              <div className="ph-item">
                <div className="ph-col-12">
                  <div className="ph-picture"></div>
                </div>
                <div className="ph-col-12">
                  <div className="ph-picture"></div>
                </div>
              </div>
            </div>
          </div>
        ) : this.state.results.length == 0 ? (
          <div className="student-results-container columns">
            <EmptyResults text="No hay resultados"></EmptyResults>
          </div>
        ) : (
          <div className="student-results-container columns">
            <div className="column is-6">
              <div className="mt-20">
                <h5 className="title is-5">
                  Resultados simulacros ({numIcfes})
                </h5>
                {numIcfes > 0 ? (
                  <ul>
                    {this.state.results.map((result) => {
                      if (result.type_exam == "Icfes") {
                        return (
                          <li key={result.id}>
                            <span className="span-container">
                              <span className="span-icon fa fa-graduation-cap"></span>
                              <span className="span-lbl">{result.name}</span>
                            </span>
                            <span className="span-result">
                              Puntaje: &nbsp;
                              <CountUp
                                end={0 || this.calculateResult(result)}
                              />
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                ) : (
                  <p style={{ color: "#ccc" }}>
                    No hay resultados para mostrar
                  </p>
                )}
              </div>
            </div>
            <div className="column is-6">
              <div className="mt-20">
                <h5 className="title is-5">
                  Resultados evaluaciones ({numExams})
                </h5>
                {numExams ? (
                  <ul>
                    {this.state.results.map((result) => {
                      if (result.type_exam == "Exam") {
                        return (
                          <li key={result.id}>
                            <span className="span-container">
                              <span className="span-icon fa fa-graduation-cap"></span>
                              <span className="span-lbl">{result.name}</span>
                            </span>
                            <span className="span-result">
                              Nota:{" "}
                              <CountUp
                                decimals={1}
                                end={0 || this.calculateResult(result)}
                              />
                            </span>
                          </li>
                        );
                      }
                    })}
                  </ul>
                ) : (
                  <p style={{ color: "#ccc" }}>
                    No hay resultados para mostrar
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(StudentResults);
