import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import axios from "axios";
import { GET_FORUMS } from "../../../../helpers/api";
import moment from "moment";
import "moment/locale/es";
import Loader from "../../../Common/Loader";
import svg from "../../../../assets/talk.svg";
import EmptyResults from "../layout/EmptyResults";
import TitleHeader from "../layout/TitleHeader";
import LazyLoader from "../../Shared/Lessons/Components/LazyLoader";

class ForumList extends Component {
  constructor() {
    super();
    this.state = {
      forums: [],
      fetchingData: true,
      pageCount: 0,
      perPage: 5,
      offset: 0,
      filteredForums: [],
    };
    moment().locale("es");
  }

  async componentDidMount() {
    try {
      const res = await axios.get(GET_FORUMS + "/student");
      let { forums } = res.data;
      const now = moment(res.data.now);
      for (let forum of forums) {
        const endDate = moment(forum.end_date);
        forum["difference"] = endDate.diff(now);
        forum["humanize"] = moment.duration(endDate.diff(now)).humanize();
      }

      const perPage = 5;
      const pageCount = Math.ceil(forums.length / perPage);

      this.setState(
        {
          forums: forums,
          pageCount: pageCount,
          perPage: perPage,
          fetchingData: false,
        },
        () => this.loadData()
      );
    } catch (e) {
      this.setState({
        fetchingData: false,
      });
    }
  }

  loadData() {
    const { perPage, offset, forums } = this.state;
    const filteredForums = [];
    let maxCount = perPage + offset;
    if (maxCount > forums.length) {
      maxCount = forums.length;
    }
    for (let i = offset; i < maxCount; i++) {
      filteredForums.push(forums[i]);
    }

    this.setState({
      filteredForums: filteredForums,
    });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perPage);

    this.setState({ offset: offset }, () => {
      this.loadData();
    });
  };

  render() {
    const { forums, fetchingData, filteredForums, pageCount } = this.state;

    return (
      <div>
        <TitleHeader text="Foro" image={svg}></TitleHeader>

        <div className="columns">
          <div className="column is-8 is-offset-2">
            {fetchingData ? (
              <LazyLoader></LazyLoader>
            ) : (
              <div>
                {forums.length > 0 ? (
                  <React.Fragment>
                    <ul className="forum-list">
                      {filteredForums.map((forum) => {
                        return (
                          <li key={forum.id}>
                            <div className="forum-list__content">
                              <div>
                                <i className="fa fa-comments"></i>
                              </div>
                              <div className="forum-list__meta">
                                <div>{forum.title}</div>
                                <div>
                                  {forum.difference <= 0
                                    ? "Foro cerrado"
                                    : `Foro cierra en ${forum.humanize}`}
                                </div>
                                <div>
                                  Creado por: <span>{forum.user.name}</span>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Link
                                className="forum-list__button-go"
                                to={"/student/discussion/" + forum.slug}
                              >
                                Ver foro
                              </Link>
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                    {pageCount > 1 && (
                      <nav
                        className="pagination mt-20 mb-20"
                        role="navigation"
                        aria-label="pagination"
                      >
                        <ReactPaginate
                          previousLabel={"Anterior"}
                          nextLabel={"Siguiente"}
                          breakLabel={"..."}
                          breakClassName={"pagination-link"}
                          pageCount={this.state.pageCount}
                          onPageChange={this.handlePageClick}
                          containerClassName={"pagination-list"}
                          pageLinkClassName={"pagination-link"}
                          activeLinkClassName={"is-current"}
                          previousClassName={"button"}
                          nextClassName={"button"}
                        />
                      </nav>
                    )}
                  </React.Fragment>
                ) : (
                  <EmptyResults text="No hay foros disponibles todavía"></EmptyResults>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(withRouter(ForumList));
