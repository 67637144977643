import React, { Component } from "react";
import axios from "axios";
import InputSelectField from "../../../UI/Form/InputSelectField";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import { EXAM_RESULTS, GET_SERIALIZED_EXAMS_BY_ID } from "../../../../helpers/api";
import { HorizontalBar } from 'react-chartjs-2';
import domtoimage from 'dom-to-image';
import Reports from "../../../../reports/results";
import { toast } from "react-toastify";
import moment from 'moment';


class ExamResults extends Component {

    constructor(props) {
        super(props);
        this.state = {           
            level_id: "-1",
            exam_id: "-1",
            errors: {},
            levels: this.props.levels,
            exams: [],
            fetchDataForGraphs: false,
            sendingRequest: false,
            fetchingDataFromApi: false,
            barGraphData: {
                labels: [],
                data: []
            },
            metadata: [],            
        };
        this.onSelectExam = this.onSelectExam.bind(this); 
        this.onSelectLevel = this.onSelectLevel.bind(this); 
        this.isChart1Rendered = false;
        this.isChart2Rendered = false;

        this.screenResolution = window.innerWidth;

    }

    async onSelectLevel(e) {
        this.setState({ [e.target.name]: e.target.value, exams: [], exam_id: "-1" });
        const val = e.target.value;
        if (val == "-1") return;
        try {
            const data = await axios.get(GET_SERIALIZED_EXAMS_BY_ID + "/" + val);
            this.setState({ exams: data.data });
        }catch(e) {

        }
    }

    async onSelectExam(e) {
        this.setState({ [e.target.name]: e.target.value });
        this.isChart1Rendered = false;
        this.isChart2Rendered = false;
        const val = e.target.value;

        if (val == "-1") return;

        this.setState({
            fetchingDataFromApi: true
        })

        try {
            const results = await axios.get(EXAM_RESULTS + "/" + val );            
            this.setState({
                barGraphData: {
                    labels: results.data.labels,
                    data: results.data.data                    
                },
                metadata: results.data.metadata,
                fetchDataForGraphs: true,
                fetchingDataFromApi: false
            })            
        } catch(e) {
            console.log(e)
            this.setState({               
                fetchingDataFromApi: false
            })
        }
    }   

    calculateDataForGraphSingleQuestion(metadata) {
        try {
            const jsonObjs = [];
            for (let i = 0; i < metadata.length; i++) {
                jsonObjs.push(JSON.parse(metadata[i]['exam_metadata']));
            }
    
            const questionsData = [];
            const questionNumberLbl = [];
    
            for (let i = 0; i < jsonObjs[0].length; i++) {
                questionNumberLbl.push("Pregunta " + (i+1));
                let counter = 0;
                for (let k = 0; k < jsonObjs.length; k++) {
                    if (jsonObjs[k][i].question.answer == jsonObjs[k][i].question.studentAnswer) {
                        counter++;
                    }
                }
                questionsData.push(Math.floor((counter/jsonObjs.length)*100));
            }         
    
            return { labels: questionNumberLbl, data: questionsData };
        } catch(e) {
            return { labels: [], data: [] };
        }
        
    }

    calculateGraphHeight(data) {
        const numberOfRecords = data.length > 0 ? data.length : 0;
        const singleRecordHeight = 20;
        return 80 + (numberOfRecords * singleRecordHeight);
    }

    calculateGraphHeightForPDF(records=24) {        
        const singleRecordHeight = 4.4;
        return 20 + (records * singleRecordHeight);
    }

    calculateTableHeightForPDF(records=24) {        
        const singleRecordHeight = 6.2;
        return 20 + (records * singleRecordHeight);
    }

    saveToPDF = async() => {      

        if(!this.isChart1Rendered && !this.isChart2Rendered) {
            toast.info("Espere a que cargen todos los datos e intente de nuevo", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:1500
            });
            return
        }
        this.setState({fetchingDataFromApi: true});      
        
        const image1 = await domtoimage.toPng(document.getElementById('chart-container'));
        const graph1Height = this.calculateGraphHeightForPDF(this.state.barGraphData.data.length);
        const image2 = await domtoimage.toPng(document.getElementById('chart-container-2'));
        const graph2Height = this.calculateGraphHeightForPDF(); 
        const table = await domtoimage.toPng(document.getElementById('results-table'));        
        const tableHeight = this.calculateTableHeightForPDF(this.state.barGraphData.data.length); 

        try {
            const pdf = await Reports.makeResultsPDF(image1, image2, graph1Height, graph2Height, table, tableHeight);                  
            pdf.save("reporte-" + Date.now().toString());
            this.setState({fetchingDataFromApi: false})
        } catch(err) {
            console.error(err);
            this.setState({fetchingDataFromApi: false})
        }
    }

    calculateStudentTimeForResult = result => {
        const createdAt = moment(result.created_at);
        const updated_at = moment(result.updated_at);        

        const seconds = moment.duration(updated_at.diff(createdAt)).seconds();
        const minutes = moment.duration(updated_at.diff(createdAt)).minutes();        

        if (minutes <= 0) { 
            return { time: `${seconds} segundos`, color: '#FF0606' }
        };        

        if (minutes >= 1 && minutes < 20) {
            return minutes == 1  ?
             { time: `${minutes} minuto`, color: '#FF0606' }  :
             { time: `${minutes} minutos`, color: '#FF7F00' };
        }

        if (minutes >= 20 && minutes < 40) {
            return { time: `${minutes} minutos`, color: '#0269E9' };
        }

        return { time: `${minutes} minutos`, color: '#00B62B' };
        
    }

    render() {

        const { errors } = this.state;

        const barGraphData  = {            
            labels: this.state.barGraphData.labels,
            datasets: [
              {                
                label: 'Puntaje',
                backgroundColor: 'rgba(0,106,177, .4)',
                borderColor: 'rgba(0,106,177,1)',
                borderWidth: 1,
                hoverBackgroundColor: 'rgba(0,106,177,0.8)',
                hoverBorderColor: 'rgba(0,106,177,1)',
                data: this.state.barGraphData.data
              }
            ]
          };


        const singleQuestionGraphData = this.state.metadata.length > 0 
            ? this.calculateDataForGraphSingleQuestion(this.state.metadata)
            : { labels: [], data: [] };           

        
        const barQuestionGraphData  = {            
            labels: singleQuestionGraphData.labels,
            datasets: [
                    {                
                    label: 'Porcentaje de estudiantes que respondieron correctamente',
                    backgroundColor: 'rgba(0,106,177, .4)',
                    borderColor: 'rgba(0,106,177,1)',
                    borderWidth: 1,
                    hoverBackgroundColor: 'rgba(0,106,177,0.8)',
                    hoverBorderColor: 'rgba(0,106,177,1)',
                    data: singleQuestionGraphData.data
                    }
                ]
            };
            
      
        return (
            <div>
                { this.state.fetchingDataFromApi ? <FullScreenLoader></FullScreenLoader> : null }
                <div className={'columns'}>
                    <div className={'column is-10 is-offset-1 mt-20'}>
                        
                        <h3 className={'is-main-header'}>
                        <i className="fa fa-trophy"></i>&nbsp;Resultados</h3>

                        <div className="columns">
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Curso</label>
                                    <InputSelectField
                                        name={'level_id'}
                                        value={this.state.level_id}
                                        onChange={this.onSelectLevel}
                                        placeholder={'Curso'}
                                        error={errors.level_id}
                                        data={this.state.levels}
                                        isFullWidth={true}
                                        medium={false}
                                    />
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Prueba</label>
                                    <InputSelectField
                                        disabled={this.state.level_id == -1 ? true : false}
                                        name={'exam_id'}
                                        value={this.state.exam_id}
                                        onChange={this.onSelectExam}
                                        placeholder={'Prueba'}
                                        error={errors.exam_id}
                                        data={this.state.exams}
                                        isFullWidth={true}
                                        medium={false}
                                    />
                                </div>
                            </div>
                        </div>    

                        {
                            !this.state.fetchDataForGraphs 
                            ? (<div>Seleccione un curso y un examen por favor</div>)
                            : (null)
                        }

                        {
                            this.state.barGraphData.data.length == 0 & this.state.fetchDataForGraphs
                            ? (<div>No se encontraron resultados en el examen seleccionado</div>)
                            : (<div>
                                { this.state.barGraphData.data.length > 0 ?  
                                    (<div className="mt-20">
                                        <h5 className="title is-5">Resultados individuales por estudiante - ({ this.state.barGraphData.labels.length }) resultados</h5>
                                        <div
                                            id="chart-container"
                                            style={{
                                                background: "white",
                                                width: '100%', 
                                                height: this.calculateGraphHeight(this.state.barGraphData.data).toString() + "px", 
                                            }}
                                            >
                                                <HorizontalBar                                                    
                                                    data={barGraphData}
                                                    options={{                                                
                                                        maintainAspectRatio: false,
                                                        animation: {
                                                            onComplete: () => {
                                                                this.isChart1Rendered = true
                                                            }
                                                         }
                                                    }}                                                         
                                                /> 
                                        </div>
                                             
                                    </div>)
                                    : null
                                }

                                { singleQuestionGraphData.data.length > 0 ?  
                                    (<div className="mt-40 mb-20">
                                        <h5 className="title is-5">Resultados individuales por pregunta</h5>
                                        <div id="chart-container-2"
                                            style={{
                                                width: '100%', 
                                                height: this.calculateGraphHeight(singleQuestionGraphData.data).toString() + "px",
                                            }}>
                                            <HorizontalBar
                                                data={barQuestionGraphData}                                           
                                                options={{                                                
                                                    maintainAspectRatio: false,
                                                    animation: {
                                                        onComplete: () => {
                                                            this.isChart2Rendered = true
                                                        }
                                                     }
                                                }}                                                       
                                            /> 
                                        </div>     
                                    </div>)
                                    : null
                                }
                            </div>)
                        }

                        {
                            this.state.metadata.length > 0 && (<div>
                            <h5 className="title is-5 mt-20">Tabla de resultados - ({ this.state.barGraphData.labels.length }) resultados</h5>
                            <div id="results-table">
                                <table className="table is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th>Identificación</th>
                                            <th>Nombre</th>
                                            <th>Tiempo del estudiante</th>
                                            <th>Puntaje</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.state.metadata.map(d => {
                                            const { time, color } = this.calculateStudentTimeForResult(d);
                                            return (
                                                <tr key={d.identification}>
                                                    <td>{ d.identification }</td>
                                                    <td>{ d.name }</td>
                                                    <td><b style={{ color }}>{ time }</b></td>
                                                    <td>{ d.grade }</td>
                                                </tr>
                                            )
                                        }) }                                    
                                    </tbody>
                                </table>                            
                            </div>    
                        </div>)                            
                        }

                        {
                            (this.screenResolution >= 1000 && this.state.metadata.length > 0) ? 
                            <span 
                            title="Generar archivo pdf"
                            style={{
                                fontSize: '40px',
                                marginTop: '30px',
                                color: '#006ab1',
                                marginBottom: '40px',
                                cursor: 'pointer'
                            }}
                            className="pull-right fa fa-file-pdf-o" onClick={this.saveToPDF}></span> : null
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default ExamResults;
