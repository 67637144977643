import React, { Component } from "react";
import classnames from "classnames";
import { MorphIcon } from "react-svg-buttons";

export default class VoiceMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      type: "inbox",
    };
  }

  playMessage = () => {
    let { play, type } = this.state;
    if (play) {
      return;
    }

    this.setState({ type: "check" });

    setTimeout(() => {
      this.setState({ play: true });
    }, 1000);
  };

  render() {
    const { message, currentUserId, lessonId } = this.props;
    const { play, type } = this.state;
    const url =
      "https://purocodigo.net/public/apps/cdn/icfes/lessons/voice/" +
      lessonId +
      "/" +
      message.content;

    return (
      <li
        className={classnames("", {
          clearfix: message.user.id == currentUserId,
        })}
      >
        <div
          className={classnames("message-data", {
            "align-right": message.user.id == currentUserId,
          })}
        >
          <span className="message-data-name">
            <i
              className={classnames("fa fa-circle", {
                me: message.user.id == currentUserId,
                online: message.user.id != currentUserId,
              })}
            ></i>
            {message.user.name}
          </span>
          <span className="message-data-time">{message.created_at}</span>
        </div>
        <div
          className={classnames("message", {
            "other-message float-right": message.user.id == currentUserId,
            "my-message": message.user.id != currentUserId,
          })}
        >
          <div>
            {play ? (
              <audio controls>
                <source
                  src={url}
                  type="audio/mpeg"
                  controls
                  controlsList="nodownload"
                ></source>
              </audio>
            ) : (
              <span className="download-audio-message">
                <span
                  onClick={this.playMessage}
                  className="download-audio-message__button"
                >
                  <MorphIcon type={type} thickness={2} color="#fff" />
                </span>{" "}
                Mensaje de audio
              </span>
            )}
          </div>
        </div>
      </li>
    );
  }
}
