import React, { Component } from "react";
import NewVoiceMessage from "./NewVoiceMessage";

export default class ChatForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: "",
    };
  }

  handleChange = (e) => {
    if (e.target.value.length > 500) {
      return;
    }
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  checkForEnterKey = (e) => {
    if (e.keyCode === 13) {
      this.sendMessage();
    }
  };

  sendMessage() {
    const { message } = this.state;

    if (!message || message.trim().length === 0) {
      this.setState({ message: "" });
      return;
    }

    this.setState({ message: "" });

    this.props.messageEvent(message);
  }

  render() {
    const { message } = this.state;
    const { lessonId, currentUser } = this.props;

    return (
      <div className="chat-message clearfix">
        <div className="chat-message-controls">
          {parseInt(currentUser.role) === 2 && (
            <NewVoiceMessage lessonId={lessonId}></NewVoiceMessage>
          )}
          <textarea
            name="message"
            onChange={this.handleChange}
            onKeyUp={this.checkForEnterKey}
            placeholder="Escribe el mensaje y presiona enter para enviarlo"
            rows="3"
            value={message}
          ></textarea>
        </div>

        <button onClick={() => this.sendMessage()}>Participar</button>
      </div>
    );
  }
}
