import React from 'react'
import jwt_decode from "jwt-decode";

export default function(ComposedComponent) {

    class RequireAuthRole extends React.Component {

        // Redirect to correct route if user tries to access
        // a route with a different role
        componentWillMount() {
            const token = localStorage.jwtToken
            if (!token) {
                return this.props.history.push('/')
            }
            const decoded = jwt_decode(localStorage.jwtToken);
            const url = this.splitUrl(this.props.history.location.pathname)

            if (url[0] == "admin" && decoded.role == 1) {
                return this.props.history.push('/student')
            }

            if (url[0] == "student" && decoded.role == 2) {
                return this.props.history.push('/admin')
            }
        }

        splitUrl(url) {
            try {
                const u = url.trim().toLowerCase()
                let arr = u.split('/');
                arr = arr.filter(p => p !== "")
                return arr
            } catch (e) {
                return this.props.history.push('/')
            }
        }

        // Otherwise render the original component
        render() {
            return <ComposedComponent {...this.props}/>
        }

    }

    return RequireAuthRole

}
