import React, { Component } from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "@ckeditor/ckeditor5-build-classic/build/translations/es.js";
import InputSelectField from "../../../UI/Form/InputSelectField";
import InputTextField from "../../../UI/Form/InputTextField";
import classnames from "classnames";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import axios from "axios";
import { NEW_FORUM, GET_FORUM, EDIT_FORUM } from "../../../../helpers/api";
import { withRouter } from "react-router-dom";
registerLocale("es", es);

class NewForum extends Component {
  constructor(props) {
    super(props);

    this.state = {
      levels: this.props.levels,
      exams: [],
      title: "",
      content: "",
      errors: {},
      level_id: "-1",
      preview: false,
      startDate: new Date(),
      endDate: new Date(),
      isSavingForum: false,
      isEditing: false,
      forumId: "",
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onTextEditorChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ content: data });
  };

  async componentDidMount() {
    if (this.props.match.params.levelid) {
      this.setState({ isEditing: true });

      const { levelid, id } = this.props.match.params;

      try {
        const res = await axios.get(GET_FORUM + "/" + levelid + "/" + id);

        this.setState({
          content: res.data.content,
          level_id: res.data.level_id.toString(),
          title: res.data.title,
          startDate: Date.parse(res.data.start_date),
          endDate: Date.parse(res.data.end_date),
          forumId: res.data.id,
        });
      } catch (e) {
        this.props.history.push("/");
      }
    }
  }

  previewForum = () => {
    this.setState({
      preview: true,
    });
  };

  editForum = () => {
    this.setState({
      preview: false,
    });
  };

  renderHtml() {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: this.state.content,
        }}
      ></div>
    );
  }

  saveForum = async () => {
    this.setState({
      errors: {},
      isSavingForum: true,
    });
    const data = {
      level_id: this.state.level_id == -1 ? "" : this.state.level_id,
      title: this.state.title,
      start_date: Date.parse(this.state.startDate),
      end_date: Date.parse(this.state.endDate),
      content: this.state.content,
    };

    try {
      const res = await axios.post(NEW_FORUM, data);
      const now = new Date();
      this.setState({
        level_id: "-1",
        title: "",
        startDate: now,
        endDate: now,
        content: "",
        isSavingForum: false,
      });
      toast.info("Foro creado correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (e) {
      this.setState({
        errors: e.response.data,
        isSavingForum: false,
      });
    }
  };

  editForum = async () => {
    this.setState({
      errors: {},
      isSavingForum: true,
    });
    const data = {
      level_id: this.state.level_id == -1 ? "" : this.state.level_id,
      title: this.state.title,
      start_date: Date.parse(this.state.startDate) || this.state.startDate,
      end_date: Date.parse(this.state.endDate) || this.state.endDate,
      content: this.state.content,
      id: this.state.forumId,
    };

    try {
      const res = await axios.put(EDIT_FORUM, data);
      toast.info("Cambios guardados correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 2000,
      });
      this.props.history.push("/admin/listforum");
    } catch (e) {
      this.setState({
        errors: e.response.data,
        isSavingForum: false,
      });
    }
  };

  render() {
    const { errors, preview, title, isSavingForum, isEditing } = this.state;

    return (
      <div className="columns">
        <div className="column is-10 is-offset-1">
          {!preview ? (
            <div>
              <h3 className={"is-main-header"}>
                <i className="fa fa-comments-o"></i>&nbsp;
                {isEditing ? "Editar foro" : "Crear foro"}
              </h3>

              <div className="columns">
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Seleccione curso</label>
                    <InputSelectField
                      name={"level_id"}
                      value={this.state.level_id}
                      onChange={this.onChange}
                      placeholder={"Escoja un curso"}
                      error={errors.level_id}
                      data={this.state.levels}
                      isFullWidth={true}
                      medium={false}
                      disabled={isEditing}
                    />
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Titulo del foro</label>
                    <InputTextField
                      name={"title"}
                      value={this.state.title}
                      onChange={this.onChange}
                      placeholder={"e.g. Importancia de las Matemáticas"}
                      error={errors.title}
                    />
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Fecha de inicio del foro</label>
                    <DatePicker
                      className={classnames("input", {
                        "is-danger": errors.start_date,
                      })}
                      selected={this.state.startDate}
                      onChange={(date) =>
                        this.setState({ startDate: date, endDate: date })
                      }
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm"
                      locale="es"
                      minDate={new Date()}
                    />
                    {errors.start_date ? (
                      <p className="help is-danger">{errors.start_date}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Fecha de cierre del foro</label>
                    <DatePicker
                      className={classnames("input", {
                        "is-danger": errors.end_date,
                      })}
                      selected={this.state.endDate}
                      onChange={(date) => this.setState({ endDate: date })}
                      showTimeSelect
                      dateFormat="yyyy-MM-dd HH:mm"
                      locale="es"
                      minDate={this.state.startDate}
                    />
                    {errors.end_date ? (
                      <p className="help is-danger">{errors.end_date}</p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>

              <div className="editor-area">
                <CKEditor
                  editor={ClassicEditor}
                  data={this.state.content}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "link",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "undo",
                      "redo",
                    ],
                    language: "es",
                  }}
                  //onInit={ editor => console.log(editor) }
                  onChange={this.onTextEditorChange}
                />
                {errors.content ? (
                  <p className="help is-danger">{errors.content}</p>
                ) : (
                  ""
                )}
              </div>

              <button
                className="button is-primary mt-10 pull-right"
                onClick={this.previewForum}
              >
                Previsualizar
              </button>
              {isEditing ? (
                <button
                  className={classnames("button is-primary mt-10 pull-left", {
                    "is-loading": isSavingForum,
                  })}
                  onClick={this.editForum}
                >
                  Guardar cambios
                </button>
              ) : (
                <button
                  className={classnames("button is-primary mt-10 pull-left", {
                    "is-loading": isSavingForum,
                  })}
                  onClick={this.saveForum}
                >
                  Guardar
                </button>
              )}
            </div>
          ) : (
            <div>
              <div>
                <div className="forum-content-component">
                  <div className="forum-content-component__title">{title}</div>
                  <hr></hr>
                  <div>{this.renderHtml()}</div>
                </div>
              </div>
              <button
                className="button is-primary mt-10"
                onClick={() => this.setState({ preview: false })}
              >
                Volver
              </button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(NewForum);
