import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import InputTextField from "../UI/Form/InputTextField";
import { loginUser } from "../../actions/authActions";
import classnames from "classnames";
import logo from "../../assets/logowhite.png";
import { toast } from "react-toastify";

class Login extends Component {
  _isMounted = false;

  constructor() {
    super();
    this.state = {
      identification: "",
      password: "",
      errors: {},
      sendingRequest: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;

    if (this.props.auth.isAuthenticated) {
      if (this.props.auth.user.role == 1) {
        this.props.history.push("student");
      } else if (this.props.auth.user.role == 2) {
        this.props.history.push("admin");
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this._isMounted) {
      if (nextProps.errors) {
        this.setState({
          errors: nextProps.errors,
        });
      }
      if (nextProps.auth.isAuthenticated) {
        this.promiseRedirect().then(() => {
          if (nextProps.auth.user.role == 1) {
            this.props.history.push("student");
          } else if (nextProps.auth.user.role == 2) {
            this.props.history.push("admin");
          }
        });
      }
    }
  }

  promiseRedirect() {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res();
      }, 1);
    });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      sendingRequest: true,
    });

    const userData = {
      identification: this.state.identification,
      password: this.state.password,
    };

    //

    this.props.loginUser(userData, this.props.history).then((_) => {
        this.setState({
            sendingRequest: false,
        });
    }).catch(error => {
        this.setState({
            sendingRequest: false,
        });
        if (!error.response) {           
            toast.error("Error en la conexión. Codigo: " + error.message, {
              position: toast.POSITION.BOTTOM_CENTER,
              autoClose: 3000,
            });
        } 
    })
  }

  render() {
    const { errors, sendingRequest } = this.state;

    return (
      <div className="login-container">
        <div className="login-container__form">
          <div className="login-container__form__image">
            <img src={logo}></img>
          </div>

          <form noValidate onSubmit={this.onSubmit}>
            <div className="field">
              <label className="label">Numero de documento</label>
              <InputTextField
                isErrorColorLight={true}
                hasRedBorderError={false}
                icon="fa fa-user"
                name={"identification"}
                value={this.state.identification}
                type={"text"}
                onChange={this.onChange}
                placeholder={"e.g. 1059948554"}
                error={errors.identification}
                isMedium={true}
              />
            </div>

            <div className="field">
              <label className="label">Contraseña</label>
              <InputTextField
                isErrorColorLight={true}
                hasRedBorderError={false}
                icon="fa fa-lock"
                name={"password"}
                value={this.state.password}
                type={"password"}
                onChange={this.onChange}
                placeholder={"Contraseña"}
                error={errors.password}
                isMedium={true}
              />
            </div>

            <div className="control">
              <button
                className={classnames(
                  "button login-container__form__button is-light",
                  {
                    "is-loading": sendingRequest,
                  }
                )}
              >
                Ingresar
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(withRouter(Login));