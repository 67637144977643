const API_URL = "https://www.icfesapp.purocodigo.net";
//const API_URL = "https://www.app.educate.net.co";
//const API_URL = "http://project3.local";

//API_URLS
export const GET_LEVELS = API_URL + "/api/levels";
export const CREATE_LEVELS = API_URL + "/api/levels";
export const DELETE_LEVEL = API_URL + "/api/levels";
export const EXAM_TEST_RESULT = API_URL + "/api/exams/taken";
export const GET_EXAM_QUESTIONS = API_URL + "/api/levels/exams";
export const GET_EXAM_QUESTIONS_FOR_EXAM =
  API_URL + "/api/levels/examsquestions";
export const GET_EXAM_QUESTIONS_BY_EXAM_LEVEL =
  API_URL + "/api/levels/questionsbyexamlevel";
export const GET_MY_RESULTS = API_URL + "/api/students/myresults";
export const CREATE_OR_CHECK_STUDENT_EXAM_ROW = API_URL + "/api/begin/exam";
export const STUDENTS_BY_LEVEL = API_URL + "/api/levels/students";
export const LOGIN = API_URL + "/signin";
export const REGISTER_STUDENT = API_URL + "/api/register";
export const GET_EXAMS = API_URL + "/api/levels/exams";
export const GET_NOT_TAKEN_EXAMS = API_URL + "/api/levels/exams/notaken";
export const CREATE_EXAM = API_URL + "/api/levels/exams";
export const GET_AVAILABLE_EXAMS = API_URL + "/api/students/exam";
export const GET_EXAMS_BY_ID = API_URL + "/api/exams";
export const GET_SERIALIZED_EXAMS_BY_ID = API_URL + "/api/exams/serialized";
export const EXAMS_TOGGLE_ACTIVE = API_URL + "/api/exams/toggle";
export const DELETE_EXAM = API_URL + "/api/exams";
export const EXAM_RESULTS = API_URL + "/api/exams/taken/results";
export const STUDENT_RESULTS = API_URL + "/api/students/results";
export const DELETE_STUDENT = API_URL + "/api/students";
export const DASHBOARD_STATS = API_URL + "/api/dashboard/stats";
export const CHANGE_PW = API_URL + "/api/students/changepw";
export const RESTORE_PW = API_URL + "/api/restorepw";
export const NEW_FORUM = API_URL + "/api/forum/create";
export const EDIT_FORUM = API_URL + "/api/forum/edit";
export const GET_FORUMS = API_URL + "/api/forum";
export const GET_FORUM = API_URL + "/api/forum/replies";
export const FORUM_REPLY = API_URL + "/api/forum/reply";
export const FORUM_REMOVE = API_URL + "/api/forum/remove";
export const FORUM_REPLY_REMOVE = API_URL + "/api/forum/reply";
export const FORUM_REPLY_UPDATE = API_URL + "/api/forum/reply";
export const ASSIGNMENT_SEND_SOLUTION = API_URL + "/api/assignments/solution";
export const GET_ASSIGNMENTS_LIST = API_URL + "/api/assignments";
export const GET_ASSIGNMENT = API_URL + "/api/assignment";
export const GET_ASSIGNMENT_BY_LEVEL = API_URL + "/api/levelassignment";
export const GET_ASSIGNMENT_SOLUTIONS = API_URL + "/api/assignmentsolutions";
export const GET_SOLUTION_FILE = API_URL + "/api/solution"; //{id}/download
export const DELETE_SOLUTION = API_URL + "/api/solution";
export const USER_PROFILE = API_URL + "/api/user/profile";
export const CREATE_ASSIGNMENT = API_URL + "/api/createassignment";
export const UPDATE_ASSIGNMENT = API_URL + "/api/updateassignment";
export const DELETE_ASSIGNMENT = API_URL + "/api/deleteassignment";
export const RESET_STUDENT_EXAM = API_URL + "/api/exams/taken/reset";

//real time
export const PUSHER_AUTH = API_URL + "/api/pusher/auth";
export const CONNECT_TO_LESSON = API_URL + "/api/lesson/connect";
export const LESSON_NEW_MESSAGE = API_URL + "/api/lesson/message";
export const LESSON_NEW_VOICE_MESSAGE = API_URL + "/api/lesson/voicemessage";

//lesson
export const NEW_LESSON = API_URL + "/api/lesson/create";
export const GET_LESSON_ADMIN = API_URL + "/api/lesson/admin";
export const EDIT_LESSON = API_URL + "/api/lesson/edit";
export const GET_LESSON_PANEL = API_URL + "/api/lesson/lessonspanel";
export const DELETE_LESSON = API_URL + "/api/lesson";
export const GET_LESSONS_STUDENT = API_URL + "/api/lesson/students";
export const TEACHER_FINISHED_LESSON = API_URL + "/api/lesson/finished";

//attendances
export const GET_ATTENDANCES = API_URL + "/api/attendance";

//reports
export const REPORT_ATTENDANCE = API_URL + "/api/reports/attendance";
export const REPORT_ATTENDANCE_DOCUMENT =
  API_URL + "/api/reports/attendance/document";
export const REPORT_ATTENDANCE_CLEAN_RESOURCES =
  API_URL + "/api/reports/attendance";

export const LESSON_REPORT_ATTENDANCE =
  API_URL + "/api/reports/lesson/attendance";
export const LESSON_REPORT_ATTENDANCE_DOCUMENT =
  API_URL + "/api/reports/lesson/attendance/document";
