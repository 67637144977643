import React, { Component } from "react";
import ForumDetail from "./ForumDetail";
import { withRouter } from "react-router-dom";

class StudentForum extends Component {

    componentDidMount() {        
        if (!this.props.match.params.id) {            
            this.props.history.push('/student/forums');
        }         
    }
    
    render() {        
        return (
            <div className="columns">
                <div className="column is-8-desktop is-10-tablet is-offset-2-desktop is-offset-1-tablet">
                    <div className="sm-mx-10">
                        <ForumDetail slug={this.props.match.params.id} levelid="student"></ForumDetail>
                    </div>                    
                </div>
            </div>
        );
    }
}



export default withRouter(StudentForum);

