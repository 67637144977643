import { GET_ERRORS } from "./types";
import axios from "axios";
import { CREATE_EXAM } from "../helpers/api";

// create level
export const createExam = (examData) => dispatch => {

    return axios
        .post(CREATE_EXAM, examData)
        .then(response => {
            return new Promise((resolve, reject) => {                
                resolve(response);
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};