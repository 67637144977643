import React, { Component } from 'react';
import classnames from 'classnames';

export default class Gender extends Component {

    constructor (props) {
        super(props);

        this.state = {
            genders: [
                {
                    id: 1,
                    title: 'Femenino',
                    img: 'https://purocodigo.net/public/apps/cdn/avatars/girlgender.svg'                    
                },
                {
                    id: 2,
                    title: 'Masculino',
                    img: 'https://purocodigo.net/public/apps/cdn/avatars/mangender.svg'                    
                }
            ],
            selectedGender: 0,
            buttonDisabled: true
        };       
    }

    selectGender(gender) {        
        this.setState({
            selectedGender: gender.id,
            buttonDisabled: false
        });
    }

    renderGenders() {
        const { genders, selectedGender } = this.state;
        const markup = [];
        genders.forEach(gender => {
            markup.push(
                <div 
                onClick={() => { this.selectGender(gender) }}
                className={classnames("gender-single ", {
                    "gender-single--active": gender.id == selectedGender
                })}
                key={gender.id}>                        
                    <div><img src={gender.img}></img></div>
                    <div className="gender-title">{ gender.title }</div>
                </div>  
            );
        });
        return markup;
    }

    render() {
        return (
            <div className="profile__container__content">
                <div>
                    <h2><i className="fa fa-venus-mars" aria-hidden="true"></i>&nbsp;Cual es tu Género?</h2>
                </div>
                <div className="gender-selection">
                    { this.renderGenders() }                
                </div>
                <div className="next-step-button">
                    <button 
                        onClick={() => this.props.genderCallback(this.state.selectedGender) }
                        className="button is-primary" 
                        disabled={this.state.buttonDisabled}>Siguiente paso</button>
                </div>
            </div>
        )
    }
}
