import { GET_ERRORS } from "./types";
import axios from "axios";
import { REGISTER_STUDENT } from "../helpers/api";

// Register user
export const registerStudent = (userData) => dispatch => {

    return axios
        .post(REGISTER_STUDENT, userData)
        .then(response => {
            return new Promise((resolve, reject) => {
                resolve(response);
            })
        })
        .catch(err => {
            dispatch({
                type: GET_ERRORS,
                payload: err.response.data
            });
        });
};
