import React, { Component } from "react";
import {connect} from "react-redux";
import axios from 'axios';
import { CHANGE_PW } from "../../../../helpers/api";
import DashboardHeader from "../layout/DashboardHeader";
import InputTextField from "../../../UI/Form/InputTextField";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
import svg from '../../../../assets/password.svg';
import TitleHeader from "../layout/TitleHeader";


class ChangePassword extends Component {

    constructor() {
        super();
        this.state = {
            errors: {},
            current_password: '',
            password: '',
            retype_password: '',
            sendingRequest: false
        };        

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onSubmit(e) {
        e.preventDefault();
        this.setState({
            errors: {},
            sendingRequest: true
        })
        const data = {
            current_password: this.state.current_password,
            password: this.state.password,
            retype_password: this.state.retype_password
        }
        axios.patch(CHANGE_PW, data).then((r) => {
            if (r.status == 200) {
                this.setState({ current_password: '', password: '', retype_password: '', sendingRequest: false })
                toast.info("Contraseña cambiada correctamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose:1500
                });
            }   
        }).catch(e => {
            this.setState({
                errors: e.response.data,
                sendingRequest: false
            })
        })
    }   



 
    render() {

        const { errors } = this.state;

        const html = this.state.sendingRequest ? (<Loader/>) : (<div className="control">
            <button className="button is-primary login-container__form__button mt-10">Cambiar contraseña</button>
        </div>);


        return (
            <div>
                <TitleHeader text="Cambiar contraseña" image={svg}></TitleHeader>                
                
                <div className="columns">
                    <div className="mt-20 sm-mx-10 column is-4 is-offset-4">
                        <form noValidate onSubmit={this.onSubmit}>

                            <div className="field">
                                <label className="label">Contraseña actual</label>
                                <InputTextField
                                    name={'current_password'}
                                    value={this.state.current_password}
                                    type={'password'}
                                    onChange={this.onChange}
                                    placeholder={'Contraseña actual'}
                                    error={errors.current_password}
                                />
                            </div>

                            <div className="field">
                                <label className="label">Contraseña nueva</label>
                                <InputTextField
                                    name={'password'}
                                    value={this.state.password}
                                    type={'password'}
                                    onChange={this.onChange}
                                    placeholder={'Contraseña nueva'}
                                    error={errors.password}
                                />
                            </div>

                            <div className="field">
                                <label className="label">Repite contraseña</label>
                                <InputTextField
                                    name={'retype_password'}
                                    value={this.state.retype_password}
                                    type={'password'}
                                    onChange={this.onChange}
                                    placeholder={'Repite contraseña'}
                                    error={errors.retype_password}
                                />
                            </div>

                            { html }
                            
                        </form>
                    </div>
                </div>
                
            </div>
        );
    }
}

export default ChangePassword;

