export const getFooterLegalSize = (pdf) => {
    pdf.setFontSize(8);
    const year = new Date().getFullYear();
    pdf.text(140, 335, `Purocodigo @${year} Todos los derechos reservados`);
};

export const addTextToPDF = (pdf, x, y, color, size, text) => {
    pdf.setTextColor(...color);
    pdf.setFontSize(size);           
    pdf.text(x, y, text);
};