export const isEmpty = (value) => {
  return (
    value === undefined ||
    value === null ||
    (typeof value === "object" && Object.keys(value).length === 0) ||
    (typeof value === "string" && value.trim().length === 0)
  );
};

export const formatPrice = (price) => {
  return "$" + price.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const calculateTotalCart = (cart) => {
  if (cart.length === 0) return formatPrice(0);

  return cart.reduce((t, c) => {
    return t + (c.dish.price - c.dish.discount) * c.quantity;
  }, 0);
};

export const getDistanceFromLatLon = (lat1, lon1, lat2, lon2, units = "km") => {
  const R = units === "m" ? 6371e3 : 6371; // Radius of the earth in km 6371 //6371e3 meters
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return R * c; // Distance in km
};

export const formatDistance = (number) => {
  if (number > 0.1) {
    return `${number.toFixed(1).replace(".0", "")} km`;
  }
  return `${(number * 1000).toFixed(0)} m`;
};

const deg2rad = (deg) => {
  return deg * (Math.PI / 180);
};

export const roundWithPresision = (value, precision) => {
  const multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getMinutesAndSeconds = (minutes) => {
  if (minutes <= 0) {
    return { minutes: 0, seconds: 0 };
  }

  if (minutes == 1) {
    return { minutes: 0, seconds: 59 };
  }

  return { minutes: minutes - 1, seconds: 59 };
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
};

export const toTitleCase = (str) => {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const importAll = (r) => {
  return r.keys().map(r);
};

export const getScrollTopMax = (element) => {
  if (!element.scrollTopMax) {
    return element.scrollHeight - element.clientHeight;
  }
  return element.scrollTopMax;
};

export const replaceIframe = (content) => {
  if (!content) return null;
  const regex = /<oembed.+?url="https?:\/\/www\.youtube\.com\/watch\?v=([a-zA-Z0-9_-]{11})"><\/oembed>/g;
  const result = content.replace(
    regex,
    "<iframe class='lesson-video' src='https://www.youtube.com/embed/$1'></iframe>"
  );
  return result;
};

export const formatMinutes = (num) => {
  const minutes = num / 60;
  const rminutes = Math.floor(minutes);
  const seconds = (minutes - rminutes) * 60;
  const rseconds = Math.round(seconds);
  const formattedMinutes = rminutes < 10 ? "0" + rminutes : rminutes;
  const formattedSeconds = rseconds < 10 ? "0" + rseconds : rseconds;
  return formattedMinutes + ":" + formattedSeconds;
};
