import React, { Component, Fragment } from 'react'
import DataTable from 'react-data-table-component';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { toast } from 'react-toastify';
import axios from 'axios';
import { RESET_STUDENT_EXAM } from '../../../../helpers/api';
import { gradeExam } from '../../../../helpers/examresult';

class ExamResultDetailTable extends Component {

    constructor(props) {
        super(props);

        this.state = {
            data: this.props.data
        }
    }    

    resetStudentExam = (result) => {
        confirmAlert({            
            message: 'Estas seguro(a) que quieres reiniciar la prueba ' + result.name + ' para este estudiante?',
            buttons: [
              {
                label: 'Si',
                onClick: async () => await this.removeExamTaken(result.id)
              },
              {
                label: 'No',
                onClick: () => { return; }
              }
            ]
          });        
    }

    removeExamTaken = async (examTakenId) => {
        try {     
            const res = await axios.delete(`${RESET_STUDENT_EXAM}/${examTakenId}`)               
            
            let { data } = this.state;
            data = data.filter(d => d.id !== examTakenId);
            this.setState({ data });

            toast.info("Prueba reiniciada correctamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2500
            });
            
        } catch(e) {      
            console.error(e)                  
            toast.error("Error! Intente nuevamente", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 2000
            });
        }
    }



    render() {
        

        const { data } = this.state;        

        const table = data.length ? <DataTable
            columns={[                
                { name: 'Prueba',
                    selector: 'result',
                    cell: row => <span title={row.name}>{row.name}</span>
                },
                { name: 'Respuestas/Preguntas',
                    selector: 'result',
                    cell: row => <span>{row.result}/{row.number_questions}</span>
                },
                { name: 'Puntaje',
                    selector: 'number_questions',
                    cell: row => <span><b>{gradeExam(row)}</b></span>
                },                        
                { name: 'Fecha', selector: 'exam_date', sortable: true },                
                { name: 'Reiniciar prueba',
                selector: 'id',
                cell: row => <div className="icon-actions">
                    <span title="Reiniciar prueba"><i className="fa fa-history" onClick={() => this.resetStudentExam(row)}></i></span>
                </div>,}
            ]}
            data={data}
            pagination={true}
            paginationPerPage={5}
            noHeader={true}

        /> : <div>
            No se encontraron resultados para este estudiante
        </div>;

        return <React.Fragment>
            { table }
        </React.Fragment>
    }
}

export default ExamResultDetailTable;