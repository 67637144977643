const male = [
    {
        id: 1,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man1.svg',
    },
    {
        id: 2,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man2.svg',
    },
    {
        id: 3,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man3.svg',
    },
    {
        id: 4,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man4.svg',
    },
    {
        id: 5,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man5.svg',
    },
    {
        id: 6,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man6.svg',
    },
    {
        id: 7,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man7.svg',
    },
    {
        id: 8,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man8.svg',
    },
    {
        id: 9,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/man9.svg',
    }   
  
];

const female = [
    {
        id: 1,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl1.svg',
    },
    {
        id: 2,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl2.svg',
    },
    {
        id: 3,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl3.svg',
    },
    {
        id: 4,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl4.svg',
    },
    {
        id: 5,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl5.svg',
    },
    {
        id: 6,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl6.svg',
    },
    {
        id: 7,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl7.svg',
    },
    {
        id: 8,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl8.svg',
    },
    {
        id: 9,
        src: 'https://purocodigo.net/public/apps/cdn/avatars/girl9.svg',
    } 
];

export const getAvatars = (gender) => {
    if (gender == 1) {
        return female;
    }

    return male;
}