import React, { Component } from "react";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import { connect } from "react-redux";
import { createExam } from "../../../../actions/examActions";
import InputSelectField from "../../../UI/Form/InputSelectField";
import axios from "axios";
import { GET_NOT_TAKEN_EXAMS } from "../../../../helpers/api";
import Loader from "../../../Common/Loader";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import classnames from "classnames";

class NewExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levels: this.props.levels,
      exams: [],
      time: [
        { id: "30", name: "30 minutos" },
        { id: "35", name: "35 minutos" },
        { id: "40", name: "40 minutos" },
        { id: "45", name: "45 minutos" },
        { id: "50", name: "50 minutos" },
        { id: "55", name: "55 minutos" },
      ],
      errors: {},
      level_id: "-1",
      exam_id: "",
      exam_time: "",
      active: "",
      sendingRequest: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSelectLevel = this.onSelectLevel.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSelectLevel(e) {
    this.setState({ [e.target.name]: e.target.value, exams: [], exam_id: "" });
    const levelId = e.target.value;
    this.fetchExams(levelId);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      sendingRequest: true,
    });

    const examData = {
      level_id: this.state.level_id,
      exam_id: this.state.exam_id,
      active: this.state.active,
      exam_time: this.state.exam_time,
    };

    this.props
      .createExam(examData)
      .then((res) => {
        if (res) {
          this.setState({
            errors: {},
            level_id: "-1",
            exam_id: "",
            active: "",
            exam_time: "",
            sendingRequest: false,
          });
          toast.info("Prueba creada correctamente", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500,
          });
        }
      })
      .catch((e) => {
        this.setState({
          sendingRequest: false,
        });
      });
  }

  fetchExams(levelId) {
    axios.get(GET_NOT_TAKEN_EXAMS + "/" + levelId).then((res) => {
      this.setState({
        exams: res.data,
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        sendingRequest: false,
      });
    }
  }

  render() {
    const { errors, sendingRequest } = this.state;

    return (
      <div>
        {this.state.fetchingDataFromApi ? (
          <FullScreenLoader></FullScreenLoader>
        ) : null}
        <div className={"columns"}>
          <div className={"column is-10 is-offset-1 mt-20"}>
            <h3 className={"is-main-header"}>
              <i className="fa fa-pencil" style={{ marginRight: "5px" }}></i>
              Nueva prueba
            </h3>

            <form noValidate onSubmit={this.onSubmit}>
              <div className="columns">
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Seleccione curso</label>
                    <InputSelectField
                      name={"level_id"}
                      value={this.state.level_id}
                      onChange={this.onSelectLevel}
                      placeholder={"Escoja un curso"}
                      error={errors.level_id}
                      data={this.state.levels}
                      isFullWidth={true}
                      medium={false}
                    />
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Seleccione prueba</label>
                    <InputSelectField
                      disabled={this.state.level_id == -1 ? true : false}
                      name={"exam_id"}
                      value={this.state.exam_id}
                      onChange={this.onChange}
                      placeholder={"Escoja una prueba"}
                      error={errors.exam_id}
                      data={this.state.exams}
                      isFullWidth={true}
                      medium={false}
                    />
                  </div>
                </div>
              </div>

              <div className="columns">
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Tiempo de prueba</label>
                    <InputSelectField
                      name={"exam_time"}
                      value={this.state.exam_time}
                      onChange={this.onChange}
                      placeholder={"Tiempo para la prueba"}
                      error={errors.exam_time}
                      data={this.state.time}
                      isFullWidth={true}
                      medium={false}
                    />
                  </div>
                </div>
                <div className="column is-6">
                  <div className="field">
                    <label className="label">Habilitar prueba</label>
                    <div className="pretty p-default p-round">
                      <input
                        type="radio"
                        name="active"
                        value="1"
                        checked={this.state.active == "1"}
                        onChange={this.onChange}
                      />
                      <div className="state p-primary-o">
                        <label>Si</label>
                      </div>
                    </div>

                    <div className="pretty p-default p-round">
                      <input
                        type="radio"
                        name="active"
                        value="0"
                        checked={this.state.active == "0"}
                        onChange={this.onChange}
                      />
                      <div className="state p-primary-o">
                        <label>No</label>
                      </div>
                    </div>
                    {errors.active && (
                      <p className="help is-danger">{errors.active}</p>
                    )}
                  </div>
                </div>
              </div>

              <button
                className={classnames(
                  "button is-primary  login-container__form__button mt-10",
                  {
                    "is-loading": sendingRequest,
                  }
                )}
              >
                Crear prueba
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

NewExam.propTypes = {
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { createExam })(NewExam);
