import React, { Component } from "react";
import Pusher, { log } from "pusher-js";
import axios from "axios";
import {
  PUSHER_AUTH,
  CONNECT_TO_LESSON,
  TEACHER_FINISHED_LESSON,
} from "../../../../helpers/api";
import Chat from "./Components/Chat";
import classnames from "classnames";
import { withRouter } from "react-router-dom";
import jwt_decode from "jwt-decode";
import ChatLoader from "./Components/ChatLoader";
import User from "./Components/User";
import LessonContent from "./Components/LessonContent";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import "react-confirm-alert/src/react-confirm-alert.css";

class Lesson extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      lesson: {},
      showChat: false,
      currentUser: {},
      connectingToLesson: true,
      unreadMessages: 0,
    };

    this.pusher = null;
    this.channel = null;
    this.channelName = null;
  }

  async componentDidMount() {
    const lessonUUID = this.props.match.params.id;

    try {
      const token = localStorage.jwtToken;

      const response = await axios.post(CONNECT_TO_LESSON, {
        lesson: lessonUUID,
      });

      if (!response.data) {
        this.props.history.push("/");
      }

      if (!token) {
        return this.props.history.push("/");
      }

      const currentUser = jwt_decode(localStorage.jwtToken);

      this.setState({ lesson: response.data, currentUser: currentUser }, () => {
        const { lesson } = this.state;

        if (lesson.isLessonOpen) {
          const pusherChannel = "presence-" + lesson.uuid;
          this.channelName = pusherChannel;
          this.connectToPusher(token, pusherChannel);
        } else {
          this.setState({
            connectingToLesson: false,
          });
        }
      });
    } catch (ex) {

      toast.error("Error, Codigo: " + ex.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 4000,
      });

      setTimeout(() => this.props.history.push("/"), 10);

    }
  }

  connectToPusher(token, pusherChannel) {
    this.pusher = new Pusher("46fce7c2392e19cd277f", {
      cluster: "us2",
      authEndpoint: PUSHER_AUTH,
      auth: {
        headers: {
          Authorization: "Bearer " + token,
        },
      },
    });

    this.channel = this.pusher.subscribe(pusherChannel);

    this.addPusherListeners();
  }

  addPusherListeners() {
    //enter lesson
    this.channel.bind("pusher:subscription_succeeded", (data) => {
      const members = data.members;
      const me = data.me;
      const count = data.count;

      const users = [];

      for (let member in members) {
        const user = { id: member, name: members[member].name };
        users.push(user);
      }

      this.setState({
        users: users,
        connectingToLesson: false,
      });
    });

    //error when entering lesson
    this.channel.bind("pusher:subscription_error", (error) => {
      console.log("error", error);
    });

    //new member enter the lesson
    this.channel.bind("pusher:member_added", (member) => {
      let { users } = this.state;

      const user = users.find(
        (user) => parseInt(user.id) === parseInt(member.id)
      );

      if (!user) {
        users.push({ id: member.id, name: member.info.name });
        this.setState({ users: users });
      }
    });

    //member left the lesson
    this.channel.bind("pusher:member_removed", (member) => {
      let { users } = this.state;

      users = users.filter((user) => parseInt(user.id) !== parseInt(member.id));

      this.setState({ users: users });
    });

    //new message in chat
    this.channel.bind("new-message", (data) => {
      let { comments } = this.state.lesson;
      let { unreadMessages, showChat } = this.state;
      comments.push(data.message);
      if (!showChat) {
        unreadMessages++;
      }
      this.setState({
        "lesson.comments": comments,
        unreadMessages: unreadMessages,
      });
    });

    //content class was modified
    this.channel.bind("content-modified", (data) => {
      if (!data) {
        return;
      }
      const { lesson } = this.state;
      lesson.content = data.content;
      this.setState({
        lesson: lesson,
      });
    });

    //class has finished
    this.channel.bind("lesson-finished", (data) => {
      let { users, lesson } = this.state;
      try {
        this.pusher.unsubscribe(this.channelName);
        this.pusher.disconnect();
        this.pusher = null;
        lesson.isLessonOpen = false;
        users = [];
        this.setState({ lesson: lesson, users: users });
      } catch (ex) {}
    });
  }

  toggleChatWindow = () => {
    let { showChat, unreadMessages } = this.state;
    if (!showChat) {
      unreadMessages = 0;
    }
    this.setState({ showChat: !showChat, unreadMessages: unreadMessages });
  };

  finishClass = async () => {
    confirmAlert({
      message:
        "Estas seguro(a) que quieres finalizar la clase? Si finalizas la clase no podras enviar mas mensajes",
      buttons: [
        {
          label: "Si",
          onClick: () =>
            axios.post(TEACHER_FINISHED_LESSON, { id: this.state.lesson.id }),
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  render() {
    const {
      users,
      showChat,
      lesson,
      currentUser,
      connectingToLesson,
      unreadMessages,
    } = this.state;

    return (
      <React.Fragment>
        {connectingToLesson && (
          <ChatLoader text="Conectando a la clase" color="white"></ChatLoader>
        )}
        <div className="lesson-container">
          <div className="lesson-container__users">
            <div className="lesson-container__users-header">
              Usuarios conectados ({users.length})
            </div>
            <ul className="users-list">
              {users.map((user) => (
                <User
                  key={user.id}
                  user={user}
                  currentUserId={currentUser.id}
                ></User>
              ))}
            </ul>
          </div>
          <div className="lesson-container__content">
            <div className="lesson-container__body">
              <div
                className={classnames("lesson-container__html", {
                  "lesson-container__html--hide": showChat,
                })}
              >
                {parseInt(currentUser.role) === 2 && lesson.isLessonOpen && (
                  <div className="finish-class-button">
                    <button className="button" onClick={this.finishClass}>
                      Finalizar Clase
                    </button>
                  </div>
                )}
                <LessonContent
                  content={lesson.content}
                  title={lesson.title}
                ></LessonContent>
              </div>
              <div
                className={classnames("lesson-container__comments", {
                  "lesson-container__comments--open": showChat,
                })}
              >
                <div className="lesson-container__comments-header">
                  <span
                    className="lesson-container__comments-header-title"
                    onClick={this.toggleChatWindow}
                  >
                    {unreadMessages > 0 && (
                      <React.Fragment>
                        <i className="fa fa-bell" aria-hidden="true"></i> (
                        {unreadMessages}){" "}
                        {unreadMessages > 1
                          ? "mensajes nuevos"
                          : "mensaje nuevo"}
                      </React.Fragment>
                    )}
                  </span>
                  {showChat ? (
                    <span
                      className="lesson-container__comments-header-close"
                      onClick={this.toggleChatWindow}
                    >
                      <i className="fa fa-minus-square" aria-hidden="true"></i>{" "}
                      Ocultar conversación
                    </span>
                  ) : (
                    <span
                      className="lesson-container__comments-header-close"
                      onClick={this.toggleChatWindow}
                    >
                      <i className="fa fa-comments" aria-hidden="true"></i>{" "}
                      Abrir conversación
                    </span>
                  )}
                </div>
                <Chat
                  lessonId={this.props.match.params.id}
                  lessonOpen={lesson.isLessonOpen}
                  messages={lesson.comments}
                  currentUser={currentUser}
                ></Chat>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Lesson);
