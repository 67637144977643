import React, { Component } from "react";
import {connect} from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { DASHBOARD_STATS } from "../../../helpers/api";
import CountUp from 'react-countup';

class Welcome extends Component {

    constructor() {
        super();    
        this.state = {
            stats: {
                student_count: 0,
                level_count: 0,
                exam_count: 0
            }
        }    
    }

    async componentDidMount() {
        try {
            const res = await axios.get(DASHBOARD_STATS);
            this.setState({
                stats: res.data
            });
        } catch(e) {
            
        }
    }

    handleNavigationEvent(route) {      
        this.props.history.push(`/admin/${route}`);
    }

    formatValue = value => `${Number(value).toFixed(0)}`;
 
    render() {
        return (
            <div className="welcome-admin">                
                <div className="welcome-admin__stats">
                    <div className="welcome-admin__stats__single welcome-admin__stats__single--green">
                        <span><CountUp end={this.state.stats.student_count} /></span>
                        <span>Estudiante{ this.state.stats.student_count == 1 ? '' : 's' }</span>
                    </div>
                    <div className="welcome-admin__stats__single welcome-admin__stats__single--purple">
                        
                        <span><CountUp end={this.state.stats.level_count} /></span>
                        <span>Curso{ this.state.stats.level_count == 1 ? '' : 's' }</span>
                    </div>
                    <div className="welcome-admin__stats__single welcome-admin__stats__single--cyan">
                        <span><CountUp end={this.state.stats.exam_count} /></span>
                        <span>Pruebas disponible{ this.state.stats.exam_count == 1 ? '' : 's' }</span>
                    </div>                    
                </div>
                <div className="welcome-admin__modules">
                    <div 
                    onClick={()=> {this.handleNavigationEvent('studentlist')}}
                    className="welcome-admin__modules__single welcome-admin__modules__single--red">
                        <div><i className="fa fa-users"></i></div>
                        <span>Estudiantes</span>
                    </div>

                    <div 
                    onClick={()=> {this.handleNavigationEvent('exams')}}
                    className="welcome-admin__modules__single welcome-admin__modules__single--blue">
                        <div><i className="fa fa-book"></i></div>
                        <span>Pruebas</span>
                    </div>

                    <div 
                    onClick={()=> {this.handleNavigationEvent('examresult')}}
                    className="welcome-admin__modules__single welcome-admin__modules__single--violet">
                        <div><i className="fa fa-trophy"></i></div>
                        <span>Resultados</span>
                    </div>

                    <div 
                    onClick={()=> {this.handleNavigationEvent('level')}}
                    className="welcome-admin__modules__single welcome-admin__modules__single--orange">
                        <div><i className="fa fa-list"></i></div>
                        <span>Cursos</span>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    null
)(withRouter(Welcome));
