import React, { Component } from "react";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import axios from "axios";
import InputSelectField from "../../../UI/Form/InputSelectField";
import DataTable from "react-data-table-component";
import classnames from "classnames";
import {
  GET_EXAMS_BY_ID,
  EXAMS_TOGGLE_ACTIVE,
  DELETE_EXAM,
} from "../../../../helpers/api";
import { toast } from "react-toastify";
import ConfirmCheck from "../../../UI/Modal/ConfirmCheck";

class ExamList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      levels: this.props.levels,
      exams: [],
      level_id: "-1",
      sendingRequest: false,
      fetchingDataFromApi: false,
      showConfirm: false,
      examToDelete: {},
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const val = e.target.value;

    this.setState({ level_id: val.toString() });

    if (val == -1) return;

    this.setState({ fetchingDataFromApi: true });

    this.fetchExamsByLevel(val).then((exams) => {
      this.setState({
        fetchingDataFromApi: false,
        exams: exams.data,
      });
    });
  }

  async fetchExamsByLevel(id) {
    try {
      return await axios.get(GET_EXAMS_BY_ID + "/" + id);
    } catch (e) {
      console.log(e);
    }
  }

  async toggleExam(row) {
    this.setState({
      fetchingDataFromApi: true,
    });
    try {
      const res = await axios.patch(EXAMS_TOGGLE_ACTIVE + "/" + row.id);
      let { exams } = this.state;

      for (let i = 0; i < exams.length; i++) {
        if (row.id == exams[i].id) {
          exams[i].active = exams[i].active == 1 ? 0 : 1;
          break;
        }
      }

      this.setState({
        fetchingDataFromApi: false,
        exams: exams,
      });

      const message =
        res.data.active == 0 ? "Prueba desactivada" : "Prueba activada";
      toast.info(message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1500,
      });
    } catch (e) {
      this.setState({ fetchingDataFromApi: false });
      toast.error("Ha ocurrido un error, intente de nuevo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
      });
    }
  }

  deleteExam(row) {
    this.setState({
      examToDelete: row,
      showConfirm: true,
    });
  }

  async confirmWindow(res) {
    this.setState({
      showConfirm: false,
      levelToDelete: {},
    });

    if (!res.res) {
      return;
    }

    this.setState({ fetchingDataFromApi: true });

    try {
      const data = await axios.delete(DELETE_EXAM + "/" + res.metadata.id);

      let { exams } = this.state;

      exams = exams.filter((exam) => exam.id != data.data.id);

      this.setState({ exams: exams, fetchingDataFromApi: false });

      toast.info("Prueba Eliminada correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (e) {
      this.setState({ fetchingDataFromApi: false });
      toast.error("Ha ocurrido un error, intente de nuevo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
      });
    }
  }

  render() {
    const { exams } = this.state;

    const table = exams.length ? (
      <DataTable
        columns={[
          {
            name: "Examen",
            selector: "id",
            sortable: true,
            cell: (row) => <span title={row.exam.name}>{row.exam.name}</span>,
          },

          { name: "Curso", selector: "level.name" },
          { name: "Tiempo de examen", selector: "exam_time" },
          {
            name: "Prueba activa",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span
                  title={
                    row.active == 1 ? "Desactivar prueba" : "Activar prueba"
                  }
                >
                  <i
                    className={classnames("fa", {
                      "fa-toggle-on": row.active == 1,
                      "fa-toggle-off": row.active == 0,
                    })}
                    onClick={() => this.toggleExam(row)}
                  ></i>
                </span>
              </div>
            ),
          },
          {
            name: "Acciones",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Eliminar curso">
                  <i
                    className="fa fa-trash"
                    onClick={() => this.deleteExam(row)}
                  ></i>
                </span>
              </div>
            ),
          },
        ]}
        data={exams}
        pagination={true}
        paginationPerPage={20}
        noHeader={true}
      />
    ) : (
      <div>No se encontraron examenes en este curso</div>
    );

    return (
      <div>
        <ConfirmCheck
          isActive={this.state.showConfirm}
          callback={this.confirmWindow.bind(this)}
          title="Eliminar prueba"
          message="¿Estás seguro que deseas eliminar este prueba? Si eliminas esta prueba, todos los resultados de esta prueba seran eliminados"
          confirmTextLbl="Por favor confirme el nombre de la prueba a eliminar"
          confirmButton="Si"
          cancelButton="No"
          metadata={this.state.examToDelete}
          name={
            this.state.examToDelete.exam
              ? this.state.examToDelete.exam.name
              : ""
          }
          confirmPlaceholder="Nombre del examen"
        ></ConfirmCheck>

        {this.state.fetchingDataFromApi ? (
          <FullScreenLoader></FullScreenLoader>
        ) : null}
        <div className={"columns"}>
          <div className={"column is-10 is-offset-1 mt-20"}>
            <h3 className={"is-main-header"}>
              <i className="fa fa-list" style={{ marginRight: "5px" }}></i>Lista
              de pruebas
            </h3>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">Seleccione un curso</label>
                  <InputSelectField
                    name={"level_id"}
                    value={this.state.level_id}
                    onChange={this.onChange}
                    placeholder={"Curso"}
                    data={this.state.levels}
                    isFullWidth={true}
                    medium={false}
                  />
                </div>
              </div>
            </div>

            {this.state.level_id != "-1" ? (
              table
            ) : (
              <div>Por favor seleccione un curso</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ExamList;
