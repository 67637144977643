import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, withRouter } from "react-router-dom";
import AdminMenu from "./partials/AdminMenu";
import AdminTopBar from "./partials/AdminTopBar";
import NewStudent from "./students/NewStudent";
import NewLevel from "./levels/NewLevel";
import Levels from "./levels/Levels";
import StudentList from "./students/StudentList";
import NewExam from "./exams/NewExam";
import ExamList from "./exams/ExamList";
import ExamResults from "./exams/ExamResults";
import ExamSolve from "./exams/ExamSolve";
import Welcome from "./Welcome";
import NewForum from "./forum/NewForum";
import ForumAdminList from "./forum/ForumAdminList";
import ForumAdminDetail from "./forum/ForumAdminDetail";
import AssignmentsTable from "./assignments/AssignmentsTable";
import CreateAssignment from "./assignments/CreateAssignment";
import AssignmentsList from "./assignments/AssignmentsList";
import { fetchLevels } from "../../../actions/levelActions";

import FullScreenLoader from "../../Common/FullScreenLoader";
import NewLesson from "./lessons/NewLesson";

import Lessons from "./lessons/Lessons";
import AttendanceReport from "./lessons/AttendanceReport";
import LessonAttendanceReport from "./lessons/LessonAttendanceReport";

class AdminDashboard extends Component {
  constructor() {
    super();
    this.state = {
      levels: [],
      fetchedInitialData: false,
    };
  }

  componentDidMount() {
    this.props.fetchLevels();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.levels) {
      this.setState({
        levels: nextProps.levels.levels,
        fetchedInitialData: true,
      });
    }
  }

  render() {
    const { levels, fetchedInitialData } = this.state;

    return (
      <React.Fragment>
        {fetchedInitialData ? (
          <div className="admin-panel">
            <div className="admin-panel__bar">
              <AdminTopBar user={this.props.auth.user}></AdminTopBar>
            </div>

            <div className="admin-panel__main-container">
              <div className="admin-panel__menu">
                <AdminMenu></AdminMenu>
              </div>

              <div className="admin-panel__content">
                <Route exact path="/admin" component={Welcome} />
                <Route
                  path="/admin/newexam"
                  component={() => <NewExam levels={levels}></NewExam>}
                />
                <Route
                  path="/admin/exams"
                  component={() => <ExamList levels={levels}></ExamList>}
                />
                <Route
                  path="/admin/examresult"
                  component={() => <ExamResults levels={levels}></ExamResults>}
                />
                <Route path="/admin/answers" component={ExamSolve} />
                <Route
                  path="/admin/register"
                  component={() => <NewStudent levels={levels}></NewStudent>}
                />
                <Route
                  path="/admin/studentlist"
                  component={() => <StudentList levels={levels}></StudentList>}
                />
                <Route
                  path="/admin/level"
                  component={() => <Levels levels={levels}></Levels>}
                />
                <Route path="/admin/createlevel" component={NewLevel} />
                <Route
                  path="/admin/newassignment"
                  component={() => (
                    <CreateAssignment levels={levels}></CreateAssignment>
                  )}
                />
                <Route
                  path="/admin/tasks"
                  component={() => (
                    <AssignmentsTable levels={levels}></AssignmentsTable>
                  )}
                />
                <Route
                  path="/admin/assignmentlist"
                  component={() => (
                    <AssignmentsList levels={levels}></AssignmentsList>
                  )}
                />
                <Route
                  path="/admin/forum"
                  component={() => <NewForum levels={levels}></NewForum>}
                />
                <Route
                  path="/admin/editforum/:levelid/:id"
                  component={() => <NewForum levels={levels}></NewForum>}
                />
                <Route
                  path="/admin/listforum"
                  component={() => (
                    <ForumAdminList levels={levels}></ForumAdminList>
                  )}
                />
                <Route
                  path="/admin/detailforum/:levelid/:id"
                  component={ForumAdminDetail}
                />
                <Route
                  path="/admin/newlesson/"
                  component={() => <NewLesson levels={levels}></NewLesson>}
                />
                <Route
                  path="/admin/editlesson/:levelid/:uuid"
                  component={() => <NewLesson levels={levels}></NewLesson>}
                />
                <Route
                  path="/admin/lessons"
                  component={() => <Lessons levels={levels}></Lessons>}
                />
                <Route
                  path="/admin/reportlesson"
                  component={() => (
                    <AttendanceReport levels={levels}></AttendanceReport>
                  )}
                />
                <Route
                  path="/admin/attendancereport"
                  component={() => (
                    <LessonAttendanceReport
                      levels={levels}
                    ></LessonAttendanceReport>
                  )}
                />
              </div>
            </div>
          </div>
        ) : (
          <FullScreenLoader></FullScreenLoader>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  levels: state.levels,
});

export default connect(mapStateToProps, { fetchLevels })(
  withRouter(AdminDashboard)
);
