import React, { Component } from "react";
import "./sass/App.scss";

import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";

import checkForToken from "./Utils/checkForToken";

import PrivateRoute from "./components/Common/PrivateRoute";
import Login from "./components/Auth/Login";
import NotFound from "./components/layout/NotFound";

import { Provider } from "react-redux";
import store from "./store";
import StudentDashboard from "./components/Users/Student/StudentDashboard";
import AdminDashboard from "./components/Users/Admin/AdminDashboard";
import Exam from "./components/Users/Student/Exam/Exam";
import RolesComponent from "./components/Common/RolesComponent";
import Lesson from "./components/Users/Shared/Lessons/Lesson";

//check for token
checkForToken();

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <ToastContainer />
            <Switch>
              <Route exact path="/" component={Login} />
              <PrivateRoute
                path="/student/exam/:id"
                component={RolesComponent(Exam)}
              />
              <PrivateRoute
                path="/student"
                component={RolesComponent(StudentDashboard)}
              />
              <PrivateRoute
                path="/admin"
                component={RolesComponent(AdminDashboard)}
              />
              <PrivateRoute
                path="/lesson/:id"
                component={RolesComponent(Lesson)}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
