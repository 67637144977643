import {logoutUser, setCurrentUser} from "../actions/authActions";
import setAuthToken from "./setAuthToken";
import jwt_decode from "jwt-decode";
import store from "../store";

const checkForToken = () => {

    if (localStorage.jwtToken) {
        //set auth token header auth
        setAuthToken(localStorage.jwtToken);
        //decode token
        const decoded = jwt_decode(localStorage.jwtToken);
        //set user
        store.dispatch(setCurrentUser(decoded));

        //check for expired token
        const currentTime = Date.now() / 1000;

        if (decoded.exp < currentTime) {
            //logout user
            store.dispatch(logoutUser());
            //redirect to login
            window.location.href = "login";
        }
    }

};

export default checkForToken;
