import React, { Component } from 'react';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '@ckeditor/ckeditor5-build-classic/build/translations/es.js'
import InputSelectField from '../../../UI/Form/InputSelectField';
import InputTextField from '../../../UI/Form/InputTextField';
import DatePicker from "react-datepicker";
import classnames from 'classnames';
import { toast } from 'react-toastify';
import "react-datepicker/dist/react-datepicker.css";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import es from 'date-fns/locale/es';
import axios from 'axios';
import Dropzone from 'react-dropzone';
import { formatBytes } from '../../../../helpers/helpers';
import { CREATE_ASSIGNMENT } from '../../../../helpers/api';
registerLocale('es', es)

class CreateAssignment extends Component {

    constructor(props) {
        super(props);
       
        this.state = {
            levels: this.props.levels,
            exams: [],
            title: '',
            content: '',
            errors: {},            
            level_id: '',            
            startDate: new Date(),
            endDate: new Date(),
            isSavingAssignment: false,
            file: null,
        };      
        
        this.onChange = this.onChange.bind(this);
    }

    

    onChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    onTextEditorChange = (event, editor) => {
        const data = editor.getData();
        this.setState({content: data});
    }    

    onDrop = (files) => {
        if (files.length > 1) {
            toast.error("Solamente puede enviar (1) archivo", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000
            });  
            return;
        }

        const file = files[0];   
        
        if (file.size > 1024*1024*6) {
            toast.error("El archivo excede el peso maximo", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000
            });
            return;
        }

        if (
            file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            || file.type == "application/pdf"            
        ) {
            if (file.type == "application/pdf") file.icon = "fa fa-file-pdf-o";
            if (file.type == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") file.icon = "fa fa-file-word-o";
            this.setState({file});
        } else {
            toast.error("El formato del documento debe ser WORD o PDF", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000
            });
            return;
        }        
    };

    restartState = () => {
        this.setState({
            title: '',
            content: '',            
            level_id: '',            
            startDate: new Date(),
            endDate: new Date(),
            isSavingAssignment: false,
            file: null,
        });
    }    

    saveAssignment = async () => {
        const { content, startDate, endDate, title, file, level_id } = this.state;

        if (!file) {
            toast.error("Por favor adjunte el archivo del taller", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose: 3000
            });
            return;
        }

        this.setState({ isSavingAssignment: true, errors: [] });

        const formData = new FormData();
        formData.append('file', file)
        formData.append('level_id', level_id);
        formData.append('content', content);
        formData.append('start_date', Date.parse(startDate));
        formData.append('end_date', Date.parse(endDate));
        formData.append('title', title);
        
        const config = {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }

        try {
            const res = await axios.post(CREATE_ASSIGNMENT, formData, config);              
            if (res.status == 200) {
                this.restartState();
                toast.info("Se ha creado el taller correctamente", {
                    position: toast.POSITION.BOTTOM_CENTER,
                    autoClose: 3000
                });
            }
        } catch (e) {
            this.setState({
                errors: e.response.data,
                isSavingAssignment: false
            })
        } 

    }

    render() {

        const { errors, file, isSavingAssignment } = this.state;

        return (
            <div className="columns">
                <div className="column is-10 is-offset-1">
                    <div>
                        <h3 className="is-main-header">
                            <i className="fa fa-pencil"></i>&nbsp;Nuevo taller
                        </h3>

                        <div className="columns">
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Seleccione curso</label>
                                    <InputSelectField
                                        name={'level_id'}
                                        value={this.state.level_id}
                                        onChange={this.onChange}
                                        placeholder={'Escoja un curso'}
                                        error={errors.level_id}
                                        data={this.state.levels}
                                        isFullWidth={true}
                                        medium={false}
                                    />
                                </div>
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Titulo del taller</label>
                                    <InputTextField
                                            name={'title'}
                                            value={this.state.title}
                                            onChange={this.onChange}
                                            placeholder={'e.g. Taller de geometria'}
                                            error={errors.title}
                                        />
                                </div>
                                
                            </div>
                        </div>  

                        <div className="columns">
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Fecha de inicio del taller</label>
                                    <DatePicker  
                                        className={classnames("input", {
                                            "is-danger": errors.start_date                                       
                                        })}
                                        selected={this.state.startDate}
                                        onChange={(date) => this.setState({startDate: date, endDate: date})}
                                        showTimeSelect
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        locale="es"
                                        minDate={new Date()}
                                    />
                                    { errors.start_date ? <p className="help is-danger">
                                        { errors.start_date }
                                    </p> : "" }
                                </div>                            
                            </div>
                            <div className="column is-6">
                                <div className="field">
                                    <label className="label">Fecha de cierre del taller</label>
                                    <DatePicker                                
                                        className={classnames("input", {
                                            "is-danger": errors.end_date                                       
                                        })}
                                        selected={this.state.endDate}
                                        onChange={(date) => this.setState({endDate: date})}
                                        showTimeSelect                                    
                                        dateFormat="yyyy-MM-dd HH:mm"
                                        locale="es"
                                        minDate={this.state.startDate}
                                    />
                                    { errors.end_date ? <p className="help is-danger">
                                        { errors.end_date }
                                    </p> : "" }
                                </div>
                            </div>
                        </div>

                        <div className="editor-area">
                            <label className="label">Escriba la descripción del taller</label>
                            <CKEditor
                                editor={ ClassicEditor }
                                data={this.state.content}
                                config={ {
                                    toolbar: ["heading","|","bold","italic","link","bulletedList","numberedList","|","|","blockQuote", "undo","redo"],
                                    language: 'es'
                                } }                                
                                onChange={this.onTextEditorChange}                   
                            />
                            { errors.content ? <p className="help is-danger">
                                { errors.content }
                            </p> : "" }
                        </div>


                        <div className="mt-10 mb-10">
                            <label className="label">Adjunte el taller</label>
                            <Dropzone onDrop={this.onDrop}>
                                {({getRootProps, getInputProps}) => (
                                <section className="dropzone-container">
                                    <div {...getRootProps({className: 'dropzone'})}>
                                        <input {...getInputProps()} />
                                        <div className="info-container">
                                            { file 
                                            ? (<div className="file-uploaded">
                                                <div className={file.icon}></div>
                                                <div>
                                                    <span>{ file.name }</span> - <span>{ formatBytes(file.size) }</span>
                                                </div>
                                            </div>) 
                                            : "Arrastre el archivo o presione clic para seleccionarlo" 
                                            }
                                        </div>
                                    </div>                                    
                                </section>
                                )}
                            </Dropzone>
                        </div>

                        <div style={{ marginBottom: 100 }}>
                            <button 
                            className={classnames("button is-primary mt-10 pull-left", {
                                'is-loading': isSavingAssignment
                            })}                        
                            onClick={this.saveAssignment}
                            >Guardar taller</button>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default CreateAssignment;