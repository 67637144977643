import React from 'react';
import PropTypes from "prop-types";

const TitleHeader = ({
                    text, image                          
                }) => {
    return (
        <div className="header-svg">
            <div>
                <object type="image/svg+xml" data={image}>
                    Error al cargar svg
                </object>
            </div>
            <h2>
            &nbsp;{ text }
            </h2>
        </div>  
    );
}

TitleHeader.propTypes = {
    text: PropTypes.string,    
    image: PropTypes.string
}

export default TitleHeader;
