import React, { Component } from "react";
import InputTextField from "../../../UI/Form/InputTextField";
import Loader from "../../../Common/Loader";
import InputSelectField from "../../../UI/Form/InputSelectField";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { registerStudent } from "../../../../actions/studentActions";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import { toast } from "react-toastify";
import classnames from "classnames";

class NewStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      identification: "",
      name: "",
      level_id: "",
      errors: {},
      levels: this.props.levels,
      sendingRequest: false,
      fetchingDataFromApi: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        sendingRequest: false,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      sendingRequest: true,
    });

    const userData = {
      identification: this.state.identification,
      name: this.state.name.toUpperCase(),
      level_id: this.state.level_id,
    };

    this.props
      .registerStudent(userData)
      .then((res) => {
        if (res) {
          this.setState({
            errors: {},
            identification: "",
            name: "",
            level_id: "",
            sendingRequest: false,
          });
          toast.info("Estudiante registrado correctamente", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 1500,
          });
        }
      })
      .catch((ex) => {
        this.setState({
          sendingRequest: false,
        });
      });
  }

  render() {
    const { errors, sendingRequest } = this.state;

    return (
      <div>
        {this.state.fetchingDataFromApi ? (
          <FullScreenLoader></FullScreenLoader>
        ) : null}
        <div className={"columns"}>
          <div className={"column is-10 is-offset-1 mt-20"}>
            <h3 className={"is-main-header"}>
              <i className="fa fa-pencil" style={{ marginRight: "5px" }}></i>
              Registrar estudiante
            </h3>

            <form noValidate onSubmit={this.onSubmit}>
              <div className="field">
                <label className="label">Numero de documento</label>
                <InputTextField
                  name={"identification"}
                  value={this.state.identification}
                  type={"text"}
                  onChange={this.onChange}
                  placeholder={"e.g. 1059948554"}
                  error={errors.identification}
                />
              </div>

              <div className="field">
                <label className="label">Nombre</label>
                <InputTextField
                  name={"name"}
                  value={this.state.name}
                  onChange={this.onChange}
                  placeholder={"e.g. Julian Caceres"}
                  error={errors.name}
                />
              </div>

              <div className="field">
                <label className="label">Curso</label>
                <InputSelectField
                  name={"level_id"}
                  value={this.state.level_id}
                  onChange={this.onChange}
                  placeholder={"Curso"}
                  error={errors.level_id}
                  data={this.state.levels}
                  isFullWidth={true}
                  medium={false}
                />
              </div>

              <div className="control">
                <button
                  className={classnames(
                    "button is-primary  login-container__form__button mt-10",
                    {
                      "is-loading": sendingRequest,
                    }
                  )}
                >
                  Registrar estudiante
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

NewStudent.propTypes = {
  registerStudent: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { registerStudent })(NewStudent);
