import React from 'react';
import classnames from 'classnames';
import PropTypes from "prop-types";

const InputSelectField = ({
                            name,                            
                            value,                           
                            error,                           
                            onChange,
                            disabled,
                            isFullWidth,
                            medium,
                            isPrimary,
                            data,
                            placeholder
                        }) => {

    
    let renderedOptions = data.map((option, index) => {
        return (
            <option value={option.id} key={option.id}>{option.name}</option>
        );
    });

    return (
        <div className="control">
            <div 
                className={classnames("select ", {
                    "is-primary": isPrimary,
                    "is-medium": medium,
                    "is-danger": error,
                    "is-fullwidth": isFullWidth
                })}                                
            >
                <select 
                    name={name}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                >
                    <option value="-1">{ placeholder }</option>
                    { renderedOptions }
                </select>               
            </div>
            {error && (
                <div >
                    <p className="help is-danger">{error}</p>
                </div>
            )}
        </div>
    );
}

InputSelectField.propTypes = {
    name: PropTypes.string.isRequired,    
    value: PropTypes.string.isRequired,   
    error: PropTypes.string,  
    onChange: PropTypes.func.isRequired,
    disabled: PropTypes.bool,
    isFullWidth: PropTypes.bool,
    data: PropTypes.array.isRequired,
    isPrimary: PropTypes.bool,
    medium: PropTypes.bool,
    placeholder: PropTypes.string,  
}

InputSelectField.defaultProps = {
    type: 'text',
    isFullWidth: false,
    medium: true,
    isPrimary: true,
    placeholder: 'Seleccione una opción'
}

export default InputSelectField;
