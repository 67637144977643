import React, { Component } from "react";
import InputSelectField from "../../../UI/Form/InputSelectField";
import {
  GET_ASSIGNMENT_BY_LEVEL,
  DELETE_ASSIGNMENT,
} from "../../../../helpers/api";
import axios from "axios";
import DataTable from "react-data-table-component";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmCheck from "../../../UI/Modal/ConfirmCheck";
import FullScreenLoader from "../../../Common/FullScreenLoader";
import EditAssignmentModal from "./EditAssignmentModal";

class AssignmentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      levels: this.props.levels,
      tasks: [],
      level_id: "-1",
      sendingRequest: false,
      fetchingDataFromApi: false,
      showConfirm: false,
      tasksToDelete: {},
      isEditingTask: false,
      taskToEdit: {},
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    const val = e.target.value;

    this.setState({ level_id: val.toString() });

    if (val == -1) return;

    this.setState({ fetchingDataFromApi: true });

    this.fetchAssignmentsByLevel(val).then((res) => {
      this.setState({
        fetchingDataFromApi: false,
        tasks: res.data,
      });
    });
  }

  async fetchAssignmentsByLevel(id) {
    try {
      return await axios.get(GET_ASSIGNMENT_BY_LEVEL + "/" + id);
    } catch (e) {
      console.log(e);
    }
  }

  deleteTask(row) {
    this.setState({
      tasksToDelete: row,
      showConfirm: true,
    });
  }

  async confirmWindow(res) {
    this.setState({
      showConfirm: false,
      tasksToDelete: {},
    });

    if (!res.res) {
      return;
    }

    this.setState({ fetchingDataFromApi: true });

    try {
      const data = await axios.delete(
        DELETE_ASSIGNMENT + "/" + res.metadata.id
      );

      let { tasks } = this.state;

      tasks = tasks.filter((tasks) => tasks.id != data.data.id);

      this.setState({
        tasks,
        fetchingDataFromApi: false,
      });

      toast.info("Taller eliminado correctamente", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3000,
      });
    } catch (e) {
      this.setState({ fetchingDataFromApi: false });
      toast.error("Ha ocurrido un error, intente de nuevo", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 3500,
      });
    }
  }

  editTaskCallback = (data) => {
    if (!data.res) {
      this.setState({ isEditingTask: false });
      return;
    }

    const { tasks } = this.state;
    const updatedTask = data.response;

    tasks.map((task) => {
      if (task.id == updatedTask.id) {
        task.start_date = updatedTask.start_date;
        task.end_date = updatedTask.end_date;
      }
      return task;
    });

    this.setState({ isEditingTask: false, tasks });
  };

  render() {
    const { tasks, isEditingTask, taskToEdit } = this.state;

    const table = tasks.length ? (
      <DataTable
        columns={[
          {
            name: "Titulo",
            selector: "id",
            sortable: true,
            cell: (row) => <span title={row.name}>{row.name}</span>,
          },
          { name: "Taller abre", selector: "start_date", sortable: true },
          { name: "Taller Cierra", selector: "end_date", sortable: true },
          {
            name: "Acciones",
            selector: "id",
            cell: (row) => (
              <div className="icon-actions">
                <span title="Cambiar fechas">
                  <i
                    className="fa fa-pencil"
                    onClick={() =>
                      this.setState({ isEditingTask: true, taskToEdit: row })
                    }
                  ></i>
                </span>
                <span title="Eliminar Taller">
                  <i
                    className="fa fa-trash"
                    onClick={() => this.deleteTask(row)}
                  ></i>
                </span>
              </div>
            ),
          },
        ]}
        data={tasks}
        pagination={true}
        paginationPerPage={20}
        noHeader={true}
      />
    ) : (
      <div>No se encontraron talleres en este curso</div>
    );

    return (
      <div>
        {isEditingTask && (
          <EditAssignmentModal
            isActive={isEditingTask}
            task={taskToEdit}
            callback={this.editTaskCallback}
          ></EditAssignmentModal>
        )}
        <ConfirmCheck
          isActive={this.state.showConfirm}
          callback={this.confirmWindow.bind(this)}
          title="Eliminar"
          message="¿Estás seguro que deseas eliminar este taller? Si eliminas esta taller, todos las soluciones de este taller seran eliminadas"
          confirmTextLbl="Por favor confirme el nombre del taller a eliminar"
          confirmButton="Si"
          cancelButton="No"
          metadata={this.state.tasksToDelete}
          name={
            this.state.tasksToDelete.name ? this.state.tasksToDelete.name : ""
          }
          confirmPlaceholder="Nombre del taller"
        ></ConfirmCheck>

        {this.state.fetchingDataFromApi ? (
          <FullScreenLoader></FullScreenLoader>
        ) : null}
        <div className={"columns"}>
          <div className={"column is-10 is-offset-1 mt-20"}>
            <h3 className={"is-main-header"}>
              <i className="fa fa-calendar" style={{ marginRight: "5px" }}></i>
              Lista de talleres
            </h3>
            <div className="columns">
              <div className="column is-12">
                <div className="field">
                  <label className="label">Seleccione un curso</label>
                  <InputSelectField
                    name={"level_id"}
                    value={this.state.level_id}
                    onChange={this.onChange}
                    placeholder={"Curso"}
                    data={this.state.levels}
                    isFullWidth={true}
                    medium={false}
                  />
                </div>
              </div>
            </div>

            {this.state.level_id != "-1" ? (
              table
            ) : (
              <div>Por favor seleccione un curso</div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AssignmentList);
