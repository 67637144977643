import React, { Component } from "react";
import ForumDetail from "../../Student/Forum/ForumDetail";
import { withRouter } from "react-router-dom";

class ForumAdminDetail extends Component {

    componentDidMount() {        
        if (!this.props.match.params.id) {            
            this.props.history.push('/admin/listforum');
        }         
    }
    
    render() {        
        return (
            <div className="columns">
                <div className="column is-10-desktop is-10-tablet is-offset-1-desktop is-offset-1-tablet">
                    <ForumDetail slug={this.props.match.params.id} levelid={this.props.match.params.levelid}></ForumDetail>
                </div>
            </div>
        );
    }
}

export default withRouter(ForumAdminDetail);

