import React, { useState } from "react";
import classnames from "classnames";

export default function CounterTextArea({
  characterCount,
  onChangeCb,
  error,
  content,
  icon,
  header,
  headerText,
}) {
  return (
    <div>
      <div className="forum-answers-list__counter">
        <div className="header">
          <i className={icon} aria-hidden="true"></i>
          &nbsp;{headerText}
        </div>
        <div>{characterCount}/500</div>
      </div>
      <div className="forum-asnwers-list__form">
        <div className="field">
          <div className="control">
            <textarea
              value={content}
              onChange={(e) => onChangeCb(e.target.value)}
              name="content"
              className={classnames("textarea", {
                "is-danger": error,
                "is-primary": !error,
              })}
              placeholder="Se breve con tu respuesta, solo tienes 500 caracteres"
            ></textarea>
          </div>
          {error && <p className="help is-danger">{error}</p>}
        </div>
      </div>
    </div>
  );
}
