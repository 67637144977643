import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import image from "../../../../assets/welcome.svg";
import { toTitleCase } from "../../../../helpers/helpers";
import moment from "moment";
import "moment/locale/es";

class StudentLanding extends Component {
  constructor() {
    super();
  }


  render() {
    
    return (
      <React.Fragment>
        <div className="container">
          <div className="columns is-vcentered is-centered welcome-information-container">
            <div className="column welcome-information">
              <h2>Hola { toTitleCase(this.props.auth.user.name) }!</h2>
              <p>
                Bienvenid@ a Educate, una plataforma para la educación en linea
              </p>
              <p
                style={{ textTransform: "capitalize" }}
              >{ moment().format("dddd D, MMMM") }</p>
            </div>
            <div className="column welcome-information-image">
              <div>
                <object type="image/svg+xml" data={image}>
                  Error al cargar svg
                </object>
              </div>
            </div>
          </div>
        </div>

        <div className="sticky-svg">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path
              fill="#b042b4"
              fillOpacity="1"
              d="M0,96L60,133.3C120,171,240,245,360,256C480,267,600,213,720,186.7C840,160,960,160,1080,181.3C1200,203,1320,245,1380,266.7L1440,288L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
            ></path>
          </svg>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(withRouter(StudentLanding));
