import React, { Component } from 'react'
import Gender from './Steps/Gender'
import Avatar from './Steps/Avatar'
import axios from 'axios';
import { USER_PROFILE } from '../../../../helpers/api';
import { toast } from "react-toastify";

export default class Profile extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gender: 0,
            avatarURI: '',
            step: 1
        };
    }

    handleGender = (genderSelected) => {
        if (genderSelected == 0) return;
        this.setState({
            gender: genderSelected,
            step: 2
        });
    }

    handleAvatar = async (avatar) => {
        if (!avatar) return;        

        const data = {
            gender: this.state.gender,
            url: avatar.src
        };

        
        try {
            const res = await axios.post(USER_PROFILE, data);  
            toast.info("Avatar actualizado", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:1500
            });
            this.props.closeProfile();
        } catch (ex) {
            toast.error("Ha ocurrido un error al actualizar el avatar", {
                position: toast.POSITION.BOTTOM_CENTER,
                autoClose:2500
            });
            this.props.closeProfile();
        }
        
    }

    render() {

        const { step, gender } = this.state;

        return (
            <div className="profile__container">
                { step === 1 
                ? <Gender genderCallback={this.handleGender}></Gender> 
                : <Avatar avatarCallback={this.handleAvatar} gender={gender}></Avatar> }                
            </div>
        );
    }
}
