import React from 'react';
import PropTypes from "prop-types";
import free from '../../../../assets/free.svg';


const EmptyResults = ({
                    text                           
                }) => {
    return (
        <div className="empty-results-component">
            <object type="image/svg+xml" data={free}>
                Error al cargar svg
            </object>
            <h5>{text}</h5>
        </div>
    );
}

EmptyResults.propTypes = {
    text: PropTypes.string    
}

export default EmptyResults;
