import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";
import MicRecorder from "mic-recorder-to-mp3";
import axios from "axios";
import { LESSON_NEW_VOICE_MESSAGE } from "../../../../../helpers/api";
import { formatMinutes } from "../../../../../helpers/helpers";
import { toast } from "react-toastify";

const recorder = new MicRecorder({
  bitRate: 64,
});

export default function NewVoiceMessage(props) {
  const [isRecorging, setIsRecording] = useState(false);
  const [finishedRecording, setFinishedRecording] = useState(false);
  const [voiceMessage, setVoiceMessage] = useState(null);
  const [voiceTime, setVoiceTime] = useState(0);

  let timerInterval = useRef(null);

  useEffect(() => {});

  function startRecording() {
    if (!isRecorging) {
      setIsRecording(true);
      recorder
        .start()
        .then(() => {
          timerInterval.current = setInterval(() => {
            timerInterval = setVoiceTime((voiceTime) => voiceTime + 1);
          }, 1000);
        })
        .catch((e) => console.log(e));
    } else {
      try {
        clearInterval(timerInterval.current);
        recorder
          .stop()
          .getMp3()
          .then(([buffer, blob]) => {
            const file = new File(buffer, "voicemessage.mp3", {
              type: blob.type,
              lastModified: Date.now(),
            });
            setVoiceMessage(file);
            setFinishedRecording(true);
          });
      } catch (ex) {
        console.log(ex);
      }
    }
  }

  async function handleAfterRecording(accept) {
    setIsRecording(false);
    setFinishedRecording(false);

    if (voiceTime > 300) {
      toast.error("ERROR: Mensajes de voz no pueden durar mas de 5 minutos", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 4000,
      });
      setVoiceTime(0);
      return;
    }

    setVoiceTime(0);

    if (accept) {
      const formData = new FormData();
      formData.append("message", voiceMessage);
      formData.append("lessonid", props.lessonId);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };
      const res = await axios.post(LESSON_NEW_VOICE_MESSAGE, formData, config);
    }
  }

  return (
    <div className="chat-message-controls__mic">
      {finishedRecording && (
        <div className="recording-controls">
          <span title="Enviar" onClick={() => handleAfterRecording(true)}>
            <i className="fa fa-check" aria-hidden="true"></i>
          </span>
          <span title="Borrar" onClick={() => handleAfterRecording(false)}>
            <i className="fa fa-times" aria-hidden="true"></i>
          </span>
        </div>
      )}
      <span
        onClick={startRecording}
        className={classnames("recording-button", {
          recording: isRecorging,
        })}
      >
        <i className="fa fa-microphone" aria-hidden="true"></i>
      </span>
      <span className="formatted-minutes">
        {isRecorging ? formatMinutes(voiceTime) : " "}
      </span>
    </div>
  );
}
