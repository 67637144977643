import React, { Component } from "react";
import PropTypes from "prop-types";
import classnames from 'classnames';

class ExamOption extends Component {

    constructor() {
        super();
        this.state = {
            options: [
                {id: 1, name: "A", selected: false},
                {id: 2, name: "B", selected: false},
                {id: 3, name: "C", selected: false},
                {id: 4, name: "D", selected: false}
            ]
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.optionSelected !== 0) {
            let { options } = this.state;
            options = options.map(opt => {
                opt.selected = false;
                if (nextProps.optionSelected == opt.id) {
                    opt.selected = true;
                }
                return opt;
            });
            this.setState({
                options: options
            });
        }
    }

    selectOption(option) {
        let { options } = this.state;
        options = options.map(opt => {
            opt.selected = false;
            if (option.id == opt.id) {
                opt.selected = true;
            }
            return opt;
        });

        this.setState({
            options: options
        });

        const data = {
            question: this.props.id,
            answer: option.id
        };

        this.props.onSelectedChoice(data);

    }

    render() {
        return (
            <div className={"exam-container__answer-element"}>
                <div className={"exam-container__answer-element__number"}>{ this.props.id })</div>
                {
                    this.state.options.map(option =>
                        <div
                            className={classnames("exam-container__answer-element__option", {
                                "exam-container__answer-element__option--active": option.selected
                            })}
                            onClick={() => this.selectOption(option) } key={option.id}>
                            <span>{ option.name }</span>
                        </div>
                    )
                }

            </div>
        );
    }
}

ExamOption.propTypes = {
    id: PropTypes.number,
    onSelectedChoice: PropTypes.func,
    optionSelected: PropTypes.number
};


export default ExamOption;
