import React, { Component } from "react";
import {connect} from "react-redux";
import axios from 'axios';
import { GET_AVAILABLE_EXAMS } from "../../../../helpers/api";
import { withRouter, Link } from "react-router-dom";



class BeginExam extends Component {

    constructor() {
        super();        
    }

    componentDidMount() {
        if (this.props.match.params.id) {
            this.restoreStorage();
            const { id } = this.props.match.params;
        } else {
            this.props.history.push('/student');
        }
    }

    restoreStorage() {
        localStorage.removeItem('studentAnswers');
        localStorage.removeItem('activeIndex');
    }

 
    render() {
        return (
            <div className="begin-exam-component">
                <h5 className="is-main-header is-main-header--no-border is-main-header--no-margin">Instrucciones</h5> 
                <div><p className="instructions">Antes de comenzar el examen lea las siguientes instrucciones</p></div> 
                <div>
                    <ol>
                        <li>Una vez haga clic en el botón empezar prueba, el tiempo de la prueba empezará a disminuir, la prueba debe ser enviada antes que de el tiempo se acabe, si no envía la prueba antes del tiempo establecido su prueba no será calificada por el sistema.</li>
                        <li>Debe responder todas las preguntas en la hoja de respuestas antes de enviar la prueba</li>
                        <li>El sitio web no necesita ser recargado, si presenta fallas técnicas informe al docente encargado.</li>
                        <li>Si tiene alguna pregunta respecto a la prueba puede preguntarle al docente encargado.</li>
                        <li>No puede abrir otras pestañas del navegador, ni buscar información sobre las preguntas en Google, si el docente encargado descubre el estudiante navegando en otros sitios web, su prueba será anulada inmediatamente.</li>
                        <li>Está prohibido el uso de celulares o tabletas durante la prueba</li>                        
                        <li>Si necesita salir del salón durante la prueba, puede pedir permiso al docente encargado.</li>
                        <li>No puede hablar con otras personas que estén tomando la prueba, ni hacer ruidos que interfieran en la concentración de los estudiantes.</li>
                    </ol>
                </div>
                <Link to={"/student/exam/" + this.props.match.params.id} className="button is-primary">Empezar prueba</Link>            
            </div>
        );
    }
}

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(
    mapStateToProps,
    null
)(withRouter(BeginExam));

