import React, { Component } from "react";
import { replaceIframe } from "../../../../../helpers/helpers";

export default class LessonContent extends Component {
  renderHtml(content) {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: replaceIframe(content),
        }}
      ></div>
    );
  }

  render() {
    const { content, title } = this.props;

    return (
      <div>
        <div className="lesson-container__html__title">{title}</div>
        <hr></hr>
        <div>{this.renderHtml(content)}</div>
      </div>
    );
  }
}
