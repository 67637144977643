import React, { Component } from "react";
import classnames from "classnames";

class Confirm extends Component {

  constructor() {
    super();
    this.state = {

    };

  }

  action(res) {
    this.props.callback(res);
  }

  render() {
    return (
      <div className={classnames("modal ", {
        "is-active": this.props.isActive,
      })}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{ this.props.title }</p>
            <button className="delete" aria-label="close" onClick={()=>{this.action(false)}}></button>
          </header>
          <section className="modal-card-body">
            { this.props.message }
          </section>
          <footer className="modal-card-foot">
            <button className="button is-success" onClick={()=>{this.action(true)}}>{ this.props.confirmButton }</button>
            <button className="button" onClick={()=>{this.action(false)}}>{ this.props.cancelButton }</button>
          </footer>
        </div>
      </div>
    );
  }
}



export default Confirm;