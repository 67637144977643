import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";
import { GET_AVAILABLE_EXAMS } from "../../../../helpers/api";
import { Link } from "react-router-dom";
import DashboardHeader from "../layout/DashboardHeader";
import Loader from "../../../Common/Loader";
import svg from "../../../../assets/exams.svg";
import EmptyResults from "../layout/EmptyResults";
import TitleHeader from "../layout/TitleHeader";
import ReactPaginate from "react-paginate";
import LazyLoader from "../../Shared/Lessons/Components/LazyLoader";

class ExamsList extends Component {
  constructor() {
    super();
    this.state = {
      exams: [],
      fetchingData: false,
      pageCount: 0,
      perPage: 5,
      offset: 0,
      filteredExams: [],
    };
  }

  componentDidMount() {
    this.setState({ fetchingData: true });
    axios
      .get(GET_AVAILABLE_EXAMS)
      .then((exams) => {
        const perPage = 5;
        const pageCount = Math.ceil(exams.data.length / perPage);

        this.setState(
          {
            exams: exams.data,
            fetchingData: false,
            pageCount: pageCount,
            perPage: perPage,
          },
          () => this.loadData()
        );
      })
      .catch((e) => {
        this.setState({ fetchingData: false });
      });
  }

  loadData() {
    const { perPage, offset, exams } = this.state;
    const filteredExams = [];
    let maxCount = perPage + offset;
    if (maxCount > exams.length) {
      maxCount = exams.length;
    }
    for (let i = offset; i < maxCount; i++) {
      filteredExams.push(exams[i]);
    }

    this.setState({
      filteredExams: filteredExams,
    });
  }

  handlePageClick = (data) => {
    let selected = data.selected;
    let offset = Math.ceil(selected * this.state.perPage);

    this.setState({ offset: offset }, () => {
      this.loadData();
    });
  };

  renderAvailableExamsList() {
    return this.state.filteredExams.map((exam) => {
      return (
        <li key={exam.id} className="mb-10">
          <div>
            <div className="exams-list-component__list__data">
              <span>
                <i className="fa fa-book"></i>
              </span>
              &nbsp;
              <span>{exam.name}</span>
            </div>
            <div className="exams-list-component__list__time">
              <span>
                Esta prueba tiene una duración de {exam.exam_time} minutos
              </span>
            </div>
          </div>
          <div className="exams-list-component__list__begin">
            <Link
              className="start-button"
              to={"/student/beginexamen/" + exam.id}
            >
              Comenzar
            </Link>
          </div>
        </li>
      );
    });
  }

  render() {
    const { pageCount } = this.state;

    return (
      <div className="exams-list-component">
        <TitleHeader text="Pruebas" image={svg}></TitleHeader>

        {this.state.fetchingData ? (
          <LazyLoader></LazyLoader>
        ) : (
          <div className="exams-list-component__body">
            {this.state.exams.length === 0 ? (
              <EmptyResults text="No hay pruebas disponibles"></EmptyResults>
            ) : (
              <React.Fragment>
                <ul className="exams-list-component__list">
                  {this.renderAvailableExamsList()}
                </ul>
                {pageCount > 1 && (
                  <nav
                    className="pagination mt-20 mb-20"
                    role="navigation"
                    aria-label="pagination"
                  >
                    <ReactPaginate
                      previousLabel={"Anterior"}
                      nextLabel={"Siguiente"}
                      breakLabel={"..."}
                      breakClassName={"pagination-link"}
                      pageCount={pageCount}
                      onPageChange={this.handlePageClick}
                      containerClassName={"pagination-list"}
                      pageLinkClassName={"pagination-link"}
                      activeLinkClassName={"is-current"}
                      previousClassName={"button"}
                      nextClassName={"button"}
                    />
                  </nav>
                )}
              </React.Fragment>
            )}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(ExamsList);
