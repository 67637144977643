import React, { Component } from "react";
import InputTextField from "../../../UI/Form/InputTextField";
import Loader from "../../../Common/Loader";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createLevel } from "../../../../actions/levelActions";
import { toast } from "react-toastify";
import classnames from "classnames";

class NewLevel extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      errors: {},
      sendingRequest: false,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors,
        sendingRequest: false,
      });
    }
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      sendingRequest: true,
    });

    const levelData = {
      name: this.state.name.toUpperCase(),
    };

    this.props
      .createLevel(levelData)
      .then((res) => {
        if (res) {
          this.setState({
            errors: {},
            name: "",
            sendingRequest: false,
          });
          toast.info("Curso creado correctamente", {
            position: toast.POSITION.BOTTOM_CENTER,
            autoClose: 2500,
          });
        }
      })
      .catch((e) => {
        this.setState({
          sendingRequest: false,
        });
      });
  }

  render() {
    const { errors, sendingRequest } = this.state;

    return (
      <div className={"columns"}>
        <div className={"column is-10 is-offset-1 mt-20"}>
          <h3 className={"is-main-header"}>
            <i className="fa fa-pencil" style={{ marginRight: "5px" }}></i>Nuevo
            curso
          </h3>

          <form noValidate onSubmit={this.onSubmit}>
            <div className="field">
              <label className="label">Nombre del curso</label>
              <InputTextField
                name={"name"}
                value={this.state.name}
                onChange={this.onChange}
                placeholder={"e.g. Decimo B"}
                error={errors.name}
              />
            </div>

            <div className="control">
              <button
                className={classnames(
                  "button is-primary  login-container__form__button mt-10",
                  {
                    "is-loading": sendingRequest,
                  }
                )}
              >
                Crear curso
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

NewLevel.propTypes = {
  createLevel: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  errors: state.errors,
});

export default connect(mapStateToProps, { createLevel })(NewLevel);
