import React, { Component } from "react";

class DashboardHeader extends Component {

    constructor() {
        super();        
    }

 
    render() {
        return (
            <div className="dashboard-header">
                <div>
                    <h4 className="is-main-header is-main-header--no-border is-main-header--no-margin">
                        { this.props.icon ? <span><span className={this.props.icon}></span>&nbsp;</span> : null }
                        { this.props.title }
                    </h4>
                </div>
            </div>
        );
    }
}

export default DashboardHeader;