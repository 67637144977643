import { addTextToPDF, getFooterLegalSize } from "../helpers/pdf";
import * as jsPDF from 'jspdf';

export default class Reports {
    static makeResultsPDF(graph1, graph2, graph1Height, graph2Height, table, tableHeight) {
        return new Promise((resolve, reject) => {
            try {
                const pdf = new jsPDF("","","legal");                

                addTextToPDF(pdf, 20, 20, [0,106,177], 12, "Tabla de resultados");
                pdf.addImage(table, 'PNG', 20, 25, 170, tableHeight);
                getFooterLegalSize(pdf);

                pdf.addPage("legal");   
                addTextToPDF(pdf, 20, 20, [0,106,177], 12, "Resultados individuales por estudiante");
                pdf.addImage(graph1, 'PNG', 20, 25, 175, graph1Height);
                getFooterLegalSize(pdf);

                pdf.addPage("legal");   
                addTextToPDF(pdf, 20, 20, [0,106,177], 12, "Resultados individuales por pregunta");            
                pdf.addImage(graph2, 'PNG', 20, 25, 175, graph2Height);
                getFooterLegalSize(pdf);

                resolve(pdf);
            }catch(e) {
                reject(e);
            }
        });  
    }
}