import React, { Component } from "react";
import classnames from "classnames";
import InputTextField from "../Form/InputTextField";

class ConfirmCheck extends Component {

  constructor(props) {
    super(props);
    this.state = {
        confirmText: '',
        disableButton: true
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange(e) {
    this.setState({ [e.target.name]: e.target.value });
    const val = e.target.value;
    if (this.props.name.toLowerCase().trim() === val.toLowerCase().trim()) {
        this.setState({
            disableButton: false
        })
    } else {
        this.setState({
            disableButton: true
        })
    }
   }


  action(res) {
    this.setState({
        confirmText: '',
        disableButton: true
    })
    this.props.callback({res: res, metadata: this.props.metadata});
  } 

  render() {
    return (
      <div className={classnames("modal ", {
        "is-active": this.props.isActive,
      })}>
        <div className="modal-background"></div>
        <div className="modal-card">
          <header className="modal-card-head">
            <p className="modal-card-title">{ this.props.title } {this.props.name}</p>
            <button className="delete" aria-label="close" onClick={()=>{this.action(false)}}></button>
          </header>
          <section className="modal-card-body">
            { this.props.message }
            <div className="field mt-10">
                <label className="label">
                  { this.props.confirmTextLbl }
                </label>
                <InputTextField
                    name={'confirmText'}
                    value={this.state.confirmText}
                    type={'text'}
                    onChange={this.onChange}
                    placeholder={this.props.confirmPlaceholder}                    
                />
            </div>
          </section>
          <footer className="modal-card-foot">
            <button disabled={this.state.disableButton}  className="button is-success" onClick={()=>{this.action(true)}}>{ this.props.confirmButton }</button>
            <button className="button" onClick={()=>{this.action(false)}}>{ this.props.cancelButton }</button>
          </footer>
        </div>
      </div>
    );
  }
}



export default ConfirmCheck;